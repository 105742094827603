import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from "./components/Header/HeaderNew";
import Footer from "./components/Footer/Footer";
import Garage from "./pages/garage/Garage";
import Team from "./pages/meetTheTeam/Team_New";
import Terms from "./pages/Terms/Terms";
import MintPage from "./pages/carClubMint/CarClubMintPage";
import HomePage from "./pages/homePage/HomePage";
import Utility from "./pages/Membership/membership_new"
import Tools from "./pages/Tools/tools_new"
import Schedule from "./pages/Upcoming/Upcoming"
import Market from './pages/marketPlace/marketPlaceNew'
import Fantasy from './pages/Fantasy/Fantasy'
import Racing from './pages/racingSim/Racing'
import Driverz from './pages/racingSim/Driverz'
import Admin from './pages/Admin/admin';
import Turbo from './pages/turbo/turbo';
import Wager from './pages/wager/wager';
import Vroom from './pages/vroomShop/vroomShop';

function App(){
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} index />
          <Route path ="/mint" element={<MintPage />} />
          <Route path ="/garage" element={<Garage />} /> 
          <Route path = "/pitcrew" element={<Team />} /> 
          <Route path = "/terms" element={<Terms />} />
          <Route path = "/utility" element={<Utility />} />
          <Route path = "/toolbox" element={<Tools />} />
          <Route path = "/schedule" element={<Schedule />} />
          <Route path = "/market" element={<Market />} />
          <Route path = "/fantasy" element={<Fantasy />} />
          <Route path = "/racing" element={<Racing />} />
          <Route path = "/driverz" element={<Driverz />} />
          <Route path = "/driverz-admin" element={<Admin />} />
          <Route path = "/turbo" element={<Turbo />} />
          <Route path = "/wager" element={<Wager />} />
          <Route path = "/vroom" element={<Vroom />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;

/*

<div className="App">
<header className="App-header">
  <img src={logo} className="App-logo" alt="logo" />
  <p>
    Driverz<sup>&trade;</sup>
  </p>
  <a
    className="App-link"
    href="https://reactjs.org"
    target="_blank"
    rel="noopener noreferrer"
  >
    Learn React
  </a>
</header>
</div> */
