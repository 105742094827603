// Import the functions you need from the SDKs you need
// import firebase from 'firebase/compat/app';
// import "firebase/compat/database";
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig1 = {
    apiKey: "AIzaSyCGE2lFykD1tPCrRX7JVvaGP0y4XGmNQa8",
    authDomain: "stakingdata.firebaseapp.com",
    projectId: "stakingdata",
    storageBucket: "stakingdata.appspot.com",
    messagingSenderId: "356439349903",
    appId: "1:356439349903:web:4b9cd5ea329ebc7181aefa",
    measurementId: "G-FLTT745SVC"
  };

// Initialize Firebase
const appNew = initializeApp(firebaseConfig1, "Staking-App");
export const db = getFirestore(appNew);
// const analytics = getAnalytics(app);

// firebase.initializeApp(firebaseConfig);
//export default firebase.database();