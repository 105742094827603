export const getListingInsDetails = `

import DriverzInsuranceStorefront from 0xf887ece39166906e

pub struct NFTData {
    pub let listingID: UInt64
    pub let price: UFix64 

    init (listingID: UInt64, price: UFix64) {
        self.listingID = listingID
        self.price = price
    }
}

pub fun main(storefrontAddress: Address): [NFTData] {

    let nftData: [NFTData] = []

    let storefront = getAccount(storefrontAddress)
        .getCapability<&DriverzInsuranceStorefront.Storefront{DriverzInsuranceStorefront.StorefrontPublic}>(
            DriverzInsuranceStorefront.StorefrontPublicPath
        )!
        .borrow()
        ?? panic("Could not borrow Storefront from provided address")

    let listingIDs = storefront.getListingIDs()
    var listingDetails: {UInt64: DriverzInsuranceStorefront.ListingDetails} = {}
    
    for listingID in listingIDs {
        let listing = storefront.borrowListing(listingResourceID: listingID)
            ?? panic("Could not borrow listing with provided ID")

        let listingID = listingID
        let price = listing.getDetails().salePrice

        nftData.append(NFTData(listingID: listingID, price: price))
    }
    
    return nftData

}

`
