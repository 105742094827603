import * as fcl from "@onflow/fcl";

fcl.config({
  "discovery.wallet": process.env.REACT_APP_DAPPER_WALLET_DISCOVERY,
  "discovery.wallet.method": process.env.REACT_APP_DAPPER_WALLET_DISCOVERY_METHOD,
  "accessNode.api": process.env.REACT_APP_FLOW_ACCESS_NODE,
  "app.detail.title": process.env.REACT_APP_APP_NAME,
  "app.detail.icon": process.env.REACT_APP_APP_ICON,
  "discovery.authn.endpoint": "https://fcl-discovery.onflow.org/api/authn",
  "discovery.authn.include": [
    "0xead892083b3e2c6c", // Dapper Wallet
  ],
});

console.log("Access Node:", process.env.REACT_APP_FLOW_ACCESS_NODE);