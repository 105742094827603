import { FaYoutube, FaTwitter } from 'react-icons/fa';
import './Team.css';
import Lee from '../../assets/Images/DLee.png';
import Lead from '../../assets/Images/Lead.png';
import Dolo from '../../assets/Images/doloPFP.png';
import JD from '../../assets/Images/JDpfp.png';
import sushi from '../../assets/Images/Sushi.png';
import riki from '../../assets/Images/rikiPFP.png';
import bckgd from '../../assets/Images/Pitcrew_Team.jpg';
import mike from '../../assets/Images/mike.png';
import fubar from '../../assets/Images/Fubar.png'

{/*DOLO TWITTER LINK: https://twitter.com/dolodood*/} 

export default function Team() {
    return(
        <div className='team-container'>
            <div className='first-row'>
                <img src={bckgd} alt="" className='team-full-width-image' />
            </div>
            <div className='second-row-team'>
                <div className='column-team'>
                    <img src={Lee} alt="" />
                    <h3>DLee</h3>
                    <p>Owner of Driverz Inc. <br />Genesis Artist/Founder</p>
                    <a href="https://twitter.com/D2Leee" target="_blank" rel="noopener noreferrer">
                        <FaTwitter size={24}/>
                    </a>
                </div>
                <div className='column-team'>
                    <img src={Lead} alt="" />
                    <h3>Lead</h3>
                    <p>Chief Executive Officer</p>
                    <a href='https://twitter.com/lead2324' target='_blank' rel='noopener noreferrer'>
                        <FaTwitter size={24} />
                    </a>
                </div>
                <div className='column-team'>
                    <img src={sushi} alt='' />
                    <h3>Sushi</h3>
                    <p>Artist/Graphic Designer</p>
                    <a href='https://twitter.com/SonOfASushiChef' target='_blank' rel='noopener noreferrer'>
                        <FaTwitter size={24} />
                    </a>
                </div>
                <div className='column-team'>
                    <img src={riki} alt="" />
                    <h3>Riki</h3>
                    <p>Web3 Stack Developer</p>
                    <a href='https://www.youtube.com/channel/UC82SECj9t9ev3y8C5pnp5xA' target='_blank' rel='noopener noreferrer'>
                        <FaYoutube size={24} />
                    </a>
                </div>
                <div className='column-team'>
                    <img src={Dolo} alt="" />
                    <h3>Dolo</h3>
                    <p>Social Media Manager</p>
                    <a href='https://twitter.com/dolodood' target='_blank' rel='noopener noreferrer'>
                        <FaTwitter size={24} />
                    </a>
                </div>
                {/*<div className='column-team'>
                    <img src={JD} alt="" />
                    <h3>JD</h3>
                    <p>Racing & Automotive Partnerships</p>
                    <a href='https://twitter.com/jd_grice?t=W-nZ8chSbl2b6artCtc0iw&s=33' target='_blank' rel='noopener noreferrer'>
                        <FaTwitter size={24} />
                    </a>
                </div>*/}
                <div className='column-team'>
                    <img src={fubar} alt="" />
                    <h3>Fubar</h3>
                    <p>Simulation Stream Director</p>
                    <a href='https://twitter.com/fistsofdiamond' target='_blank' rel='noopener noreferrer'>
                        <FaTwitter size={24} />
                    </a>
                </div>
            </div>
        </div>

    )
}