import { useState, useEffect } from "react";
import { Row, Accordion, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as fcl from "@onflow/fcl";
import './marketPlace.css'

import { getAddresses } from "../../Cadence/marketplace/getAddresses";
import { getAllListingsAllAddresses } from "../../Cadence/marketplace/getAllListingsAllAddresses";
import { createListingGensis } from "../../Cadence/marketplace/createListingGenesis"
import { purchaseGensis } from "../../Cadence/marketplace/purchaseGenesis";
import { purchaseCar } from "../../Cadence/marketplace/purchaseCar";
import { purchaseWheel } from "../../Cadence/marketplace/purchaseWheel";
import { purchaseHelmet } from "../../Cadence/marketplace/purchaseHelmet";
import { purchaseNFTDapper } from "../../Cadence/marketplace/purchaseNFTDapper";

import SaleNFTItem from "../../components/SaleNFTItem";
import Traits from "../../data/tire_traits.json";
import Helmet_Traits from "../../data/helmet_traits.json";
import Wheel_Traits from "../../data/wheel_traits.json";
import Car_Traits from "../../data/car_traits.json";
import bckgd from "../../assets/Images/Driverz_Marketplace.jpg"


export default function Marketplace() {

    const [nftData, setNftData] = useState({}); // define the state variable here as an object
    const [isLoading, setIsLoading] = useState(true);
    const [userAddress, setUserAddress] = useState(null);
    const [listingIdToWallet, setListingIdToWallet] = useState({});

    const getListed = async () => {
        setIsLoading(true);


        //get addresses of accounts that have NFTs listed on the Driverz Storefront contract
        const res = await fcl.query({
            cadence: getAddresses,
        });

        const filteredRes = res.filter((item) => item !== '0xc95d50f8fc3d8986');

        console.log('addresses - ', filteredRes);

        //run the addresses through the next script to get all the listings for each address.
        const data = await fcl.query({
            cadence: getAllListingsAllAddresses,
            args: (arg, t) => [arg(filteredRes, t.Array(t.Address))],
        });

        //map the listing IDs to the account addresses, so that you can pass them into the Cadence transaction.
        const listingIdToWalletMap = {};
        for (let [wallet, nfts] of Object.entries(data)) {
            for (let nft of nfts) {
                listingIdToWalletMap[nft.listingId] = wallet;
            }
        }

        console.log('listingIdToWallet - ', listingIdToWalletMap);
        


       console.log('sale - ', data);
       setNftData(data);
       setListingIdToWallet(listingIdToWalletMap)
       setIsLoading(false);
    }

        


    useEffect(() => {
        async function fetchUserAddress() {
            const { addr } = await fcl.currentUser().snapshot();
            setUserAddress(addr);
        }

        fetchUserAddress();
        getListed();
    }, []);


//Below allows you to run the purchaseCar transaction when the button is clicked, from the HTML code.
    const purchaseCarisNFT = async (listedId, price) => {
        const sellerAddress = listingIdToWallet[listedId];
        try {
            console.log("Preparing to send mutation",
            "Seller Address - ", sellerAddress,
            "Listing Id - ", listedId,
            "Price - ", price);
            const txid = await fcl.mutate({
                cadence: purchaseCar,
                args: (arg, t) => [
                    arg(sellerAddress, t.Address),
                    arg(listedId, t.UInt64),
                    arg(price, t.UFix64)
                
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
        } catch (error) {}
    }

    const purchaseDriverzAirdropisNFT = async (listedId, price) => {
        const sellerAddress = listingIdToWallet[listedId];
        try {
            const txid = await fcl.mutate({
                cadence: purchaseNFTDapper,
                args: (arg, t) => [
                    arg(sellerAddress, t.Address),
                    arg(listedId, t.UInt64),
                    arg(price, t.UFix64)
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
        } catch (error) {}
    }

    const purchaseHelmetisNFT = async (listedId, price) => {
        const sellerAddress = listingIdToWallet[listedId];
        try {
            const txid = await fcl.mutate ({
                cadence: purchaseHelmet,
                args: (arg, t) => [
                    arg(sellerAddress, t.Address),
                    arg(listedId, t.UInt64),
                    arg(price, t.UFix64)
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });
        } catch (error) {}
    }

    const purchaseWheelNFT = async (listedId, price) => {
        const sellerAddress = listingIdToWallet[listedId];
        try {
            const txid = await fcl.mutate ({
                cadence: purchaseWheel,
                args: (arg, t) => [
                    arg(sellerAddress, t.Address),
                    arg(listedId, t.UInt64),
                    arg(price, t.UFix64)
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            })
        } catch (error) {}
    }

    const purchaseGenesisNFT = async (listedID, price) => {
        console.log("Starting purchaseGenesisNFT");
        const sellerAddress = listingIdToWallet[listedID];
        console.log("Seller Address - ", sellerAddress)
        try {
            console.log("Preparing to send mutation");
            const txid = await fcl.mutate({
                cadence: purchaseGensis,
                args: (arg, t) => [
                arg(sellerAddress, t.Address),
                arg(listedID, t.UInt64),
                arg(price, t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64)//
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });

            console.log(txid);
            toast.success("Successfully minted Car Club!");
        } catch (error) {
            console.log("err", error);
            console.log("error.message", error.message);
    
            fetch("/log", {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify({ error: error.message }),
            })
                .then((res) => {
                if (res.ok) {
                    console.log("Error logged successfully");
                } else {
                    console.log("Error logging failed:", res.status);
                }
                })
                .catch((fetchError) => {
                console.log("Error logging fetch error:", fetchError);
                });
            
            toast.error(error);
            }
    }
        

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const flattenedNftData = Object.values(nftData).flat();

    return (
        <div className="marketplace-page">
            <div className="first-row">
                <img src={bckgd} alt="marketplace background image" />
            </div>
            <div className="nft-tiles">
                {/*{Object.entries(nftData).map(([wallet, nfts], index) => (
                    <div key={index}> */}
                        {/*<p>Wallet: {wallet}</p> */}
                        {flattenedNftData.map((nft, index) => (
                            <div key={index} className="nft-tile">
                                <div className="image-container">
                                    <img src={nft.thumbnail} alt={nft.name} />
                                </div>
                                <p>NFT ID: {nft.nftId}</p>
                                <p>NFT Type: {nft.nftType}</p>
                                <p>Name: {nft.name}</p>
                                {/*<p>Amount: {nft.amount}</p> */}
                                <p>Price: {Number(nft.amount).toFixed(2)}</p>
                                {nft.nftType === "DriverzNFT" && (
                                    <Button variant="primary" onClick={() => {
                                        console.log(`listingId: ${nft.listingId}, Amount: ${nft.amount}`);
                                        purchaseGenesisNFT(nft.listingId, nft.amount);
                                        }}>
                                        Purchase
                                    </Button>  
                                )}
                                {nft.nftType === "Car" && (
                                    <Button variant="primary" onClick={() => {
                                        purchaseCarisNFT(nft.listingId, nft.amount);
                                    }}>
                                        Purchase
                                    </Button>
                                )}
                                {nft.nftType === "DriverzAirdrop" && (
                                    <Button variant="primary" onClick={() => {
                                        purchaseDriverzAirdropisNFT(nft.listingId, nft.amount);
                                    }}>
                                        Purchase
                                    </Button>
                                )}
                                {nft.nftType === "Helmet" && (
                                    <Button variant="primary" onClick={() => {
                                        purchaseHelmetisNFT(nft.listingId, nft.amount);
                                    }}>
                                        Purchase
                                    </Button>
                                )}
                                {nft.nftType === "Wheel" && (
                                    <Button  variant="primary" onClick={() => {
                                        purchaseWheelNFT(nft.listingId, nft.amount);
                                    }}>
                                        Purchase
                                    </Button>
                                )}
                            </div>
                        ))}
                    {/*</div>
                ))}*/}
            </div>
        </div>
    )
}

{/* 

if (ownGenesis) {
    var price = parseFloat(mintCount * 37);

    try {
      const txid = await fcl.mutate({
        cadence: createListingGensis,
        args: (arg, t) => [
          arg('0x6817fd8ae667556e', t.Address),
          arg('0xf887ece39166906e', t.Address),
          arg(listedID, t.Array(t.UInt64)),
          arg(price + ".0", t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64)//
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      console.log(txid);
      toast.success("Successfully minted Car Club!");
    } catch (error) {
      console.log("err", error);
      console.log("error.message", error.message);

      fetch("/log", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ error: error.message }),
        })
          .then((res) => {
            if (res.ok) {
              console.log("Error logged successfully");
            } else {
              console.log("Error logging failed:", res.status);
            }
          })
          .catch((fetchError) => {
            console.log("Error logging fetch error:", fetchError);
          });
      
        toast.error(error);
      }
      
  } */}