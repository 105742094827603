
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './lib/fcl/config';
import * as fcl from '@onflow/fcl'


if (window.location.protocol !== 'https:') {
  window.location.href = `https:${window.location.href.substring(window.location.protocol.length)}`;
} 


fcl.config()
.put("accessNode.api", process.env.REACT_APP_FLOW_ACCESS_NODE)
.put("discovery.wallet", process.env.REACT_APP_DAPPER_WALLET_DISCOVERY)
.put("discovery.wallet", "https://fcl-discovery.onflow.org/authn", "dapper") // Discovery wallet for Blocto

console.log("Flow Access Node:", process.env.REACT_APP_FLOW_ACCESS_NODE);
console.log("Dapper Wallet Discovery:", process.env.REACT_APP_DAPPER_WALLET_DISCOVERY);






const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
