export const purchaseCarClubListing = `
// Mainnet
import NonFungibleToken from 0x1d7e57aa55817448
import CarClub from 0xf887ece39166906e
import FungibleToken from 0xf233dcee88fe0abe
import VroomToken from 0xf887ece39166906e
import CarClubMainStorefront from 0xf887ece39166906e
import CarClubTracker from 0x66b60643244a7738

// Testnet
// import NonFungibleToken from 0x631e88ae7f1d7c20
// import CarClub from 0x6e9ac121d7106a09
// import FungibleToken from 0x9a0766d93b6608b7
// import VroomToken from 0x6e9ac121d7106a09
// import CarClubStorefront from 0x6e9ac121d7106a09

transaction(listingResourceID: UInt64, storefrontAddress: Address, userAddress: Address, itemType: String, rollout: String) {
    let paymentVault: @FungibleToken.Vault
    let nftCollection: &NonFungibleToken.Collection{NonFungibleToken.Receiver}
    let storefront: &CarClubMainStorefront.Storefront{CarClubMainStorefront.StorefrontPublic}
    let listing: &CarClubMainStorefront.Listing{CarClubMainStorefront.ListingPublic}
    let salePrice: UFix64
    let balanceBeforeTransfer: UFix64
    let vroomTokenVault: &VroomToken.Vault

    prepare(buyer: AuthAccount) {
                // Initialize the buyer's collection if they do not already have one
        if buyer.borrow<&CarClub.Collection>(from: CarClub.CollectionStoragePath) == nil {
            let collection <- CarClub.createEmptyCollection() as! @CarClub.Collection
            buyer.save(<-collection, to: CarClub.CollectionStoragePath)
            
            buyer.link<&CarClub.Collection{NonFungibleToken.CollectionPublic, CarClub.CollectionPublic}>(
                CarClub.CollectionPublicPath,
                target: CarClub.CollectionStoragePath
            )
             ?? panic("Could not link collection Pub Path");
        }

                // Get the storefront reference from the seller
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&CarClubMainStorefront.Storefront{CarClubMainStorefront.StorefrontPublic}>(
                CarClubMainStorefront.StorefrontMainPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

                    // Get the listing by ID from the storefront
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        self.salePrice = self.listing.getDetails().salePrice

                // Get a DUC vault from Dapper's account
        self.vroomTokenVault = buyer.borrow<&VroomToken.Vault>(from: /storage/VroomTokenVault)
            ?? panic("Cannot borrow VroomToken vault from account storage")
        self.balanceBeforeTransfer = self.vroomTokenVault.balance
        self.paymentVault <- self.vroomTokenVault.withdraw(amount: self.salePrice)

                // Get the collection from the buyer so the NFT can be deposited into it
        self.nftCollection = buyer.borrow<&NonFungibleToken.Collection{NonFungibleToken.Receiver}>(
            from: CarClub.CollectionStoragePath
        ) ?? panic("Cannot borrow NFT collection receiver from account")



    }

    execute {
        self.listing.purchase(
            payment: <- self.paymentVault,
            collection: self.nftCollection
        )

        CarClubTracker.addPurchase(userAddress: userAddress, itemType: itemType, rollout: rollout)
/*
        self.nftCollection.deposit(token: <- item)

        // Remove listing-related information from the storefront since the listing has been purchased.
        self.storefront.cleanup(listingResourceID: listingResourceID)


        //use below logic

                // Access the specific storefront to perform the purchase
        let storefront = getAccount(storefrontAddress)
                        .getCapability<&CarClubMainStorefront.Storefront{CarClubMainStorefront.StorefrontManager}>(CarClubMainStorefront.StorefrontMainStoragePath)
                        .borrow()
                        ?? panic("Could not borrow the storefront reference for purchasing")

        // Assumes the buyer's account is already set up with a collection that can receive the NFT
        let buyerCollection = signer.getCapability<&{NonFungibleToken.CollectionPublic}>(NonFungibleToken.CollectionPublicPath)
                            .borrow()
                            ?? panic("Could not borrow reference to the buyer's collection")
        
        // Performing the purchase from the storefront
        storefront.borrowListing(listingResourceID: listingResourceID)?.purchase(
            payment: <- self.paymentVault,
            collection: buyerCollection
        )*/

    }
}

`