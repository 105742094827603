import { useEffect, useState } from "react";
import { Button } from "react-bootstrap"
import { FaExternalLinkAlt } from 'react-icons/fa'
import { Link } from "react-router-dom";
import { TwitchChat } from 'react-twitch-embed';
import * as fcl from "@onflow/fcl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from "react-bootstrap";
import Racing_Sim from "../../assets/Images/Racing_Sim.png"
import { initInsurance } from "../../Cadence/insurance/initInsurance";
import "./Racing.css";
import racingTile1 from '../../assets/Images/DRLimage3-racing1.png';
import racingTile2 from '../../assets/Images/DRLimage2-racing2.png';


export default function Racing(){ 



  const Form = () => {
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
      name: '',
      lastName: '',
      email: '',
      userName: '',
      password: '',
      repeatPassword: ''
    })

  }

  const initInsuranceClick = async () => {
    try {
      const txid = await fcl.mutate({
        cadence: initInsurance,
        args: (arg, t) => [],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      // console.log(txid);
      toast.success("Successfully initialized for Driverz Insurance!");
    } catch (error) {
      // console.log("err", error);
      toast.error(error);
    }
  };

  return(
    <div className="racing-page">
      <div className="img-row">
        <img src={Racing_Sim} /> 
      </div>

      <div className="first-row">
        <h2>Enter YOUR Driverz to WIN Prizes!</h2>     
      </div>

      <div className="content-row">
        <div className='text-column'>
          <h3>Driverz Racing League</h3>
          <ul>
            <li><strong>Seasons:</strong> Monthly races in the DRL (Driverz Racing League) with new seasons starting quarterly.</li>
            <li><strong>Weekly Streams:</strong> Live streams every week starting in July.</li>
            <li><strong>Tournaments:</strong> Monthly tournaments with exciting prizes beginning in July.</li>
          </ul>

          <h3>Participation and Prizes:</h3>
          <ul>
            <li><strong>How to Enter:</strong> Owners can participate by filling out the racing sim form on our website (coming soon!).</li>
            <li><strong>Prizes:</strong> Winners will receive Driverz collectibles, as well as IRL (In Real Life) medals, trophies, and plaques.</li>
          </ul>
        </div>
        <div className="img-column">
          <img src={racingTile1} />
        </div>
      </div>

      <div className="content-row-img-left">
        <div className="img-column">
          <img src={racingTile2} />
        </div>
        <div className="text-column">
          <h3>Podium Finish and Benefits:</h3>
          <ul>
            <li><strong>Medals and Earn Rate Boost:</strong> If your Driverz lands on the podium, you will receive a medal on your profile to celebrate the victory. Additionally, the winning Driverz will receive a staking earn rate boost specific to that Driverz.</li>
            <li><strong>Seasons and Tournaments:</strong> Each Genesis Driverz is eligible for up to 4 seasons in the DRL or up to 12 tournaments before burning out.</li>
            <li><strong>Cooldown Period:</strong> After a Driverz has completed their racing eligibility, they will have a 1-season (3 month) cooldown period before they can race again.</li>
          </ul>

          <h3>Car Club Collectibles:</h3>
          <ul>
            <li><strong>Upgrades and Performance:</strong> Car Club Collectibles used as upgrades within the DRL simulations will undergo wear and tear during races.</li>
            <li><strong>Eligibility and Burning Out:</strong> These collectibles are eligible for up to 2 seasons of DRL and up to 6 tournaments before burning out.</li>
            <li><strong>Medals and Earn Rate Boost:</strong> If your Driverz lands on the podium with a Car Club Collectible, you will receive a medal on your profile and a staking earn rate boost for that specific collectible as an upgrade.</li>
            <li><strong>Cooldown Period:</strong> Once a Car Club Collectible burns out from racing, it can no longer participate in races until its cooldown period resets after a 1-season (3 month) break.</li>
          </ul>
        </div>
      </div>

      <div class="signup-row">
        <div class="signup-content">
          <h2>Join the Racing Simulation</h2>
        </div>
        <div class="signup-content">
          <Link to="/driverz">
            <Button variant="primary" className="mint-button">
              Enter Here
            </Button>
          </Link>  
        </div>
      </div>
{/*}
      <p className="text-white">
        Driverz has racing simulation tournaments, leagues, and giveaways delivered in two formats: Driverz Racing League & Driverz Turbo.
        <br/><br/>
        <Link to="/driverz">
          <Button variant="primary">
            Enter Here
          </Button>
        </Link>        
        <br/><br/>
        <b>Driverz Racing League</b>
        <br/><br/>
        Ultimate Racing 2D (game available on Steam) is programmed with our Driverz stats (shown in the pictures below) to deliver a simulation race for owners. 
        Races will be streamed live by our Simulation Director on his Twitch channel, and all winners will receive prizes. 
        Owners can earn "D-Coins" by participating in the Twitch Live Streams, which they can then use to bet on the races. 
        These coins will be transferrable in the future to the native VROOM token on our website that can be used to redeem 
        Driverz digital collectibles, merchandise, and in-real-life automotive, racing, and karting experiences in a city near you! 
        <br/><br/>
        Twitch - Driverz Racing League Live Stream: &nbsp;
        <a className="text-decoration-none" target="_blank" href="https://www.twitch.tv/driverzracingleague" alt="">
          https://www.twitch.tv/driverzracingleague
        </a>
        <br/>
        Ultimate Racing 2D video game on Steam: &nbsp;
        <a className="text-decoration-none" target="_blank" href="https://store.steampowered.com/app/808080/Ultimate_Racing_2D/" alt="">
          https://store.steampowered.com/app/
          808080/Ultimate_Racing_2D/
        </a>
      </p>

      <div className="row mb-5">
        <div className="col-md-4 p-1">
          <img className="mb-5" src="Racing_one.png" alt="" width='100%' height='auto' />
        </div>
        <div className="col-md-4 p-1">
          <img src="Racing_two.png" alt="" width='100%' height='auto' />
        </div>
        <div className="col-md-4 p-1">
          <img src="Racing_three.png" alt="" width='100%' height='auto' />
        </div>
      </div>

      <div className="row">
        <div className="col-md-10">
          <div className="twitch-pad mb-5">
            <div className="live-pad">
            </div>
            <div className="cover-live-pad">
              <a className='me-4' href="https://www.twitch.tv/driverzracingleague">
                <FaExternalLinkAlt className='text-white' size={40} />
              </a>
              <p className="text-white mt-3 font-13">
                Driverz Racing League
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-2">
          <TwitchChat channel="driverzracingleague" width="300" height="500" />
        </div>
      </div>  

      <p className="text-white">
        <b>Driverz Turbo</b>
        <br/><br/>
          Driverz Turbo is our homegrown racing simulator that was built in partnership with Moonshot Baseball 
          (Polygon Blockchain) project leader, Melverk. Driverz owners who enter to win Turbo Sims will have a chance to 
          receive collectibles and/or cash prizes. Read the below medium article for details on functionality of the sim. 
          Here is the first official community Grand Prix that was run when we launched Driverz Turbo! To see some of the 
          recent race results, scroll down below. You can submit your Driverz for our Turbo sim races with the 'Enter Here' 
          button at the top of this page. Vroom Vroom! 
        <br/>
        <a className="text-decoration-none" href="https://medium.com/@melverknft/driverz-turbo-7549d7ec1990" alt="">
          https://medium.com/@melverknft/driverz-turbo-7549d7ec1990
        </a>     
      </p>

      <div className="row mt-5 mb-5">
        <div className="col-md-8 m-auto">
          <video width="100%" height="auto" controls>
            <source src="Racing_video.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="row mb-5">
        <h3 className="text-white text-center mb-5">
          Driverz Turbo Grand Prix Replays
        </h3>

        <div className="col-md-4">
          <h5 className="text-white text-center mb-4">
            December 22' Champions Grand Prix
          </h5>
          <video width="100%" height="auto" controls>
            <source src="December_Champions_GP.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="col-md-4">
          <h5 className="text-white text-center mb-4">
            January 23' Owners Grand Prix
          </h5>
          <video width="100%" height="auto" controls>
            <source src="January_Owners_GP.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="col-md-4">
          <h5 className="text-white text-center mb-4">
            February 23' Champions Grand Prix
          </h5>
          <video width="100%" height="auto" controls>
            <source src="February_Champions_GP.mp4" type="video/mp4" />
          </video>
        </div>        

      </div>

      <div className="row mt-5 mb-5">
        <div className="col-md-4 m-auto">
          <Card bg="light" key="light" text="dark" className="mb-2">
            <Card.Img variant="top" src="./Trophy4.png" />
            <Card.Body>
              <Card.Title>
                Driverz Exclusives
              </Card.Title>
              <Card.Text>
                If you won a Driverz Exclusive, click 'Claim Prize' below
              </Card.Text>
          
              <Button variant="primary" onClick={initInsuranceClick}>
                Claim Prize
              </Button>              
            </Card.Body>
          </Card>
        </div>
  </div>*/}
    </div>
  )
}