import React from 'react';
import { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import "./HomePage.css";
import { ThreeDots } from 'react-loader-spinner';
import homePageBckgd from '../../assets/Images/Driverz.jpg';
import thumbnail1 from '../../assets/Images/icon1.png';
import thumbnail2 from '../../assets/Images/icon2.png';
import thumbnail3 from '../../assets/Images/icon3.png';
import r4columnleft from '../../assets/Images/Membership_airdrop3.png';
import r5columnright from '../../assets/Images/About_one.png';
import pfp1 from '../../assets/Images/atlpfp.jpg';
import pfp2 from '../../assets/Images/fubpfp.png';
import pfp3 from '../../assets/Images/rotpfp.jpg';
import pfp4 from '../../assets/Images/hogpfp.jpg';

import { initAll } from '../../Cadence/Transactions/initAll';


export default function HomePage () {
    const [isProcessing, setIsProcessing] = useState(false);

    const initAllCollections = async () => {
        setIsProcessing(true);
        try {
            const initAllCollec = await fcl.mutate({
                cadence: initAll,
                args: (arg, t) => [],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999
            });

            console.log(`Transaction ID: ${initAllCollec}`);
            await fcl.tx(initAllCollec).onceSealed();
            setIsProcessing(false);
        } catch (error) {}
    };


    const handleBuyToday = () => {
        // Redirect the user to the desired URL
        window.location.href = 'https://driverz.world/mint';
    };


    return (
    <div className="home-page">
        <div className='first-row'>
            <img src={homePageBckgd} alt='Home Page Background' />
            <h2>Join the Driverz Community and Own Your Piece<br /> of the Automotive World</h2>
            <div className='second-row'>
                    {isProcessing ? (
                        <div className='loading-spinner'>
                            <ThreeDots color="#f0f0f0" height={80} width={80} />
                        </div>
                    ) : (
                        <button onClick={initAllCollections} className="mint-button">Account Setup</button>
                    )}
                    <button onClick={handleBuyToday} className="mint-button">Buy Today</button>
                </div>
        </div>

        <div className='third-row'>
            <div className='column-1'>
                <img src={thumbnail1} alt='Thumbnail 1' />
                <h3>Fantasy F1 Leagues</h3>
                <p>Join Formula 1 Fantasy Leagues for a chance to win passive income and digital collectibles. Create a team and follow the instructions on the Formula 1 website to join all our eligible leagues.</p>
            </div>
            <div className='column-3'>
                <img src={thumbnail3} alt='Thumbnail 3' />
                <h3>Racing Simulations</h3>
                <p>Ultimate Racing 2D is programmed with our Driverz skills, and simulation races for owners are streamed live on Twitch. Winners of the races will receive prizes, and owners can earn D-Coins that they can use to bet on the races in the streams. These coins will later be exchangeable for our website’s VROOM token!</p>
            </div>
        </div>
        <div className='fourth-row'>
            <div className='left-column'>
                <img src={r4columnleft} alt="Image Left" />
            </div>
            <div className='right-column'>
                <h3>Driverz Inc. Origins</h3>
                <p>Driverz Inc. began as a project centered around Formula 1 racing. It aimed to build a community of passionate racing and automotive fans across the globe. The community grew quickly, and Driverz evolved into a platform that offers digital collectibles on the Flow Blockchain, with unique utility for our owners. Today, Driverz features our original Genesis series, our Utility Collection (which includes tires, helmets, steering wheels, and cars), and our brand new 3D Car Club Collection!</p>
            </div>
        </div>
        <div className='fifth-row'>
            <div className='left-column'>
                <h3>The Genesis Series</h3>
                <p>The Driverz Genesis Series is a collection of digital collectibles that unlocks exclusive utility and fosters community camaraderie. Owners gain access to a range of features and benefits that enhance their ownership experience, while also joining a community of like-minded racing and car enthusiasts.</p>
            </div>
            <div className='right-column'>
                <img src={r5columnright} alt='Image Right' />
            </div>
        </div>
        <div className='sixth-row'>
            <div className='column-one'>
                <img src={pfp1} alt='PFP Icon 1' />
                <p>“No other project has this amount of utility, DRL tournaments, Turbo SIMs, insane amount of giveaways, incredible community, fantasy involvement, IRL racing collaboration, o and did I forget about the only NFT with royalties?!?! All you race fans, sports fans, NFT fans, if you haven’t joined Driverz the time is now! Race to the top. #VroomVroom”<br /><br /> - Atlanta21</p>
            </div>
            <div className='column-two'>
                <img src={pfp2} alt='PFP Icon 2' />
                <p>“Won a Driverz genesis and started checking the utility and what owning one meant. A week later I was a Captain of a team and a week after that I owned another Captain and 25+ genesis NFTs… I actually started watching F1 because of Driverz but was quickly addicted. By the third week I was finished the 5 seasons of the Netflix series and had started to find ways to help give back and engage with the community.”<br /><br /> - Fubar | Cosmos Captain</p>
            </div>
            <div className='column-three'>
                <img src={pfp3} alt='PFP Icon 3' />
                <p>“I joined Driverz on Day 1. It’s been a great community to be in from the start. As the project evolved, it got even better and now with DRL sims and F1 fantasy, it’s fun too! Great community + great utility = long term success! Keep buidling! Driverz leads the pack! #VroomVroom”<br /><br /> - Roto</p>
            </div>
            <div className='column-four'>
                <img src={pfp4} alt='PFP Icon 4' />
                <p>“No lie, I joined Driverz because I won a free NFT (I own 7 Driverz now) but I ended up sticking around for the amazing community and DRL. I still get so hype for race days (you’d think I was actually driving in the race). Between the racing sims, F1 watch parties and team rivalries (Go Team Cosmos) there’s fun to be had for everyone. See you on the track! #VroomVroom”<br /><br /> - heirofgrace</p>
            </div>
        </div>
    </div>
    )
};

