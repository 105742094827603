export const revealCarClub = `
import NonFungibleToken from 0x1d7e57aa55817448
import CarClub from 0xf887ece39166906e

transaction (id: UInt64){

    prepare(user: auth(Storage, BorrowValue) &Account) {
        let userCap = user.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: /storage/CarClubCollection)
            ?? panic("Could not borrow a reference to the owner's collection")

        let depositRef = user.capabilities.borrow<&{NonFungibleToken.Collection}>(/public/CarClubCollection)
            ?? panic("Could not borrow a reference to the receiver's collection")

        let nft <- userCap.withdraw(withdrawID: id) as! @CarClub.NFT
        
        nft.revealThumbnail()
        
        depositRef.deposit(token: <-nft)
  }
}
`