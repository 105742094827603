export const getAllListingsAllAddresses = `
import MetadataViews from 0x1d7e57aa55817448
import DriverzNFTStorefront from 0xf887ece39166906e
import NonFungibleToken from 0x1d7e57aa55817448
import DriverzAirdrop from 0xf887ece39166906e
import Wheel from 0xf887ece39166906e
import Helmet from 0xf887ece39166906e
import Car from 0xf887ece39166906e
import DriverzNFT from 0xa039bd7d55a96c0c

pub struct ListedDriverzNftData {
    pub let nftId: UInt64
    pub let nftType: String
    pub let name: String
    pub let thumbnail: String
    pub let amount: UFix64
    pub let paymentVaultTypeID: Type
    pub let listingId: UInt64

    init(nftId: UInt64, nftType: String, name: String, thumbnail: String ,amount: UFix64, paymentVaultTypeID: Type, listingId: UInt64) {
        self.nftId = nftId
        self.nftType = nftType
        self.name = name
        self.thumbnail = thumbnail
        self.amount = amount
        self.paymentVaultTypeID = paymentVaultTypeID
        self.listingId = listingId
    }
}

pub fun main(addresses: [Address]): {Address: [ListedDriverzNftData]} {

    let allListingsDictionary: {Address: [ListedDriverzNftData]} = {}

    for address in addresses {

    let allListings: [ListedDriverzNftData] = []
    let account = getAccount(address)
    
    let marketCollectionRef = account
        .getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(
            DriverzNFTStorefront.StorefrontPublicPath
        )
        .borrow()
        ?? panic("Could not borrow market collection from address")

    for listingID in marketCollectionRef.getListingIDs(){
        
        let saleItem = marketCollectionRef.borrowListing(listingResourceID: listingID)
        ?? panic("No item with that ID")

        let listingDetails = saleItem.getDetails()!

        var nft: &NonFungibleToken.NFT? = nil 
        var nftType: String = ""

        switch listingDetails.nftType {
            case Type<@DriverzAirdrop.NFT>():
                let collection = account.getCapability(DriverzAirdrop.CollectionPublicPath).borrow<&{DriverzAirdrop.CollectionPublic}>()
                if(collection != nil){
                    if(collection!.borrowArt(id: listingDetails.nftID) != nil){
                        nft = collection!.borrowArt(id: listingDetails.nftID)
                        nftType = "DriverzAirdrop"
                    }
                }
            case Type<@Wheel.NFT>():
                let collection = account.getCapability(Wheel.CollectionPublicPath).borrow<&{Wheel.CollectionPublic}>()
                if(collection != nil){
                    if(collection!.borrowArt(id: listingDetails.nftID) != nil){
                        nft = collection!.borrowArt(id: listingDetails.nftID)
                        nftType = "Wheel"
                    }
                }
            case Type<@Helmet.NFT>():
                let collection = account.getCapability(Helmet.CollectionPublicPath).borrow<&{Helmet.CollectionPublic}>()
                if(collection != nil){
                    if(collection!.borrowArt(id: listingDetails.nftID) != nil){
                        nft = collection!.borrowArt(id: listingDetails.nftID)
                        nftType = "Helmet"
                    }
                }
            case Type<@Car.NFT>():
                let collection = account.getCapability(Car.CollectionPublicPath).borrow<&{Car.CollectionPublic}>()
                if(collection != nil){
                    if(collection!.borrowArt(id: listingDetails.nftID) != nil){
                        nft = collection!.borrowArt(id: listingDetails.nftID)
                        nftType = "Car"
                    }
                }
            case Type<@DriverzNFT.NFT>():
                    let collection = account.getCapability(DriverzNFT.CollectionPublicPath).borrow<&{DriverzNFT.CollectionPublic}>()
                    if(collection != nil){
                        if(collection!.borrowNFT(id: listingDetails.nftID) != nil){
                            nft = collection!.borrowNFT(id: listingDetails.nftID)
                            nftType = "DriverzNFT"
                        }
                    }
        }
        
        if(nft != nil){
            if let view = nft!.resolveView(Type<MetadataViews.Display>()) {
                
                let display = view as! MetadataViews.Display

                let url = "https://ipfs.io/ipfs/".concat(display.thumbnail.uri().slice(from: 7, upTo: display.thumbnail.uri().length))

                let listedDriverzNftData = ListedDriverzNftData(
                    nftId: listingDetails.nftID,
                    nftType: nftType,
                    name: display.name,
                    thumbnail: url,
                    amount: listingDetails.salePrice,
                    paymentVaultTypeID: listingDetails.salePaymentVaultType,
                    listingId: listingID
                )
                
                allListings.append(listedDriverzNftData)
            }
        }
    }
        allListingsDictionary[address] = allListings
    }

    return allListingsDictionary

}
`;
