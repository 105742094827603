export const purchaseNFTDapper = `
// import FungibleToken from 0x9a0766d93b6608b7
// import DriverzAirdrop from 0x5246dbfcadae93b9
// import NonFungibleToken from 0x631e88ae7f1d7c20
// import DriverzNFTStorefront from 0x5246dbfcadae93b9
// import DapperUtilityCoin from 0x82ec283f88a62e65
// import MetadataViews from 0x631e88ae7f1d7c20

import FungibleToken from 0xf233dcee88fe0abe
import DriverzAirdrop from 0xf887ece39166906e
import NonFungibleToken from 0x1d7e57aa55817448
import DriverzNFTStorefront from 0xf887ece39166906e
import DapperUtilityCoin from 0xead892083b3e2c6c
import MetadataViews from 0x1d7e57aa55817448

// This transaction purchases an NFT on a peer-to-peer marketplace (i.e. **not** directly from a dapp). This transaction
// will also initialize the buyer's NFT collection on their account if it has not already been initialized.
// FIRST ARGUMENT OF A P2P PURCHASE TRANSACTION SHOULD ALWAYS BE THE SELLER'S ADDRESS
transaction(storefrontAddress: Address, listingResourceID: UInt64,  expectedPrice: UFix64) {
    let paymentVault: @FungibleToken.Vault
    let nftCollection: &DriverzAirdrop.Collection{NonFungibleToken.Receiver}
    let storefront: &DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}
    let listing: &DriverzNFTStorefront.Listing{DriverzNFTStorefront.ListingPublic}
    let salePrice: UFix64
    let balanceBeforeTransfer: UFix64
    let mainDapperUtilityCoinVault: &DapperUtilityCoin.Vault

    prepare(dapper: AuthAccount, buyer: AuthAccount) {
        // Initialize the buyer's collection if they do not already have one
        if buyer.borrow<&DriverzAirdrop.Collection>(from: DriverzAirdrop.CollectionStoragePath) == nil {
            let collection <- DriverzAirdrop.createEmptyCollection() as! @DriverzAirdrop.Collection
            buyer.save(<-collection, to: DriverzAirdrop.CollectionStoragePath)
            
            buyer.link<&DriverzAirdrop.Collection{NonFungibleToken.CollectionPublic, DriverzAirdrop.CollectionPublic}>(
                DriverzAirdrop.CollectionPublicPath,
                target: DriverzAirdrop.CollectionStoragePath
            )
             ?? panic("Could not link collection Pub Path");
        }

        // Get the storefront reference from the seller
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(
                DriverzNFTStorefront.StorefrontPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Get the listing by ID from the storefront
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        self.salePrice = self.listing.getDetails().salePrice

        // Get a DUC vault from Dapper's account
        self.mainDapperUtilityCoinVault = dapper.borrow<&DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
            ?? panic("Cannot borrow DapperUtilityCoin vault from account storage")
        self.balanceBeforeTransfer = self.mainDapperUtilityCoinVault.balance
        self.paymentVault <- self.mainDapperUtilityCoinVault.withdraw(amount: self.salePrice)

        // Get the collection from the buyer so the NFT can be deposited into it
        self.nftCollection = buyer.borrow<&DriverzAirdrop.Collection{NonFungibleToken.Receiver}>(
            from: DriverzAirdrop.CollectionStoragePath
        ) ?? panic("Cannot borrow NFT collection receiver from account")
    }

    // Check that the price is right
    pre {
        self.salePrice == expectedPrice: "unexpected price"
    }

    execute {
        let item <- self.listing.purchase(
            payment: <-self.paymentVault
        )

        self.nftCollection.deposit(token: <-item)

        // Remove listing-related information from the storefront since the listing has been purchased.
        self.storefront.cleanup(listingResourceID: listingResourceID)
    }

    // Check that all dapperUtilityCoin was routed back to Dapper
    post {
        self.mainDapperUtilityCoinVault.balance == self.balanceBeforeTransfer: "DapperUtilityCoin leakage"
    }
}

//flow transactions build --proposer=ted --payer=ted --authorizer=emulator-account --authorizer=ben -n=emulator transactions/purchaseNFTDapper.cdc '0x01cf0e2f2f715450' '36' '150.00' --filter payload --save tx1

//flow transactions sign tx1 --signer emulator-account --filter payload --save tx2 -n=emulator

//flow transactions sign tx2 --signer ben --filter payload --save tx3 -n=emulator 

//flow transactions sign tx3 --signer ted --filter payload --save tx4 -n=emulator 

//flow transactions send-signed tx4 -n=emulator
`