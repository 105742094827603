export const getCarClubListingDetails = `
import CarClubMainStorefront from 0xf887ece39166906e

pub struct ListingData {
    pub let listingId: UInt64
    pub let price: UFix64
    pub let purchased: Bool
    pub let storefrontAddress: Address

    init(listingId: UInt64, price: UFix64, purchased: Bool, storefrontAddress: Address) {
        self.listingId = listingId
        self.price = price
        self.purchased = purchased
        self.storefrontAddress = storefrontAddress
    }
}

pub fun main(accountAddress: Address): [ListingData] {
    let storefrontRef = getAccount(accountAddress)
        .getCapability<&CarClubMainStorefront.Storefront{CarClubMainStorefront.StorefrontPublic}>(CarClubMainStorefront.StorefrontMainPublicPath)
        .borrow()
        ?? panic("Could not borrow a reference to the storefront")

    let listingIDs = storefrontRef.getListingIDs()
    var listingsData: [ListingData] = []

    for id in listingIDs {
        let listingRef = storefrontRef.borrowListing(listingResourceID: id)
            ?? panic("Could not borrow a reference to the listing")
        let details = listingRef.getDetails()
        let listingData = ListingData(
            listingId: id, 
            price: details.salePrice, 
            purchased: details.purchased, 
            storefrontAddress: accountAddress
        )
        listingsData.append(listingData)
    }

    return listingsData
}

`