import bckgd from '../../assets/Images/Membership_Utility.jpg'
import gen_1 from '../../assets/Images/Membership_genesis1.png'
import thmbnl_1 from '../../assets/Images/Membership_airdrop1.png'
import thmbnl_2 from '../../assets/Images/Membership_airdrop2.png'
import thmbnl_3 from '../../assets/Images/Membership_airdrop4.png'
import banner_1 from '../../assets/Images/utility_column_banner.jpg'
import sim_1 from '../../assets/Videos/Racing_video.mp4'
import merch from '../../assets/Images/Merchandise.png'
import cClub_1 from '../../assets/Images/carClub3.png'
import cClub_2 from '../../assets/Images/carClub4.png'
import cClub_spin from '../../assets/Videos/5159-1.mp4'
import gen_2 from '../../assets/Images/genesis4.png'
import './membership.css'
import {Button} from "react-bootstrap"


export default function Utility () {
    return(
        <div className='container mt-5 utility-page'>
            <div className='first-row'>
                <img src={bckgd} alt='Membership Utility Background' />
                <h2>
                Experience the Thrill of Ownership with Driverz Inc.: Unmatched Rewards and Exclusive Privileges
                </h2>
            </div>

          {/*  <div className='text-center second-row'>
                <h2>
                Experience the Thrill of Ownership with Driverz Inc.: Unmatched Rewards and Exclusive Privileges
                </h2>
            </div> */}

            <div className='third-row'>
                <div className='left-column'>
                    <img src={gen_1} alt='left column genesis driver one' />
                </div>
                <div className='text-column'>
                    <h3>Genesis Owner Utility</h3>
                    <p>Step into the world of high-speed exclusivity with the Genesis Collection, a unique assembly of 5500 collectibles, accessible on the Gaia Marketplace. As a proud Genesis owner, you'll be in the driver's seat, enjoying a ride that's continually evolving with fresh advantages for our valued community.
                    <br /> <br />Unleash your inner speed demon - Explore the sleek and powerful Genesis Collection on the Gaia Marketplace today</p>
                    <Button variant="primary" className="gen-button">
                    <a href="https://ongaia.com/driverz" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        Explore Genesis Collection
                    </a>
                        
                    </Button>
                </div>
            </div>

            <div className='fourth-row'>
                
                <div className='text-column'>
                <h3>Fantasy Formula 1 Leagues</h3>
                <p>
                    As a proud owner of Driverz, you have the opportunity to participate in up to 5 different leagues, each offering cash prizes during every Formula One race of the season. The leagues – Owners, Pitcrew, Elite, and Champions – have different entry requirements based on the number of Driverz held in your wallet:
                </p>

                <ul>
                    <li>Owners (Tier 1): 1-5 Driverz</li>
                    <li>Pitcrew (Tier 2): 6-12 Driverz</li>
                    <li>Elite (Tier 3): 13-24 Driverz</li>
                    <li>Champions (Tier 4): 25 or more Driverz</li>
                </ul>

                <p>
                    The more Driverz you own, the greater your chances of winning cash and prizes! With fewer participants in the higher tiers, your odds of winning increase. Each owner will receive a unique code to join all eligible leagues based on their Driverz ownership.
                </p>

                <p>
                    Additionally, the Constructors Cup offers yet another avenue for cash prizes. This 10-week staking league allows holders to enter specific Driverz from their collection to boost and advantage their chosen fantasy F1 roster. Interested in joining the Constructors Cup? Find out more in our Discord.
                </p>

                <p>
                    Set one lineup per race, enter all eligible leagues simultaneously, and then sit back, relax, and enjoy the exhilarating ride that is Formula One racing. Vroom Vroom!
                </p>
                </div>
                <div className='right-column'>
                    <img src={banner_1} alt="banner column" className='full-height-img' />
                    
                </div>
            </div>

            <div className='fifth-row'>
                <div className='left-column'>
                    <img src={gen_2} alt="" />
                    {/*
 
                    */}
                </div>
                <div className='text-column'>
                    <h3>Cash Royalties</h3>
                    <p>
                        When you own a 3D Captain Driverz NFT, you're not just acquiring a unique piece of digital art. You're also unlocking a profitable opportunity: a substantial 25% royalty share from the secondary market profits of Driverz! Experience the exhilaration of watching your asset appreciate in value and your earnings grow.
                    </p>
                    <p>
                        Don't have a Captain? No problem! By gathering a collection of 25 Driverz NFTs, you can still gain access to a rewarding 20% royalty share from the secondary market sales.
                    </p>
                    <p>
                        The best part? These profits aren't a one-time deal. All royalties are paid out to eligible owners on a quarterly basis. So why wait? Start building your Driverz collection now, and strap in for a thrilling, rewarding journey!
                    </p>
                </div>
            </div>

            <div className='sixth-row'>
                <div className='text-column'>
                    <h3>Driverz Racing Simulation</h3>
                    <p>
                        As an owner, you're not just a spectator - you're part of the action! The Genesis Driverz collection gives you exclusive access to exhilarating racing simulations, designed to put your Driverz' unique skill traits to the ultimate test.
                    </p>
                    <p>
                        Engage in pulse-pounding, wheel-to-wheel battles in the Driverz Racing League or feel the adrenaline rush of high-speed, no-holds-barred action in Driverz Turbo.
                    </p>
                    <p>
                        Craving more details? Further information about these immersive simulations is just a click away. So strap in, start your engines, and prepare for an unforgettable racing experience!
                    </p>
                </div>
                <div className='right-column'>
                    <video autoPlay loop muted playsInline>
                        <source src={sim_1} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>

            <div className='seventh-row'>
                <div className='left-column'>
                    <img src={thmbnl_3} alt="" />
                </div>
                <div className='text-column'>
                    <h3>Formula 1 Live Streams</h3>
                    <p>Experience the exhilaration of every Formula One race with us, live, throughout the season. Whether you're a holder or just someone who enjoys the thrill of racing, we invite you to join the spectacle. In the 2023 season, anticipate engaging discussions with Driverz team members and special guests as we collectively revel in the excitement of each race. So mark your calendars and make race day a day to remember with us!</p>
                </div>
                
            </div>

            <div className='eigth-row'>
                <div className='text-column'>
                    <h3>Driverz Merchandise</h3>
                    <p>We are excited to announce that <strong>Driverz</strong> has partnered with the renowned <strong>Web3Wear</strong> to bring you an exciting range of merchandise. From stylish clothing to unique cell phone cases, our online store boasts a diverse array of products designed to cater to your tastes. </p>
                    <p>But that's not all! As an owner, you have the exclusive opportunity to own 1/1 pieces featuring your very own NFTs. These one-of-a-kind pieces are a fantastic way to showcase your unique digital assets in the physical world. Take your NFT experience to the next level by exploring our merchandise <a href="https://w3w.ai/main/driverz">here</a>.</p>
                </div>
                <div className='left-column'>
                    <img src={merch} alt="" />
                </div>
            </div>

            <div className='ninth-row'>
                <div className='left-column'>
                    <video autoPlay loop muted playsInline>
                        <source src={cClub_spin} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='text-column'>
                    <h3>Official Rarity Tool</h3>
                    <p>At <strong>Driverz</strong>, we believe in transparency and providing our owners and potential buyers with comprehensive information about the rarity of our NFTs. Therefore, we are thrilled to present our official rarity tool, powered by FlowScore NFT. </p>
                    <p>FlowScore NFT is an advanced evaluation system that assesses the rarity of Flow blockchain-based NFTs, enabling you to make informed decisions about your digital asset investments. With this tool, you can delve into the unique characteristics of each Driverz NFT and understand the factors that contribute to its scarcity and value.</p>
                    <p>Whether you're an existing owner looking to explore your collection's rarity or a prospective buyer conducting due diligence, we've got you covered. Discover, analyze, and unlock the potential of your NFTs today by visiting our rarity tool <a href="https://www.flowscorenft.com/#/DRVZ" target="_blank">here</a>.</p>
                </div>
            </div>

            <div className='tenth-row'>
                <div className='text-column'>
                    <h3>Buy Back Program</h3>
                    <p>At <strong>Driverz</strong>, we are committed not just to sustaining, but actively enriching our vibrant community. That's why we've initiated our <em>Buy-Back Program</em>. </p>
                    <p>Every quarter, our team steps into the market to acquire Driverz NFTs. But these aren't just any purchases - they serve a significant purpose. These NFTs are used to fuel a variety of exciting events and engagement activities, all designed to bring our community closer together and create unforgettable experiences.</p>
                    <p>This isn't just a way to maintain market dynamics - it's a tangible demonstration of our commitment to giving back to the passionate individuals who make up the Driverz community. Stay tuned for our exciting events and stand a chance to be part of the action!</p>
                </div>
                <div className='right-column'>
                    <img src={cClub_1} alt="" />
                </div>
            </div>

            <div className='eleventh-row'>
                <div className='left-column'>
                    <img src={cClub_2} alt="" />
                </div>
                <div className='text-column'>
                    <h3>Official Charity Bank</h3>                    
                    <p>
                    Making a positive impact is at the heart of the Driverz community. We've established a unique charity bank where you can donate cash or NFTs to meaningful causes. Twice a year, these valuable contributions will be donated to a charity chosen by our community. It's an opportunity for you to not only give back but to help steer our collective contribution to where it's needed most. Join us in making a difference, learn more and start your donation journey here: &nbsp;
                    <a href="https://www.piggobank.io/driverz" target="_blank">
                        https://www.piggobank.io/driverz
                    </a>
                    </p>
                </div>
            </div>
{/*
            <div className='fifth-row'>
                <div className='column-tile'>
                    <img src={thmbnl_1} alt="" /> 
                    <h3>Cash Royalties</h3>
                    <p>
                        When you own a 3D Captain Driverz NFT, you're not just acquiring a unique piece of digital art. You're also unlocking a profitable opportunity: a substantial 25% royalty share from the secondary market profits of Driverz! Experience the exhilaration of watching your asset appreciate in value and your earnings grow.
                    </p>
                    <p>
                        Don't have a Captain? No problem! By gathering a collection of 25 Driverz NFTs, you can still gain access to a rewarding 20% royalty share from the secondary market sales.
                    </p>
                    <p>
                        The best part? These profits aren't a one-time deal. All royalties are paid out to eligible owners on a quarterly basis. So why wait? Start building your Driverz collection now, and strap in for a thrilling, rewarding journey!
                    </p>
                </div>
                <div className='column-tile'>
                    <img src={thmbnl_2} alt="" />
                    <h3>Driverz Racing Simulation</h3>
                    <p>
                        As an owner, you're not just a spectator - you're part of the action! The Genesis Driverz collection gives you exclusive access to exhilarating racing simulations, designed to put your Driverz' unique skill traits to the ultimate test.
                    </p>
                    <p>
                        Engage in pulse-pounding, wheel-to-wheel battles in the Driverz Racing League or feel the adrenaline rush of high-speed, no-holds-barred action in Driverz Turbo.
                    </p>
                    <p>
                        Craving more details? Further information about these immersive simulations is just a click away. So strap in, start your engines, and prepare for an unforgettable racing experience!
                    </p>
                </div>
                <div className='column-tile'>
                    <img src={thmbnl_3} alt="" />
                    <h3>Formula 1 Live Streams</h3>
                    <p>Experience the exhilaration of every Formula One race with us, live, throughout the season. Whether you're a holder or just someone who enjoys the thrill of racing, we invite you to join the spectacle. In the 2023 season, anticipate engaging discussions with Driverz team members and special guests as we collectively revel in the excitement of each race. So mark your calendars and make race day a day to remember with us!</p>
                </div>
            </div> */}

        </div>
    )
}