import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig2 = {
  apiKey: "AIzaSyA6gW2Gr81olpOmJFIHYS5fPvIv2HjCD3M",
  authDomain: "drl-wager.firebaseapp.com",
  projectId: "drl-wager",
  storageBucket: "drl-wager.appspot.com",
  messagingSenderId: "913634387750",
  appId: "1:913634387750:web:bc6a0386c79088171e03e7"
};

// Initialize Firebase only if there isn't an instance already
const appDRL = initializeApp(firebaseConfig2, "DRL-Wager");
export const db1 = getFirestore(appDRL);