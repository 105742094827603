import './Upcoming.css'

export default function Upcoming(){
  return(
    <div className="container mt-5">
      <h1 className="text-white text-center mb-5">
        Driverz Community Schedule
      </h1>
      <iframe className="mb-5" src="https://calendar.google.com/calendar/embed?src=1etkqcmdtrovofu05eeic60efs%40group.calendar.google.com&ctz=America%2FNew_York" 
        style={{border: 0}} width="100%" height="600" frameBorder="0" scrolling="no">
      </iframe>
    </div>
  )
}