import { useEffect, useState } from 'react';
import * as fcl from "@onflow/fcl";
import { Button } from "react-bootstrap"
import { getAllNFTs } from '../../Cadence/marketplace/getAllNFTs';
import './Fantasy.css'
import bgd from '../../assets/Images/Fantasy_Formula_1.jpg'
import tile_1 from '../../assets/Images/fantasy_column_tile.jpg'
import tile_2 from '../../assets/Images/fantasy_tile_2.jpg'
import tile_3 from '../../assets/Images/fantasy_tile_3.jpg'

export default function Fantasy() {
  const [user, setUser] = useState({ loggedIn: null });
  const [type, setType] = useState(null);
  const [ownCarClub, setOwnCarClub] = useState(false);

  useEffect(() => { 
    fcl.currentUser.subscribe(setUser);
  }, []);

  const codeClick = () => {
    if (user.loggedIn === null) {
      fcl.logIn().then((data) => {
        getCode(data);        
      });
    }else{
      getCode(user);
    }
  }

  const getCode = async(data) => {

    const res = await fcl.query({
      cadence: getAllNFTs,
      args: (arg, t) => [arg(data?.addr, t.Address)],
    });

    const genesis = res.filter(({nftType}) => nftType === "Genesis");
    const carClub = res.filter(({nftType}) => nftType === "Car Club");

    if (genesis.length >= 25){
      setType("champion");
    }else if (genesis.length >= 13 && genesis.length < 25){
      setType("elite");
    }else if (genesis.length >= 6 && genesis.length < 13){
      setType("pitcrew");
    }else if (genesis.length >= 1 && genesis.length < 6){
      setType("owner");
    }

    if(carClub.length >= 1){
      setOwnCarClub(true);
    }

  }

  return(
    <div className="fantasy-page">
      <div className='row-one'>
        <img src={bgd} alt='' />
        <h2>
        Fuel your passion, ignite your strategy - Join Driverz Fantasy Formula 1 leagues!.
        </h2>
      </div>
      <div className='text-left-row'>
        <div className='column-text'>
          <h4>Rev up your racing experience with Driverz Fantasy Formula 1 Leagues. Amplify your strategy, earn free collectibles, and enjoy the thrill of passive income. It's simple:</h4>
          <ul>
            <li>Visit <a href="https://www.formula1.com/" className="custom-link" target="_blank" rel="noopener noreferrer">https://www.formula1.com</a> or install the F1 App from the Apple or Google Play Store to create your Formula 1 account and assemble your fantasy team.</li>
            <li>Once your team is ready, join the leagues suitable for you as a holder. You only need to do this once per Formula 1 season for each league.</li>
            <li>Click 'Fantasy Codes' to get a comprehensive list of your eligible leagues along with their join codes.</li>
          </ul>
          <h4>Welcome to the Driverz community. Your road to the digital collectibles race begins here!</h4>
        </div>
        <div className='column-image'>
          <img src={tile_1} alt='' />
        </div>
      </div>

      <div className='img-left-row'>
        <div className='column-image'>
          <img src={tile_2} alt='' />
        </div>
        <div className='column-text'>
          <h4>Step-by-Step Guide: Joining a Fantasy F1 League with Driverz</h4>
          <ol>
            <li>Visit <a href="https://www.formula1.com/" className="custom-link" target="_blank" rel="noopener noreferrer">formula1.com</a>.</li>
            <li>Navigate to 'Gaming' on the main navigation bar.</li>
            <li>Select 'Fantasy' from the gaming options.</li>
            <li>Register to create an account.</li>
            <li>Assemble your fantasy team.</li>
            <li>Navigate to 'Create/Join' in the left menu within the F1 Fantasy section.</li>
            <li>Select 'Join with Code'.</li>
            <li>Input the league codes which can be found by clicking the 'Fantasy Codes' button below.</li>
          </ol>
          <Button variant="primary" onClick={codeClick} className='spec-button'>
            Fantasy Codes
          </Button>
          <br/><br/>
          {type === "champion" && 
            <p className='text-white'>
              {/*Owner (1-5 Genesis Driverz) - JOIN CODE: <b>C4CLELNEV06</b>
              <br/>
              Constructors Cup (2+ Driverz) - JOIN CODE: <b>COMINGSOON</b>
              <br/>
              Pitcrew (6+ Driverz) - JOIN CODE: <b>C265F7DMH01</b>
              <br/>
              Elite (13+ Driverz) - JOIN CODE: <b>C34FZNJIO02</b>
              <br/> */}
              Owner Code: <b>C6PVJYLNR06</b><br/>
              Pitcrew Code: <b>C5XJGL4XG08</b><br/>
              Elite Code: <b>C3TUFSNIR07</b><br/>
              Champion Code: <b> C2O0JCSTH05</b> {/*} (25+ Driverz) */}
              <br/>

              {ownCarClub && 
              <>
                Genesis & Car Club Owner Code: <b>C29JMMEWY10</b> {/*(1+ Genesis & 1+ Car Club) - JOIN*/}
                <br/>
              </>         
              }
            </p>
          }

          {type === "elite" && 
            <p className='text-white'>
              {/*Owner (1-5 Genesis Driverz) - JOIN CODE: <b>C4CLELNEV06</b>
              <br/>
              Constructors Cup (2+ Driverz) - JOIN CODE: <b>COMINGSOON</b>
              <br/>
              Pitcrew (6+ Driverz) - JOIN CODE: <b>C265F7DMH01</b>
              <br/> */}
              Owner Code: <b>C4CLELNEV06</b><br/>
              Pitcrew Code: <b>C265F7DMH01</b><br/>
              Elite Code: <b>C34FZNJIO02</b> {/*} (13+ Driverz) - JOIN */}
              <br/>

              {ownCarClub && 
              <>
                Genesis & Car Club Owner Code: <b>C6HBZHD9G04</b> {/*(1+ Genesis & 1+ Car Club) - JOIN*/}
                <br/>
              </>           
              }
            </p>
          }

          {type === "pitcrew" && 
            <p className='text-white'>
              {/*Owner (1-5 Genesis Driverz) - JOIN CODE: <b>C4CLELNEV06</b>
              <br/>
              Constructors Cup (2+ Driverz) - JOIN CODE: <b>COMINGSOON</b>
              <br/>*/}
              Owner Code: <b>C4CLELNEV06</b><br/>
              Pitcrew Code: <b>C265F7DMH01</b> {/*(6+ Driverz) - JOIN*/} 
              <br/>

              {ownCarClub && 
              <>
                Genesis & Car Club Owner Code: <b>C6HBZHD9G04</b> {/*(1+ Genesis & 1+ Car Club) - JOIN*/}
                <br/>
              </>         
              }
            </p>
          }

          {/*{type === "constructor" && 
            <p className='text-white'>
              Owner Code: <b>C4CLELNEV06</b>{/*Owner (1-5 Genesis Driverz) - JOIN CODE: <b>C4CLELNEV06</b>
              <br/> 
              Constructor's Cup Code: <b>COMINGSOON</b> (2+ Driverz) - JOIN 
              <br/>

              {ownCarClub && 
              <>
                Genesis & Car Club Owner Code: <b>C6HBZHD9G04</b> (1+ Genesis & 1+ Car Club) - JOIN
                <br/>
              </>       
              }
            </p>
          }*/}

          {type === "owner" && 
            <p className='text-white'>
              Owner Code: <b>C4CLELNEV06</b> {/*} (1-5 Genesis Driverz) - JOIN */}
              <br/>

              {ownCarClub && 
              <>
                Genesis & Car Club Owner Code: <b>C6HBZHD9G04</b> {/*(1+ Genesis & 1+ Car Club) - JOIN*/}
                <br/>
              </>     
              }
            </p>
          }

        </div>
      </div>

      <div className='text-left-row'>
        <div className='column-text'>
          <h4>Your opportunities to win cash and prizes in each Formula 1 Grand Prix directly correspond with the number of Genesis Collectibles in your wallet. Owning more collectibles enhances your chances of winning! Explore our Leagues:</h4>
          <ul>
            <li><strong>Owners League:</strong> 1 - 5 Genesis Collectibles</li>
            <li><strong>Pitcrew League:</strong> 6 - 12 Genesis Collectibles</li>
            <li><strong>Elite League:</strong> 13 - 24 Genesis Collectibles</li>
            <li><strong>Champions League:</strong> 25+ Genesis Collectibles</li>
          </ul>
          <p>For our esteemed supporters holding both a Genesis Driverz and Car Club collectible, we present an exclusive fantasy league. Winners will enjoy a local racing or automotive experience of their choosing!</p>
          <p>Join all leagues you qualify for to optimize your chances of winning big. Every race counts!</p>
        </div>
        <div className='column-image'>
          <img src={tile_3} alt='' />
        </div>
      </div>

    </div>    
  )
}