import React, { useState, useEffect } from 'react';
import driversData from './output.json'; // Import the basic driver data
import driverDetailsData from './meta_output.json'; // Import the detailed driver data
import * as fcl from "@onflow/fcl";
import { checkVroomBalance } from '../../Cadence/Scripts/checkVroomBalance';
import './wager.css';
import { transferVroom } from "../../Cadence/Transactions/transferVroom"
import { db1 } from '../../firebaseDRL';
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import linesOutput from './lines_output_2.json';





const DriverRow = ({ driver, currentField, findDriverDetails, onSelectionChange, selectedDrivers }) => {
    const driverDetails = findDriverDetails(driver[currentField]);
    const initialSelection = selectedDrivers.find(d => d.uniqueId === (driverDetails && driverDetails.Name));
  
    const [isChecked, setIsChecked] = useState(!!initialSelection);
    const [amount, setAmount] = useState(initialSelection ? initialSelection.amount : '');

  
    const calculateOddsAndBettingLine = (driverDetails, enteredAmount) => {
      if (!driverDetails) return { bettingLine: "N/A", payout: 0 };
  
      // Extract driver stats
      const stamina = driverDetails.Stamina.toString();
      const mental = driverDetails.Mental.toString();
      const teamwork = driverDetails.Teamwork.toString();
      const luck = driverDetails.Luck.toString();
  
      // Find the matching entry in the lines_output data
      const matchingEntry = linesOutput.find(entry => 
          entry.STM === stamina && 
          entry.MTL === mental && 
          entry.TW === teamwork && 
          entry.LCK === luck);
  
      if (matchingEntry) {
          // If a matching entry is found, use the payout value from the JSON data
          const multiplier = matchingEntry["-> Curved by Rank"];

          const payout = (enteredAmount * multiplier);
          return { 
              bettingLine: multiplier, // Use the rounded payout as the betting line
              payout: payout // This now represents how much the user wins based on the entered amount
          };
      } else {
          // If no matching entry is found, return default values
          return { bettingLine: "N/A", payout: 0 };
      }
  };
  

    const { bettingLine, payout } = calculateOddsAndBettingLine(driverDetails, amount);

    useEffect(() => {
        if (driverDetails) {
            onSelectionChange({
                uniqueId: driverDetails.Name,
                isChecked,
                amount,
                bettingLine, // Include bettingLine in the state
                payout // Include payout in the state
            });
        }
    }, [isChecked, amount, driverDetails]);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleAmountChange = (e) => {
        const value = e.target.value.replace(/[^0-9.]/g, ''); // Allow decimal numbers
        setAmount(value);
    };

    return (
        <tr>
            <td>{driver["User Name"]}</td>
            <td>{driverDetails ? driverDetails.Name : "Unknown"}</td>
            <td>{driverDetails ? driverDetails.Stamina : "N/A"}</td>
            <td>{driverDetails ? driverDetails.Mental : "N/A"}</td>
            <td>{driverDetails ? driverDetails.Teamwork : "N/A"}</td>
            <td>{driverDetails ? driverDetails.Luck : "N/A"}</td>
            <td>{driverDetails ? driverDetails['Special Skill'] : "N/A"}</td>
            <td>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
            </td>
            <td>
                {isChecked && (
                    <input
                        type="number"
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder="Enter amount"
                    />
                )}
            </td>
            <td>{bettingLine}</td>
            <td>{isChecked ? payout.toFixed(2) : ""}</td> {/* Display payout here */}
        </tr>
    );
};

// 9122, 8169, 6091

const Wager = () => {
    const [currentFieldLugnut, setCurrentFieldLugnut] = useState('#2');
    const [currentFieldRotor, setCurrentFieldRotor] = useState('#2');
    const [currentFieldBucketSeat, setCurrentFieldBucketSeat] = useState('#2');
    const [user, setUser] = useState({ loggedIn: false });
    const [selectedDrivers, setSelectedDrivers] = useState([]);
    const [bal, setBal] = useState(null);
    const [userAddress, setUserAddress] = useState(null);
    const [availableBal, setAvailableBal] = useState(null);
    const [userWagers, setUserWagers] = useState([]);
    const [resetCounter, setResetCounter] = useState(0); // Add this state
    const [isProcessing, setIsProcessing] = useState(false);
    const [dots, setDots] =useState("")
    const [isWageringEnded, setIsWageringEnded] = useState(false);

    useEffect(() => {
      // Determine if any isProcessing state is true
      const anyProcessing = [isProcessing].some(state => state);
    
      let intervalId;
      if (anyProcessing) {
        intervalId = setInterval(() => {
          // Update dots state to cycle through '', '.', '..', '...'
          setDots(dots => {
            switch (dots) {
              case '':
                return '.';
              case '.':
                return '..';
              case '..':
                return '...';
              default:
                return '';
            }
          });
        }, 500); // Adjust time as needed
      }
    
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }, [isProcessing]); // Dependency array includes all isProcessingX variables
    
    




    // Here, allGroupedDrivers contains drivers grouped by division and set
    // You can now proceed to calculate odds for each set in each division

    const fetchUserWagers = async () => {
      if (!user.loggedIn || !user.addr) return;
  
      const q = query(collection(db1, "04112024"), where("user", "==", user.addr));
      const querySnapshot = await getDocs(q);
      const wagers = [];
      querySnapshot.forEach((doc) => {
        wagers.push(doc.data());
      });
      setUserWagers(wagers);
    };

    useEffect(() => {

      
        fetchUserWagers();
      }, [user.loggedIn, user.addr]); // Depend on user's login state and address
      
   

    const getVroomBalance = async () => {
        // Ensure the user is logged in and the address is available
        if (!user.loggedIn || !user.addr) return;
        
        try {
            const bal = await fcl.query({
                cadence: checkVroomBalance,
                args: (arg, t) => [arg(user.addr, t.Address)], // Ensure user.addr is a string formatted correctly
            });
            setBal(bal);
        } catch (error) {
            console.error("Failed to fetch balance:", error);
        }
    };


    const handleTransfer = async (amountWithFee) => {
      try {
        setIsProcessing(true);
          // Transaction logic here
          const formattedAmountWithFee = Number(amountWithFee).toFixed(2);
  
          console.log("Amount with fee: ", formattedAmountWithFee);
          console.log("Current User: ", fcl.currentUser);
          const TXid = await fcl.mutate({
              cadence: transferVroom,
              args: (arg, t) => [
                  arg(formattedAmountWithFee, t.UFix64),
                  arg("0xf887ece39166906e", t.Address)
              ],
              proposer: fcl.currentUser,
              payer: fcl.currentUser,
              authorizations: [fcl.currentUser],
              limit: 999,
          });
  
          // Wait for the transaction to be sealed
          await fcl.tx(TXid).onceSealed(); //include boolean here, true if sealed false if it doesnt seal
          console.log("Transaction sealed.");
  
          // Transaction is sealed, proceed with database operations
          const userAddress = user.addr; // Assuming 'user.addr' holds the Flow blockchain address
          const currentTime = new Date().toISOString(); // Current time in ISO format
  
          // Loop through each selected driver and store their wager in Firestore

          //include conditional statement here  and only run the for loop if that condition from the sealed transaction is true
          for (const { uniqueId, amount, bettingLine } of selectedDrivers) {
              await addDoc(collection(db1, "04112024"), {
                  user: userAddress,
                  selection: uniqueId, // The driver's unique identifier
                  line: bettingLine,
                  amount: amount,
                  time: currentTime
              });
          }
  
          console.log("Wagers stored successfully.");
  
          // Reset selectedDrivers and any other relevant state to prevent double submissions
          setSelectedDrivers([]);
          setIsProcessing(false);
          fetchUserWagers();
          setResetCounter((prev) => prev + 1); // Increment to trigger re-render
          // Optionally navigate to another page or show a success message
  
          return TXid;
      } catch (error) {
          console.error("Error in handleTransfer:", error);
      }
  };
  

      useEffect(() => {
        getVroomBalance();
      }, [user]);
      useEffect(() => {
        fcl.currentUser.subscribe(setUser);
        setUserAddress(user.addr);
        console.log(userAddress);
      }, []);

      useEffect(() => {
        // Recalculate available balance when user balance or selected drivers change
        const totalEnteredAmount = selectedDrivers.reduce((acc, curr) => acc + Number(curr.amount), 0);
        setAvailableBal(bal - totalEnteredAmount);
    }, [selectedDrivers, bal]);



  
    const findDriverDetails = (driverNumber) => {
      const driverNumStr = String(driverNumber);
      return driverDetailsData.find(detail => detail.Name.split('#')[1] === driverNumStr);
    };
  
    const handleSelectionChange = ({ uniqueId, isChecked, amount, bettingLine }) => {
        setSelectedDrivers(prev => {
          // Construct the object for the current selection
          const currentSelection = { uniqueId, amount, bettingLine }; // Include bettingLine here
    
          if (!isChecked) {
            // If the checkbox is being deselected, remove this driver from the selectedDrivers
            return prev.filter(d => d.uniqueId !== uniqueId);
          } else {
            // If the checkbox is being selected or the amount/bettingLine changed, update the driver's info
            const index = prev.findIndex(d => d.uniqueId === uniqueId);
            if (index !== -1) {
              // If the driver is already selected, update their info
              return prev.map((d, i) => i === index ? currentSelection : d);
            } else {
              // If the driver is not already selected, add them to the array
              return [...prev, currentSelection];
            }
          }
        });
    };


      const renderTable = (drivers, currentField) => (
        <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th>Owner</th>
                <th>Driver Name</th>
                <th>Stamina</th>
                <th>Mental</th>
                <th>Teamwork</th>
                <th>Luck</th>
                <th>Skill</th>

                <th>Pick</th>
                <th>Amount</th>
                <th>Boost</th>
                <th>Payout</th>
              </tr>
            </thead>
            <tbody>
              {drivers.map((driver, index) => {
                const details = findDriverDetails(driver[currentField]);
                return (
                  <DriverRow
                    key={`${details ? details.Name : index}-${resetCounter}`} // Use Name as key
                    driver={driver}
                    currentField={currentField}
                    findDriverDetails={findDriverDetails}
                    onSelectionChange={handleSelectionChange}
                    selectedDrivers={selectedDrivers} // Pass selectedDrivers here
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      );
      
      const renderCheckoutTable = () => {
        // Calculate subtotal
        const subTotal = selectedDrivers.reduce((total, { amount }) => total + Number(amount), 0);
        // Calculate booking fee (1% of subtotal)
        const bookingFee = subTotal * 0.01;
        // Calculate total including booking fee
        const totalWithFee = subTotal + bookingFee;
        // Check if available balance is sufficient
        const isBalanceSufficient = availableBal >= totalWithFee;
    
        return (
          <div className="checkout-container">
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Driver Name</th>
                  <th>Boost</th>
                  <th>Amount $VROOM</th>
                </tr>
              </thead>
              <tbody>
                {selectedDrivers.map(({ uniqueId, amount, bettingLine }) => {
                  const details = findDriverDetails(uniqueId);
                  return (
                    <tr key={uniqueId}>
                      <td>{uniqueId}</td>
                      <td>{bettingLine}</td>
                      <td style={{ textAlign: 'right' }}>{amount}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan="2" style={{ textAlign: 'right' }} ><strong>Sub Total</strong></td>
                  <td style={{ textAlign: 'right' }}>{subTotal.toFixed(2)} </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{ textAlign: 'right' }} ><strong>Booking Fee (1%)</strong></td>
                  <td style={{ textAlign: 'right' }}>{bookingFee.toFixed(2)} </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{ textAlign: 'right' }}><strong>Total</strong></td>
                  <td style={{ textAlign: 'right' }}>{totalWithFee.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
            {isWageringEnded ? (
              <div className='process'>
                <p>Wagering Ended</p>
              </div>
            ) : isProcessing ? (
              <div className='process'>
                <p>Processing Wager{dots}</p>
              </div>
            ) : (
              <button 
                className="checkout-button" 
                onClick={() => handleTransfer(totalWithFee)}
                disabled={!isBalanceSufficient || selectedDrivers.length === 0}
              >
                {isBalanceSufficient ? 'Checkout' : 'Add Funds'} 
              </button>
            )}
          </div>
        );
      };
    


  // Function to handle navigation for each division
  const handleChangeField = (division, direction) => {
    const setState = division === 'Lugnut' ? setCurrentFieldLugnut : division === 'Rotor' ? setCurrentFieldRotor : setCurrentFieldBucketSeat;
    setState(prevField => {
      const fieldNumber = parseInt(prevField[1], 10);
      const newFieldNumber = direction === 'left' ? (fieldNumber - 1 || 4) : (fieldNumber % 4) + 1;
      return `#${newFieldNumber}`;
    });
  };

  const targetTime = new Date('April 18, 2024 15:00:00 GMT-1000').getTime();


  // State to store the countdown timer
  const [countdownTimer, setCountdownTimer] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });

  useEffect(() => {
    // Update the countdown every second
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetTime - now;

      // Calculate time left
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Prefix with zero if less than 10
      days = days < 10 ? '0' + days : days;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // Update state
      setCountdownTimer({ days, hours, minutes, seconds });

      // If the countdown is over, clear the interval
      if (distance < 0) {
        clearInterval(interval);
        setIsWageringEnded(true);
        setCountdownTimer({ days: '00', hours: '00', minutes: '00', seconds: '00' });
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);


  return (
<div>
    <div className='wager-main'>
        <h1>DRL WAGERING</h1>
        <h2>WEEK 6</h2>
    </div>

    <div className="section-divider-2"></div>
    {isWageringEnded ? (
        <div className="wager-2">
          <h2>WAGERING PERIOD ENDED</h2>
        </div>
      ) : (
        <>
          <div className="wager-2">
            <h2>WAGERING CLOSES IN</h2>
          </div>
          <div className="time-container" id="countdown">
            <div className="time-section">
              <span>{countdownTimer.days}</span>
              <div className="time-label">days</div>
            </div>
            <div className="time-section">
              <span>{countdownTimer.hours}</span>
              <div className="time-label">hours</div>
            </div>
            <div className="time-section">
              <span>{countdownTimer.minutes}</span>
              <div className="time-label">minutes</div>
            </div>
            <div className="time-section">
              <span>{countdownTimer.seconds}</span>
              <div className="time-label">seconds</div>
            </div>
          </div>
          <div className='wager-3'>
              <h4>Place your bets for the April 18th, 2024 DRL Race. All wagers must be submitted by 3 PM EST on April 18th, 2024.
                  <br></br>
                  No bets will be accepted after this deadline. See Terms and Conditions for more details.</h4>
          </div>
        </>
      )}
  <div className="division-container">
    <div className="division-header">
      {/*<button onClick={() => handleChangeField('Lugnut', 'left')}>{'<'}</button>*/}
      <span className="division-name">Lugnut Division</span>
      {/*<button onClick={() => handleChangeField('Lugnut', 'right')}>{'>'}</button>*/}
    </div>
    {renderTable(driversData.slice(0, 10), currentFieldLugnut)}
  </div>

  <div className="division-container">
    <div className="division-header">
      {/*<button onClick={() => handleChangeField('Rotor', 'left')}>{'<'}</button>*/}
      <span className="division-name">Rotor Division</span>
      {/*<button onClick={() => handleChangeField('Rotor', 'right')}>{'>'}</button>*/}
    </div>
    {renderTable(driversData.slice(10, 20), currentFieldRotor)}
  </div>

  <div className="division-container">
    <div className="division-header">
      {/*<button onClick={() => handleChangeField('Bucket Seat', 'left')}>{'<'}</button>*/}
      <span className="division-name">Bucket Seat Division</span>
      {/*<button onClick={() => handleChangeField('Bucket Seat', 'right')}>{'>'}</button>*/}
    </div>
    {renderTable(driversData.slice(20, 30), currentFieldBucketSeat)}
  </div>

  {!user.loggedIn ? (
    <div className='wager-2'>
    <p>Sign in with your wallet to participate.</p>
    </div>
    ) : (
    <>
        <div className="section-divider"></div>
        <div className="wager-2">
            <h2>Checkout</h2>
        </div>
        <div className="balance-display">
        <h3>Available Balance: {availableBal} $VROOM</h3>
        </div>
        {renderCheckoutTable()}
        <div className="section-divider-2"></div>
        <div className="wager-2">
            <h2>Wager Slip</h2>
        </div>
        <div className="table-container">
            <table className="custom-table">
            <thead>
                <tr>
                <th>Selection</th>
                <th>Boost</th>
                <th>Amount $VROOM</th>
                <th>Time</th>
                </tr>
            </thead>
            <tbody>
                {userWagers.map((wager, index) => (
                <tr key={index}>
                    <td>{wager.selection}</td>
                    <td>{wager.line}</td>
                    <td>{wager.amount}</td>
                    <td>{wager.time}</td>
                </tr>
                ))}
            </tbody>
            </table>
        </div>
    </>
    )}

    <div className="section-divider"></div>
    <div className='wager-4'><h3>Terms and Conditions</h3></div>
    <div className='wager-3'>
    

<p>Welcome to our Digital Racing League (DRL) Wagering Platform. By accessing or using our platform, you agree to be bound by these terms and conditions (Terms). 
Please read them carefully. <strong>1. Agreement to Terms:</strong> By using our services, you confirm that you are at least 18 years of age or the legal age for 
participating in wagering activities in your jurisdiction, whichever is higher. You agree to provide accurate information for all interactions on the platform. 
<strong>2. Accessing Our Services:</strong> To participate in wagering, you must sign into our platform using your Blocto or Dapper wallet. By using these services 
to access our platform, you accept that you are responsible for any transactions made and for ensuring the security of your wallet. It's important to safeguard 
your wallet information and monitor transactions to prevent unauthorized use. <strong>3. Wagering:</strong> All wagers are final once submitted. Lines (odds) 
are subject to change; however, wagers will be settled at the lines fixed at the time of the wager placement. The payout displayed in the far right column of 
the wagering tables represents the potential earnings a participant would receive if their selected driver finishes first in the DRL simulated race. Please note, 
selecting a driver does not guarantee a payout. Payouts are exclusively issued for selections that finish first in the race. The platform reserves the right to 
refuse any wager for any reason. <strong>4. Deposits and Payouts:</strong> All deposits and payouts for DRL Wagering activities are exclusively conducted in $VROOM 
currency. No other forms of currency will be accepted. Deposits are facilitated upon checkout using legal and verifiable sources of $VROOM. Payouts, which are effectively 
the winnings from your wagers, will be issued directly to your main Vroom holding account, typically within 48 hours following the first broadcast of the DRL simulated 
race. We reserve the right to perform identity verification procedures prior to approving any payout to ensure the security and legality of all transactions. <strong>5. 
Responsible Gaming:</strong> We encourage responsible gaming and provide tools and resources to help manage your gaming activities. It is your responsibility to ensure 
that your gaming is controlled. <strong>6. Intellectual Property:</strong> All content on this platform, including text, graphics, logos, and software, is the property 
of the platform or its content suppliers and protected by copyright and other intellectual property laws. <strong>7. Limitation of Liability:</strong> The platform 
provides services on an "as is" and "as available" basis. We do not guarantee that the platform will always be secure, error-free, or timely. In no event shall 
the platform, its affiliates, or their licensors be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the 
platform. <strong>8. Dispute Resolution:</strong> All disputes related to payouts or any aspect of these Terms or the platform will be exclusively resolved by the 
team at Driverz Inc. By participating in DRL Wagering, participants agree that the decision of Driverz Inc. is final and binding. Participants also agree to not 
initiate any legal action against Driverz Inc. regarding any disputes. This agreement to resolve disputes internally without legal recourse is binding on all parties 
involved in DRL Wagering. <strong>9. Integrity and Eligibility:</strong> Members of the Driverz Inc. team are ineligible to receive payouts from DRL wagering. It is 
the responsibility of the Driverz Inc. team to maintain the integrity of DRL simulated races. The team is required to prevent any doctoring of the simulated races 
and to report any such activities to wagering participants for the affected simulated race. This ensures fairness and transparency for all participants engaged in 
DRL Wagering. <strong>10. Changes to Terms:</strong> We reserve the right to modify these Terms at any time. All changes are effective immediately upon posting and 
apply to all access and use of the platform thereafter.</p>

    </div>
</div>



  );
};

export default Wager;


