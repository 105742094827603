export const getListingInsurance = `
import MetadataViews from 0x1d7e57aa55817448
import DriverzInsuranceStorefront from 0xf887ece39166906e
import DriverzInsurance from 0xf887ece39166906e

// IMPORTANT: Parameter list below should match the parameter list passed to the associated purchase txn
// Please also make sure that the argument order list should be same as that of the associated purchase txn
pub fun main(address: Address): [UInt64] {

    let account = getAccount(address)
    let marketCollectionRef = account.getCapability<&DriverzInsuranceStorefront.Storefront{DriverzInsuranceStorefront.StorefrontPublic}>(
      DriverzInsuranceStorefront.StorefrontPublicPath
        ).borrow()
        ?? panic("Could not borrow market collection from address")

        return marketCollectionRef.getListingIDs()
}
`