import React, { useState, useEffect } from "react";
import { FaLongArrowAltRight } from 'react-icons/fa';
import './turbo.css';
import * as fcl from "@onflow/fcl";
import standard from "../../assets/Images/standard.png";
import pro from "../../assets/Images/pro.png";
import elite from "../../assets/Images/elite.png";
import tile1 from "../../assets/Images/turboSim/TURBO-Final.png";
import tile2 from "../../assets/Images/turboSim/TURBO-Speed2.png";
import tile3 from "../../assets/Images/turboSim/TURBO-Mental.png";
import tile4 from "../../assets/Images/turboSim/TURBO-Teamwork.png";
import tile5 from "../../assets/Images/turboSim/TURBO-Luck.png";
import tile6 from "../../assets/Images/turboSim/TURBO-Rest.png";
import { getAllNFTs } from '../../Cadence/marketplace/getAllNFTs';
import { transferVroom } from "../../Cadence/Transactions/transferVroom"
import { db } from '../../firebaseTurbo';
import { collection, getDocs, orderBy, updateDoc, arrayUnion, where, limit, query, deleteDoc, addDoc, getDoc} from "firebase/firestore";

export default function Turbo() {
    const [user, setUser] = useState({ loggedIn: null });
    const [userAddress, setUserAddress] = useState(null);
    const [nft, setNFT] = useState([]);
    const [genesisNames, setGenesisNames] = useState([]);
    const [selectedName, setSelectedName] = useState("");
    const [selectedProName, setSelectedProName] = useState("");
    const [selectedEliteName, setSelectedEliteName] = useState("");
    const [canJoinStandard, setCanJoinStandard] = useState(true);
    const [canJoinPro, setCanJoinPro] = useState(true);
    const [canJoinElite, setCanJoinElite] = useState(true);
    const [gameData, setGameData] = useState([]);
    const [levelLimitsReached, setLevelLimitsReached] = useState({ standard: false, pro: false, elite: false });
    const [filteredStandardOptions, setFilteredStandardOptions] = useState([]);
    const [filteredProOptions, setFilteredProOptions] = useState([]);
    const [filteredEliteOptions, setFilteredEliteOptions] = useState([]);
    const [dailyRaceCount, setDailyRaceCount] = useState(0);





    useEffect(() => {
        fcl.currentUser.subscribe(setUser);
    }, []);

    useEffect(() => {
        if (user && user.loggedIn) {
            setUserAddress(user.addr);
            console.log(userAddress);
        } else {
            setUserAddress(null);
        }
    }, [user]); 

    const getNFTs = async () => {
        if (user.loggedIn === null) return;
        const res = await fcl.query({
          cadence: getAllNFTs,
          args: (arg, t) => [arg(user?.addr, t.Address)],
        });
    
        console.log('NFT - ', res);
        setNFT(res);

        // Filter and extract the names of Genesis NFTs
        const genesisNFTs = res.filter(item => item.nftType === "Genesis");
        const genesisNames = genesisNFTs.map(item => item.name);
        setGenesisNames(genesisNames); // Store the names in the state
        console.log(genesisNames)
    };

    useEffect(() => {
        getNFTs();
    }, [user])
    
    // Event handler for selecting a name from the dropdown
    const handleNameSelection = (event) => {
        setSelectedName(event.target.value); // Update the selected name state
        console.log("Selected Name:", event.target.value);
    };

    const handleProNameSelection = (event) => {
        setSelectedProName(event.target.value);
        console.log("Selected Pro Name: ", event.target.value);
    };

    const handleEliteNameSelection = (event) => {
        setSelectedEliteName(event.target.value);
        console.log("Selected Elite Name: ", event.target.value);
    }

    const addPlayerToGameSession = async (playerId, gameLevel) => {
        const gameSessionsQuery = query(
            collection(db, 'Games'),
            where('gameLevel', '==', gameLevel),
            where('processed', '==', false), // Assuming 'processed' is a relevant condition
            orderBy('createdAt', 'desc'),
            limit(1)
        );
        const gameSessions = await getDocs(gameSessionsQuery);

        let gameSession = gameSessions.docs[0];

        if (gameSession && gameSession.data().players.length < 16) {
            // Add player to existing session
            await updateDoc(gameSession.ref, {
                players: arrayUnion({
                    driverId: playerId,
                    flowAddress: userAddress, // Assuming this is the player's address
                    addedAt: new Date() // Using JavaScript Date for timestamp
                })
            });
        } else {
            // Create a new game session
            await addDoc(collection(db, 'Games'), {
                gameLevel: gameLevel,
                processed: false,
                players: [{
                    driverId: playerId,
                    flowAddress: userAddress,
                    addedAt: new Date()
                }],
                createdAt: new Date()
            });
        }
    };

    const fetchGameData = async () => {
        const gameSessionsQuery = query(
            collection(db, 'Games'),
            where('processed', '==', false)
        );

        const querySnapshot = await getDocs(gameSessionsQuery);
        const data = [];

        querySnapshot.forEach((doc) => {
            const docData = doc.data();
            const players = docData.players || [];
            players.forEach((player) => {
                if (player.flowAddress === userAddress) { // Filter by user address
                    data.push({
                        name: player.driverId,
                        time: player.addedAt.toDate(),
                        level: docData.gameLevel
                    });
                }
            });
        });

        console.log("Fetched Game Data:", data);
        setGameData(data); // Set the state with the fetched data
    };

    // useEffect to call the fetchGameData function when the component mounts
    useEffect(() => {
        fetchGameData();
    }, [userAddress]);
    
    // New useEffect to check limits after gameData is updated
    useEffect(() => {
        if (gameData.length > 0) {
            console.log("Processing Game Data:", gameData); // Log the current game data
            const limitsReached = checkPlayerLimitsPerLevel(gameData);
            console.log("Limits Reached:", limitsReached); // Log the limits reached status
            setLevelLimitsReached(limitsReached);
        } else {
            console.log("Game Data is empty or not updated yet");
        }
    }, [gameData]);

    useEffect(() => {
        if (gameData.length > 0) {
            console.log("Current Game Data:", gameData);
            const newDailyCount = calculateDailyRaceCount(gameData);
            setDailyRaceCount(newDailyCount);
        }
    }, [gameData]);
/*
    useEffect(() => {
        if (gameData.length > 0) {
            const playersInStandard = gameData.filter(player => player.level === 'standard').map(player => player.name);
            const playersInPro = gameData.filter(player => player.level === 'pro').map(player => player.name);
            const playersInElite = gameData.filter(player => player.level === 'elite').map(player => player.name);
    
            setFilteredStandardOptions(genesisNames.filter(name => !playersInStandard.includes(name)));
            setFilteredProOptions(genesisNames.filter(name => !playersInPro.includes(name)));
            setFilteredEliteOptions(genesisNames.filter(name => !playersInElite.includes(name)));
        } else {
            setFilteredStandardOptions(genesisNames);
            setFilteredProOptions(genesisNames);
            setFilteredEliteOptions(genesisNames);
        }
    }, [gameData, genesisNames]); */

    useEffect(() => {
        if (gameData.length > 0) {
            const weeklyRaceCounts = calculateWeeklyRaceCounts(gameData);
            const playersInStandard = gameData.filter(player => player.level === 'standard').map(player => player.name);
            const playersInPro = gameData.filter(player => player.level === 'pro').map(player => player.name);
            const playersInElite = gameData.filter(player => player.level === 'elite').map(player => player.name);
    
            setFilteredStandardOptions(genesisNames.filter(name => !playersInStandard.includes(name) && (!weeklyRaceCounts[name] || weeklyRaceCounts[name] < 3)));
            setFilteredProOptions(genesisNames.filter(name => !playersInPro.includes(name) && (!weeklyRaceCounts[name] || weeklyRaceCounts[name] < 3)));
            setFilteredEliteOptions(genesisNames.filter(name => !playersInElite.includes(name) && (!weeklyRaceCounts[name] || weeklyRaceCounts[name] < 3)));
        } else {
            setFilteredStandardOptions(genesisNames);
            setFilteredProOptions(genesisNames);
            setFilteredEliteOptions(genesisNames);
        }
    }, [gameData, genesisNames]);

    const joinGame = async (gameLevel, transferAmount, playerId) => {
        try {
            const TXid = await fcl.mutate({
                cadence: transferVroom,
                args: (arg, t) => [
                    arg(transferAmount, t.UFix64),
                    arg("0x87000acfdce884f3", t.Address)
                ],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });

            // Add player to game session after successful transaction
            await addPlayerToGameSession(playerId, gameLevel);

            fetchGameData();

            return TXid;

            

        } catch (error) {
            console.error("Error joining game:", error);
        }
    };

    const calculateDailyRaceCount = (gameData) => {
        const now = new Date();
        const startOfTodayEastern = new Date(now.toLocaleString("en-US", { timeZone: "America/New_York" }));
        startOfTodayEastern.setHours(0, 0, 0, 0);

        console.log("Start of Today in Eastern Time:", startOfTodayEastern);

        const count = gameData.filter(entry => {
            const entryTimeEastern = new Date(entry.time.toLocaleString("en-US", { timeZone: "America/New_York" }));
            return entryTimeEastern >= startOfTodayEastern;
        }).length;

        console.log("Calculated Daily Race Count:", count);
        return count;
    };

    const calculateWeeklyRaceCounts = (gameData) => {
        const now = new Date();
        const startOfWeekEastern = new Date(now.toLocaleString("en-US", { timeZone: "America/New_York" }));
        // Set to the most recent Monday
        startOfWeekEastern.setDate(startOfWeekEastern.getDate() - (startOfWeekEastern.getDay() + 6) % 7);
        startOfWeekEastern.setHours(0, 0, 0, 0);
    
        console.log("Start of the Week in Eastern Time:", startOfWeekEastern);
    
        const weeklyRaceCounts = gameData.reduce((counts, entry) => {
            const entryTimeEastern = new Date(entry.time.toLocaleString("en-US", { timeZone: "America/New_York" }));
            if (entryTimeEastern >= startOfWeekEastern) {
                counts[entry.name] = (counts[entry.name] || 0) + 1;
            }
            return counts;
        }, {});
    
        console.log("Weekly Race Counts:", weeklyRaceCounts);
        return weeklyRaceCounts;
    };
    
      

    const standardJoin = () => {
        if (!selectedName || selectedName === "") {
            console.log("No player selected for Standard race");
            return;
        }
        joinGame('standard', "100.00", selectedName);
    };
    const proJoin = () => {
        if (!selectedProName || selectedProName === "") {
            console.log("No player selected for Pro race");
            return;
        }
        joinGame('pro', "250.00", selectedProName);
    };
    const eliteJoin = () => {
        if (!selectedEliteName || selectedEliteName === "") {
            console.log("No player selected for Elite race");
            return;
        }
        joinGame('elite', "500.00", selectedEliteName);
    };

    const checkPlayerLimitsPerLevel = (playerData) => {
        const maxPlayersPerLevel = 2;
        const playerCountPerLevel = { standard: 0, pro: 0, elite: 0 };
        const levelLimitReached = { standard: false, pro: false, elite: false };
    
        playerData.forEach(player => {
            playerCountPerLevel[player.level] += 1;
        });
    
        Object.keys(playerCountPerLevel).forEach(level => {
            if (playerCountPerLevel[level] >= maxPlayersPerLevel) {
                levelLimitReached[level] = true;
            }
        });

        console.log("Player Count Per Level:", playerCountPerLevel); 
        return levelLimitReached;
    };

    

    return (
        <div className="turbo-container">
            <div className="turbo-1">
                <h2>Driverz Turbo</h2>
                <h4>Purchase entry into the next TURBO sim!</h4>
            </div>

            <div className="ticket-row">
                <div className="ticket-column">
                    <h5>Standard</h5>
                    <img src={standard}></img>
                    <div>
                        <select onChange={handleNameSelection} className="drop-down" value={selectedName}>
                            <option value="">Select Racer</option>
                            {filteredStandardOptions.map((name, index) => (
                                <option key={index} value={name}>{name}</option>
                            ))}
                        </select>   
                    </div>              
                    <button 
                        onClick={standardJoin} 
                        className="turbo-button"
                        disabled={levelLimitsReached.standard || filteredStandardOptions.length === 0 || dailyRaceCount >= 3}>
                        Join
                    </button>
                    <p>Entry Fee - 100 $VROOM</p>   
                </div>
                <div className="ticket-column">
                    <h5>Pro</h5>
                    <img src={pro}></img>
                {/*    <div>
                        <select onChange={handleProNameSelection} className="drop-down" value={selectedProName}>
                            <option value="">Select Racer</option>
                            {filteredProOptions.map((name, index) => (
                                <option key={index} value={name}>{name}</option>
                            ))}
                        </select>
                    </div> 
                    <button 
                        onClick={proJoin} 
                        className="turbo-button"
                        disabled={levelLimitsReached.pro || filteredProOptions.length === 0 || dailyRaceCount >= 3}>
                        Join
                    </button>*/}
                    <h6>Coming Soon!</h6>
                    <p>Entry Fee - 250 $VROOM</p>
                </div>
                <div className="ticket-column">
                    <h5>Elite</h5>
                    <img src={elite}></img>
                {/*     <div>
                        <select onChange={handleEliteNameSelection} className="drop-down" value={selectedEliteName}>
                            <option value="">Select Racer</option>
                            {filteredEliteOptions.map((name, index) => (
                                <option key={index} value={name}>{name}</option>
                            ))}
                        </select>
                    </div>
                    <button 
                        onClick={eliteJoin} 
                        className="turbo-button"
                        disabled={levelLimitsReached.elite || filteredEliteOptions.length === 0 || dailyRaceCount >= 3}>
                        Join
                    </button> */}
                    <h6>Coming Soon!</h6>
                    <p>Entry Fee - 500 $VROOM</p>
                </div>
            </div>
            <div className="content-row">
                <div className="text-turbo-column">
                    <h5>TURBO Simulation Guide</h5>
                    <p>
                        Driverz TURBO SIM is an exhilarating, custom-built racing simulation that puts your Driverz Genesis collectibles to the test!
                        <br></br><br></br>
                        In a heart-pounding race around the digital track, 16 Driverz compete for glory, with each one's unique stats influencing their driving prowess, 
                        crash odds, and pit stop durations.
                    </p>
                </div>
                <div className="image-turbo-column">
                    <img src={tile1} alt="image showing Driverz after they finish their race"></img>
                </div>
            </div>
            <div className="content-text-row">
                <h5>Entry Tiers and Earnings</h5>
                <p>Claim your spot on the podium to win big. VROOM VROOM!</p>
                <div className="payout-row">
                    <div className="pay-box">
                        <h4>Standard</h4>
                        <p>Total Pool: 1,600 $VROOM</p>
                        <ol className="turbo-ol">
                            <li>🥇 - 960 $VROOM</li>
                            <li>🥈 - 400 $VROOM</li>
                            <li>🥉 - 160 $VROOM</li>
                        </ol>
                    </div>
                    <div className="pay-box">
                        <h4>Pro</h4>
                        <p>Total Pool: 4,000 $VROOM</p>
                        <ol className="turbo-ol">
                            <li>🥇 - 2,400 $VROOM</li>
                            <li>🥈 - 1,000 $VROOM</li>
                            <li>🥉 - 400 $VROOM</li>
                        </ol>
                    </div>
                    <div className="pay-box">
                        <h4>Elite</h4>
                        <p>Total Pool: 8,000 $VROOM</p>
                        <ol className="turbo-ol">
                            <li>🥇 - 4,800 $VROOM</li>
                            <li>🥈 - 2,000 $VROOM</li>
                            <li>🥉 - 800 $VROOM</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="content-row">
                <div className="text-turbo-column">
                    <h5>Race Limits</h5>
                    <p>You and your Driverz have limits! Be sure to strategize which Driverz you use.</p>
                    <ul className="turbo-ul">
                        <li>Enter up to 3 races per day</li>
                            <ul>
                                <li>Resets Daily @ 12:00 AM EST</li>
                            </ul>
                        <li>Each Driver is allowed up to 3 entries per week</li>
                            <ul>
                                <li>Resets Weekly, Monday @ 12:00 AM EST</li>
                            </ul>
                        <li>Up to 2 of your Driverz may be in the same race</li>
                        <li>16 Driverz per race</li>
                    </ul>
                </div>
                <div className="image-turbo-column">
                    <img src={tile6}></img>
                </div>
            </div>
            <div className="content-text-row">
                <h5>How Does Driverz TURBO Work?</h5>
                <p>Driverz TURBO features a detailed simulation where every frame counts. Each driver’s skill set plays a critical role in four key areas.</p>
                <div className="attribute-list">
                    <div className="att-box">
                        <span className="attribute">Stamina</span>
                            <span className="arrow-tab"></span> {/* Tab spacing */}
                                <FaLongArrowAltRight className="arrow-icon" />
                            <span className="arrow-tab"></span> 
                        <span className="relation">Speed</span>
                    </div>
                    <div className="att-box">
                        <span className="attribute">Mental</span>
                            <span className="arrow-tab"></span> {/* Tab spacing */}
                                <FaLongArrowAltRight className="arrow-icon" />
                            <span className="arrow-tab"></span> 
                        <span className="relation">Steering</span>
                    </div>
                    <div className="att-box">
                        <span className="attribute">Teamwork</span>
                            <span className="arrow-tab"></span> {/* Tab spacing */}
                            <FaLongArrowAltRight className="arrow-icon" />
                            <span className="arrow-tab"></span> 
                        <span className="relation">Pit length</span>
                    </div>
                    <div className="att-box">
                        <span className="attribute">Luck</span>
                            <span className="arrow-tab"></span> {/* Tab spacing */}
                                <FaLongArrowAltRight className="arrow-icon" />
                            <span className="arrow-tab"></span> 
                        <span className="relation">Crashes</span>
                    </div>
                </div>
            </div>
            <div className="content-row">
                <div className="image-turbo-column-new">
                    <img src={tile2} alt="speed table for Driverz Turbo"></img>
                </div>
                <div className="text-turbo-column-new">
                    <h5>
                       Stamina → Speed 💨
                    </h5>
                    <p>
                        Every frame of the simulation video has a “roll” to determine how many spaces forward each Driver moves.
                        This is determined by the Stamina skill, particularly during the final lap!<br></br><br></br>
                        <strong style={{ fontSize: '24px' }}>Special Skill Speed Boosts</strong><br></br><br></br>
                        Turning ➡ ️Speed +2 while turning<br></br>
                        Fast ➡ Top Speed is 36 instead of 30

                    </p>
                </div>
            </div>
            <div className="content-row">
                <div className="text-turbo-column">
                    <h5>Mental → Steering ↩️</h5>
                    <p>
                        Every frame of the simulation video has a “roll” to determine if the driver stays straight or moves
                        side to side within the track. The odds of that roll incorporate your Driver’s Mental skill as follows.</p>
                </div>
                <div className="image-turbo-column-new">
                    <img src={tile3} alt="a graphic showing the different probabilities of a driver based ontheir mental stat."></img>
                </div>
            </div>
            <div className="content-row">
                <div className="image-turbo-column-new">
                    <img src={tile4} alt="a graphic describing how the length of the pit stops in the game depends on the driverz teamwork stat"></img>
                </div>
                <div className="text-turbo-column-new">
                    <h5>Teamwork → Pit Stops 🔧</h5>
                    <p>
                        Every driver must make a pit stop during the designated lap(s). The odds of the length of that pit stop incorporate your Driver’s Teamwork skills.
                    </p>
                </div>
            </div>
            <div className="content-row">
                <div className="text-turbo-column">
                    <h5>Luck → CRASHES! 💥</h5>
                    <p>
                        If any driver gets too close to another driver, there is a chance they might crash!
                        The chance of a driver crashing depends on the distance between the two driverz and their Luck.
                    </p>
                </div>
                <div className="image-turbo-column-new">
                    <img src={tile5} alt="a graphic describing the different probabilities based on the driver's luck stat."></img>
                </div>
            </div>
        </div>
    )
}