export const getCCListingDetails = `
import MainStorefrontX1 from 0x66b60643244a7738

access(all) struct ListingSummary {
    access(all) let listingID: UInt64
    access(all) let details: MainStorefrontX1.ListingDetails

    init(listingID: UInt64, details: MainStorefrontX1.ListingDetails) {
        self.listingID = listingID
        self.details = details
    }
}

access(all) fun main(storefrontAddress: Address): [ListingSummary] {
    // Get the public reference to the storefront
    let storefrontRef = getAccount(storefrontAddress)
        .capabilities.borrow<&{MainStorefrontX1.StorefrontPublic}>(MainStorefrontX1.StorefrontPublicPath)
        
        ?? panic("Could not borrow the Storefrontaccess(all)lic reference from the account at provided address.")

    // Get all listing IDs from the storefront
    let listingIDs = storefrontRef.getListingIDs()
    
    var listings: [ListingSummary] = []

    // For each listing ID, get the details
    for listingID in listingIDs {
        let listing = storefrontRef.borrowListing(listingResourceID: listingID)
            ?? panic("Could not borrow the listing resource with ID: ".concat(listingID.toString()))
        
        let listingDetails = listing.getDetails()

        // Store the listing ID and details in a struct
        listings.append(ListingSummary(listingID: listingID, details: listingDetails))
    }

    return listings
}

`