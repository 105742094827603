// Import the functions you need from the SDKs you need
// import firebase from 'firebase/compat/app';
// import "firebase/compat/database";
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig1 = {
  apiKey: "AIzaSyCBkz8q7ZUs7rsSbA_F3lUxvuzx4KyyuYw",
  authDomain: "turbo-sim.firebaseapp.com",
  projectId: "turbo-sim",
  storageBucket: "turbo-sim.appspot.com",
  messagingSenderId: "285691180523",
  appId: "1:285691180523:web:958364d5e6d0f3ee9715ae",
  measurementId: "G-E1RX79NE93"
  };

// Initialize Firebase
const appNew = initializeApp(firebaseConfig1, "Turbo-Sim");
export const db = getFirestore(appNew);
// const analytics = getAnalytics(app);

// firebase.initializeApp(firebaseConfig);
//export default firebase.database();