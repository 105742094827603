export const transferVroom = `
import FungibleToken from 0xf233dcee88fe0abe
import VroomToken from 0xf887ece39166906e

transaction(recipientAddress: Address, totalAmount: UFix64) {

    // Reference to the VroomToken Vault of the sender
    let senderVault: auth(FungibleToken.Withdraw) &VroomToken.Vault

    // Reference to the FungibleToken Receiver of the recipient
    let recipientVaultRef: &{FungibleToken.Receiver}

    // Reference to the FungibleToken Receiver of the fee collector
    let feeCollectorVaultRef: &{FungibleToken.Receiver}

    prepare(signer: auth(Storage, Capabilities) &Account) {
        // Borrow a reference to the signer's VroomToken Vault
        self.senderVault = signer.storage.borrow<auth(FungibleToken.Withdraw) &VroomToken.Vault>(from: /storage/VroomTokenVault)
            ?? panic("Could not borrow reference to the sender's Vault.")

        // Borrow a reference to the recipient's vault
        self.recipientVaultRef = getAccount(recipientAddress)
            .capabilities.borrow<&{FungibleToken.Receiver}>(/public/VroomTokenReceiver)
            ?? panic("Could not borrow receiver reference to the recipient's Vault.")

        // Borrow a reference to the fee collector's vault (your address)
        self.feeCollectorVaultRef = getAccount(0x66b60643244a7738)
            .capabilities.borrow<&{FungibleToken.Receiver}>(/public/VroomTokenReceiver)
            ?? panic("Could not borrow receiver reference to the fee collector's Vault.")
    }

    execute {
        // Calculate the amount to send to the recipient and the fee
        let recipientAmount = totalAmount / 1.01  // Extract 1% fee from totalAmount
        let feeAmount = totalAmount - recipientAmount

        // Withdraw the recipient amount from the sender's vault and deposit it into the recipient's vault
        let recipientVault: @{FungibleToken.Vault} <- self.senderVault.withdraw(amount: recipientAmount)
        self.recipientVaultRef.deposit(from: <- recipientVault)

        // Withdraw the fee amount from the sender's vault and deposit it into the fee collector's vault
        let feeVault: @{FungibleToken.Vault} <- self.senderVault.withdraw(amount: feeAmount)
        self.feeCollectorVaultRef.deposit(from: <- feeVault)
    }
}

`