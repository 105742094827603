import { useEffect, useState } from "react";
import { Col, Card, Button, Form } from "react-bootstrap";
import ReactCardFlip from "react-card-flip";
import * as fcl from "@onflow/fcl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Genesis_Metadata from "../data/genesis_metadata.json";

export default function TurboGenesisItem(props) {
  const {turboGenesisID, setTurboGenesisID, turboGenesisSequence} = props;
  const [isFlipped, setIsFlipped] = useState(false);
  const [genesisMetadata, setGenesisMetadata] = useState(null);
  var backCard = "";
  var overall = 0;

  useEffect(() => {
    if(props.data.nftType === "Genesis") {
      Genesis_Metadata.map((data) => {
        if (data.revealDisplay.name === props.data.name){
          setGenesisMetadata(data.metadata);
        }
      });
    }
  }, []);

  const profileClick = () => {
    if(props.data.nftType == "Genesis") setIsFlipped(!isFlipped);
  };

  const selectClick = () => {
    if(turboGenesisSequence === 1){
      var tempID = [...turboGenesisID];
      tempID[0] = props.data.nftId;
      setTurboGenesisID(tempID);
    }else if(turboGenesisSequence === 2){
      var tempID = [...turboGenesisID];
      tempID[1] = props.data.nftId;
      setTurboGenesisID(tempID);
    }
  }

  const unselectClick = () => {
    if(turboGenesisSequence === 1){
      var tempID = [...turboGenesisID];
      tempID[0] = "";
      setTurboGenesisID(tempID);
    }else if(turboGenesisSequence === 2){
      var tempID = [...turboGenesisID];
      tempID[1] = "";
      setTurboGenesisID(tempID);
    }
  }

  if (genesisMetadata) {
    overall = (parseInt(genesisMetadata.Mental) + parseInt(genesisMetadata.Luck) + 
      parseInt(genesisMetadata["Physical Stamina"]) + parseInt(genesisMetadata.Teamwork))/4;

    backCard = 
      <>
        <div className="row font-13">
          <div className="col-6">
            <p className="text-white">
              LUCK <br /> {genesisMetadata.Luck}
            </p>
          </div>
          <div className="col-6">
            <p className="text-white mb-3">
              PHYSICAL STAMINA <br /> {genesisMetadata["Physical Stamina"]}
            </p>
          </div>
        </div>

        <div className="row font-13">
          <div className="col-6">
            <p className="text-white mb-3">
              MENTAL <br /> {genesisMetadata.Mental}
            </p>
          </div>
          <div className="col-6">
            <p className="text-white mb-3">
              TEAMWORK <br /> {genesisMetadata.Teamwork}
            </p>
          </div>
        </div>

        <div className="row font-13">          
          <div className="col-6">
            <p className="text-white mb-3">
              OVERALL <br /> {overall}
            </p>
          </div>

          <div className="col-6">
            <p className="text-white mb-3">
              SPECIAL SKILL <br /> {genesisMetadata["Special Skill"]}
            </p>
          </div>
        </div>
      </>
  }

  // console.log(props.data);

  return (
    <div className='col-xxl-3 col-md-4 col-sm-6 p-3'>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <Card className="p-3" bg={"dark"}>
          <Card.Img variant="top" src={props.data.thumbnail} />
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
              <h5 className="text-center text-white mt-2">
                {props.data.name}
              </h5>
              
              <p
                className="text-white text-center mt-2 mb-0"
                role="button"
                onClick={() => profileClick()}
              >
                See Profile
              </p>

              {turboGenesisID.includes(props.data.nftId) ?
              <Button className="mt-2" variant="primary" onClick={unselectClick}>
                Unselect
              </Button>
              :
              <Button className="mt-2" variant="warning" onClick={selectClick}>
                Select
              </Button>
              }
          </Card.Body>
        </Card>

        <Card className="p-3" bg={"dark"}>
          <h5 className="text-center text-white mt-5 mb-5">
            {props.data.name}
          </h5>

          {backCard}

          <p
            className="text-white text-center mb-1 font-weight-bold"
            onClick={() => profileClick(props.data.name)}
            role="button"
          >
            Back
          </p>
        </Card>
      </ReactCardFlip>
    </div>
  );
}
