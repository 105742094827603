export const createNewBidRemoveOldData = `
import FungibleToken from 0xf233dcee88fe0abe
import VroomToken from 0xf887ece39166906e
import VroomTokenRepository from 0xf887ece39166906e
import HouseMetadata from 0x66b60643244a7738

transaction(tresorId: UInt64, paymentAmount: UFix64, sellerAddress: Address, recipientAddress: Address, price: UFix64, amount: UFix64, auctionType: String, timeStamp: String, TxID: String, newTxID: String) {
    let paymentVault: @FungibleToken.Vault
    let vroomTokenVaultRef: &VroomToken.Vault{FungibleToken.Provider}
    let buyer: AuthAccount

    prepare(signer: AuthAccount) {
        self.buyer = signer

        // Borrow a reference to the buyer's Flow token vault to make a payment
        let vaultRef = signer.borrow<&FungibleToken.Vault{FungibleToken.Provider}>(
            from: /storage/flowTokenVault
        ) ?? panic("Could not borrow reference to the Flow token vault")
        
        // Withdraw Flow tokens for payment
        self.paymentVault <- vaultRef.withdraw(amount: paymentAmount)

        // Attempt to borrow a reference to the VroomTokenRepository's Repository
        var repositoryRef = signer.borrow<&VroomTokenRepository.Repository{VroomTokenRepository.RepositoryManager}>(
            from: VroomTokenRepository.RepositoryStoragePath
        )

        // If the repository does not exist, create and initialize it
        if (repositoryRef == nil) {
            let newRepository <- VroomTokenRepository.createRepository()
            signer.save(<-newRepository, to: VroomTokenRepository.RepositoryStoragePath)
            signer.link<&VroomTokenRepository.Repository{VroomTokenRepository.RepositoryPublic}>(
                VroomTokenRepository.RepositoryPublicPath,
                target: VroomTokenRepository.RepositoryStoragePath
            )
            repositoryRef = signer.borrow<&VroomTokenRepository.Repository{VroomTokenRepository.RepositoryManager}>(
                from: VroomTokenRepository.RepositoryStoragePath
            ) ?? panic("Could not borrow reference to the newly created VroomTokenRepository")
        }

                // Create a new tresor and add new metadata
        let newTresorId = repositoryRef!.createTresor(signer: self.buyer, price: price, amount: amount)
        HouseMetadata.addMetadata(
            userAddress: self.buyer.address,
            auctionType: auctionType,
            currentBid: amount,
            timeStamp: timeStamp,
            txID: newTxID,
            tresorID: newTresorId
        )

        
        // Borrow a reference to the signer's VroomToken vault to create a new tresor
        self.vroomTokenVaultRef = signer.borrow<&VroomToken.Vault{FungibleToken.Provider}>(
            from: VroomToken.VaultStoragePath
        ) ?? panic("Could not borrow reference to the VroomToken vault")
    }

    execute {
        // Remove the metadata using the old transaction ID (clean up old bid data)
        HouseMetadata.removeMetadataByTxID(txIDToRemove: TxID)

        let sellerAccount = getAccount(sellerAddress)
        let sellerRepositoryRef = sellerAccount
            .getCapability<&VroomTokenRepository.Repository{VroomTokenRepository.RepositoryPublic}>(
                VroomTokenRepository.RepositoryPublicPath
            )
            .borrow()
            ?? panic("Could not borrow reference to the seller's Repository")

        // Handle the tresor transaction with the old recipient
        let tresorRef = sellerRepositoryRef.borrowTresor(tresorResourceID: tresorId)
            ?? panic("Tresor does not exist")
        let recipientAccount = getAccount(recipientAddress)
        let recipientReceiver = recipientAccount.getCapability<&{FungibleToken.Receiver}>(VroomToken.VaultReceiverPath)
            .borrow()
            ?? panic("Could not borrow receiver reference to the recipient's VroomToken vault")
        tresorRef.transferAndRemoveTresor(buyerVaultRef: recipientReceiver, repositoryRef: sellerRepositoryRef)

        // Transfer the payment to the seller
        let sellerReceiver = sellerAccount.getCapability<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)
            .borrow()
            ?? panic("Could not borrow receiver reference to the seller's Flow token vault")
        sellerReceiver.deposit(from: <- self.paymentVault)

    }
}

`