export const transferCarClub = `
import NonFungibleToken from 0x1d7e57aa55817448
import CarClub from 0xf887ece39166906e

transaction (receiverAddress: Address, nftId: UInt64){

    prepare(signer: auth(Storage, BorrowValue) &Account) {
    
        let recipient = getAccount(receiverAddress)

        let collectionRef = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: /storage/CarClubCollection)
            ?? panic("Could not borrow a reference to the owner's collection")

        let depositRef = recipient.capabilities.borrow<&{NonFungibleToken.Collection}>(/public/CarClubCollection)
            ?? panic("Could not borrow a reference to the receiver's collection")

        let nft <- collectionRef.withdraw(withdrawID: nftId)

        depositRef.deposit(token: <- nft)

    }
}
`