import { useEffect, useState } from "react";
import { Col, Card, Button, Form } from "react-bootstrap";
import ReactCardFlip from "react-card-flip";
import * as fcl from "@onflow/fcl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CarClub_Metadata from "../data/carclub_metadata.json";

export default function RacingItem(props) {
  const {carClubID, setCarClubID, carClubSequence} = props;
  const [isFlipped, setIsFlipped] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [carclubMetadata, setCarClubMetadata] = useState(null);
  var backCard = "";

  useEffect(() => {
    CarClub_Metadata.map((data) => {
      if (data.Name === props.data.name){
        setCarClubMetadata(data);
      }
    });

    if(props.data.nftId >= 5001 && props.data.nftId <= 5525){
      setThumbnail("https://coral-capable-mackerel-46.mypinata.cloud/ipfs/Qmb2FFHiULyPYcX2DrvHxa3ME2dQiNbNHAwySqVom5kqt2/%28"+ props.data.nftId +"%29.mp4");
      setIsVideo(true);
    }else{
      setThumbnail(props.data.thumbnail);
      setIsVideo(false);
    }
  }, []);

  const profileClick = () => {
    setIsFlipped(!isFlipped);
  };

  const selectClick = () => {
    if(carClubSequence === 1){
      var tempID = [...carClubID];
      tempID[0] = props.data.nftId;
      setCarClubID(tempID);
    }else if(carClubSequence === 2){
      var tempID = [...carClubID];
      tempID[1] = props.data.nftId;
      setCarClubID(tempID);
    }else if(carClubSequence === 3){
      var tempID = [...carClubID];
      tempID[2] = props.data.nftId;
      setCarClubID(tempID);
    }else if(carClubSequence === 4){
      var tempID = [...carClubID];
      tempID[3] = props.data.nftId;
      setCarClubID(tempID);
    }
  }

  const unselectClick = () => {
    if(carClubSequence === 1){
      var tempID = [...carClubID];
      tempID[0] = "";
      setCarClubID(tempID);
    }else if(carClubSequence === 2){
      var tempID = [...carClubID];
      tempID[1] = "";
      setCarClubID(tempID);
    }else if(carClubSequence === 3){
      var tempID = [...carClubID];
      tempID[2] = "";
      setCarClubID(tempID);
    }else if(carClubSequence === 4){
      var tempID = [...carClubID];
      tempID[3] = "";
      setCarClubID(tempID);
    }else if(carClubSequence === 5){
      var tempID = [...carClubID];
      tempID[4] = "";
      setCarClubID(tempID);
    }
  }

  if (carclubMetadata) {
    backCard = 
      <>
        <div className="row font-13 mt-2">
          <div className="col-6">
            <p className="text-white mb-3">
              BRAKING <br /> {carclubMetadata["Braking"]}
            </p>
          </div>

          <div className="col-6">
            <p className="text-white mb-3">
              TURNING <br /> {carclubMetadata["Turning"]}
            </p>
          </div>          
        </div>

        <div className="row font-13 mt-2">
          <div className="col-6">
            <p className="text-white mb-3">
              SPEED <br /> {carclubMetadata["Speed"]}
            </p>
          </div>

          <div className="col-6">
            <p className="text-white mb-3">
              PASSING <br /> {carclubMetadata["Passing"]}
            </p>
          </div>
        </div>
      </>
  }

  // console.log(props.data);

  return (
    <div className='col-xxl-3 col-md-4 col-sm-6 p-3'>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <Card className="p-3" bg={"dark"}>
          {isVideo ?
          <video width="100%" height="auto" controls>
            <source src={thumbnail} type="video/mp4" />
          </video>
          :
          <Card.Img variant="top" src={thumbnail} />
          }
          <Card.Body className="d-flex flex-column align-items-center justify-content-center">
            <h5 className="text-center text-white mt-2">
              {props.data.name}
            </h5>
            
            <p
              className="text-white text-center mb-3 mt-2"
              role="button"
              onClick={() => profileClick()}
            >
              See Profile
            </p>

            {carClubID.includes(props.data.nftId) ?
            <Button variant="primary" onClick={unselectClick}>
              Unselect
            </Button>
            :
            <Button variant="warning" onClick={selectClick}>
              Select
            </Button>
            }
          </Card.Body>
        </Card>

        <Card className="p-3" bg={"dark"}>
          <h5 className="text-center text-white mt-5 mb-5">
            {props.data.name}
          </h5>

          {backCard}

          <p
            className="text-white text-center mb-4 mt-5 font-weight-bold"
            onClick={() => profileClick(props.data.name)}
            role="button"
          >
            Back
          </p>
        </Card>
      </ReactCardFlip>
    </div>
  );
}
