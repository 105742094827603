export const checkOwnInsurance = `
import DriverzInsurance from 0xf887ece39166906e

pub fun main(address: Address): Bool {
    if(getAccount(address).getCapability<&{DriverzInsurance.CollectionPublic}>(DriverzInsurance.CollectionPublicPath).borrow() != nil){
        let driverzCollection:&{DriverzInsurance.CollectionPublic} = getAccount(address).getCapability<&{DriverzInsurance.CollectionPublic}>(DriverzInsurance.CollectionPublicPath).borrow()!
        if(driverzCollection.getIDs().length > 0){
            return true
        } else {

            return false
        }
    } else {
        return false
    }
}
`