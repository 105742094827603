export const checkVroomBalance = `

import FungibleToken from 0xf233dcee88fe0abe
import VroomToken from 0xf887ece39166906e


access(all) fun main(address: Address): UFix64 {
    let vaultRef = getAccount(address).capabilities.borrow<&{FungibleToken.Balance}>(/public/VroomTokenBalance)
        ?? panic("Could not borrow reference to the vault!")

    return vaultRef.balance
}

`
