export const initAll = `

//Mainnet

import FungibleToken from 0xf233dcee88fe0abe
import DriverzNFT from 0xa039bd7d55a96c0c
import VroomToken from 0xf887ece39166906e
import CarClub from 0xf887ece39166906e
import MetadataViews from 0x1d7e57aa55817448
import NonFungibleToken from 0x1d7e57aa55817448

transaction {
    prepare(signer: auth(Storage, Capabilities) &Account) {
        if signer.storage.borrow<&CarClub.Collection>(from: CarClub.CollectionStoragePath) == nil {
            let collection <- CarClub.createEmptyCollection(nftType: Type<@CarClub.NFT>())
            signer.storage.save(<-collection, to: CarClub.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(CarClub.CollectionStoragePath),
                at: CarClub.CollectionPublicPath    
            )
        }

        if signer.storage.borrow<&DriverzNFT.Collection>(from: DriverzNFT.CollectionStoragePath) == nil {
            let collection <- DriverzNFT.createEmptyCollection(nftType: Type<@DriverzNFT.NFT>())
            signer.storage.save(<-collection, to: DriverzNFT.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(DriverzNFT.CollectionStoragePath),
                at: DriverzNFT.CollectionPublicPath    
            )
        }


        if signer.storage.borrow<&VroomToken.Vault>(from: VroomToken.VaultStoragePath) == nil {
            let vault <- VroomToken.createEmptyVault(vaultType: Type<@VroomToken.Vault>())
            signer.storage.save(<-vault, to: VroomToken.VaultStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{FungibleToken.Receiver}>(VroomToken.VaultStoragePath),
                at: VroomToken.VaultReceiverPath
            )

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{FungibleToken.Balance}>(VroomToken.VaultStoragePath),
                at: VroomToken.VaultBalancePath
            )
        }
    }
}
    
`
