import { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import * as fcl from "@onflow/fcl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink, CSVDownload } from "react-csv";
import "./Driverz.css"

import { getAllNFTs } from '../../Cadence/marketplace/getAllNFTs';
import RacingCarItem from '../../components/RacingCarItem';
import RacingGenesisItem from '../../components/RacingGenesisItem';
import RacingRentalItem from '../../components/RacingRentalItem';
import TurboGenesisItem from '../../components/TurboGenesisItem';
import Genesis_Metadata from "../../data/genesis_metadata.json";
import CarClub_Metadata from "../../data/carclub_metadata.json";
import FirebaseService from '../../services/FirebaseService';

export default function Driverz() {
  const [user, setUser] = useState({ loggedIn: null });
  const [nft, setNFT] = useState([]);
  const [rental, setRental] = useState([]);

  const [currentDate, setCurrentDate] = useState(null);
  const [email, setEmail] = useState("");
  const [discord, setDiscord] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [teamLogo, setTeamLogo] = useState(null);
  const [isLogoVideo, setIsLogoVideo] = useState(false);
  const [show, setShow] = useState(false);
  const [carClubShow, setCarClubShow] = useState(false);
  const [rentalShow, setRentalShow] = useState(false);
  
  const [carClubID, setCarClubID] = useState(["", "", "", ""]);
  const [carClubImg, setCarClubImg] = useState(["", "", "", ""]);
  const [carClubMetadata, setCarClubMetadata] = useState([null, null, null, null]);
  const [carClubSequence, setCarClubSequence] = useState(0);

  const [genesisID, setGenesisID] = useState(["", "", "", ""]);
  const [genesisNum ,setGensisNum] = useState(["", "", "", ""]);
  const [genesisName, setGenesisName] = useState(["", "", "", ""]);
  const [genesis, setGenesis] = useState([null, null, null, null]);
  const [genesisMetadata, setGenesisMetadata] = useState([null, null, null, null]);
  const [genesisOverall, setGenesisOverall] = useState([0,0,0,0]);
  const [genesisSequence, setGenesisSequence] = useState(0);

  const [rentalID, setRentalID] = useState("");
  const [rentalItem, setRentalItem] = useState(null);

  const [turboTeamLogo, setTurboTeamLogo] = useState(null);
  const [turboSelectedFile, setTurboSelectedFile] = useState(null);
  const [isTurboLogoVideo, setIsTurboLogoVideo] = useState(false);

  const [turboShow, setTurboShow] = useState(false);
  const [turboGenesisID, setTurboGenesisID] = useState(["", ""]);
  const [turboGenesisNum ,setTurboGensisNum] = useState(["", "", "", ""]);
  const [turboGenesisName, setTurboGenesisName] = useState(["", ""]);
  const [turboGenesis, setTurboGenesis] = useState([null, null]);
  const [turboGenesisMetadata, setTurboGenesisMetadata] = useState([null, null]);
  const [turboGenesisOverall, setTurboGenesisOverall] = useState([0,0]);
  const [turboGenesisSequence, setTurboGenesisSequence] = useState(0);

  const [formData, setFormData] = useState([]);
  const [formKey, setFormKey] = useState(null);
  const [csv, setCSV] = useState([]);

  var carClubContent = '';
  var genesisContent = '';
  var rentalContent = '';
  var turboGenesisContent = '';  

  const submitForm = () => {
    var data = {
      "Dapper Wallet": user.addr, "Date Submitted": currentDate, "Email": email, "Discord": discord, "Twitter": twitter, "Instagram": instagram, "Email Opt Out": true,
      
      "Driverz 1 ID": genesisID[0], "Driverz 1 Name": genesisName[0],
      "Driverz 1 Mental": genesisMetadata[0] === null ? "" : genesisMetadata[0]["Mental"],
      "Driverz 1 Physical Stamina": genesisMetadata[0] === null ? "" : genesisMetadata[0]["Physical Stamina"],
      "Driverz 1 Luck": genesisMetadata[0] === null ? "" : genesisMetadata[0]["Luck"],
      "Driverz 1 TeamWork": genesisMetadata[0] === null ? "" : genesisMetadata[0]["Teamwork"],
      "Driverz 1 Overall": genesisMetadata[0] === null ? "" : genesisOverall[0],
      "Driverz 1 Special Skill": genesisMetadata[0] === null ? "" : genesisMetadata[0]["Special Skill"],

      "Driverz 2 ID": genesisID[1], "Driverz 2 Name": genesisName[1],
      "Driverz 2 Mental": genesisMetadata[1] === null ? "" : genesisMetadata[1]["Mental"],
      "Driverz 2 Physical Stamina": genesisMetadata[1] === null ? "" : genesisMetadata[1]["Physical Stamina"],
      "Driverz 2 Luck": genesisMetadata[1] === null ? "" : genesisMetadata[1]["Luck"],
      "Driverz 2 TeamWork": genesisMetadata[1] === null ? "" : genesisMetadata[1]["Teamwork"],
      "Driverz 2 Overall": genesisMetadata[1] === null ? "" : genesisOverall[1],
      "Driverz 2 Special Skill": genesisMetadata[1] === null ? "" : genesisMetadata[1]["Special Skill"],

      "Driverz 3 ID": genesisID[2], "Driverz 3 Name": genesisName[2],
      "Driverz 3 Mental": genesisMetadata[2] === null ? "" : genesisMetadata[2]["Mental"],
      "Driverz 3 Physical Stamina": genesisMetadata[2] === null ? "" : genesisMetadata[2]["Physical Stamina"],
      "Driverz 3 Luck": genesisMetadata[2] === null ? "" : genesisMetadata[2]["Luck"],
      "Driverz 3 TeamWork": genesisMetadata[2] === null ? "" : genesisMetadata[2]["Teamwork"],
      "Driverz 3 Overall": genesisMetadata[2] === null ? "" : genesisOverall[2],
      "Driverz 3 Special Skill": genesisMetadata[2] === null ? "" : genesisMetadata[2]["Special Skill"],

      "Driverz 4 ID": genesisID[3], "Driverz 4 Name": genesisName[3],
      "Driverz 4 Mental": genesisMetadata[3] === null ? "" : genesisMetadata[3]["Mental"],
      "Driverz 4 Physical Stamina": genesisMetadata[3] === null ? "" : genesisMetadata[3]["Physical Stamina"],
      "Driverz 4 Luck": genesisMetadata[3] === null ? "" : genesisMetadata[3]["Luck"],
      "Driverz 4 TeamWork": genesisMetadata[3] === null ? "" : genesisMetadata[3]["Teamwork"],
      "Driverz 4 Overall": genesisMetadata[3] === null ? "" : genesisOverall[3],
      "Driverz 4 Special Skill": genesisMetadata[3] === null ? "" : genesisMetadata[3]["Special Skill"],

      "Car Club 1 ID": carClubID[0],
      "Car Club 1 Braking": carClubMetadata[0] === null ? "" : carClubMetadata[0]["Braking"],
      "Car Club 1 Turning": carClubMetadata[0] === null ? "" : carClubMetadata[0]["Turning"],
      "Car Club 1 Speed": carClubMetadata[0] === null ? "" : carClubMetadata[0]["Speed"],
      "Car Club 1 Passing": carClubMetadata[0] === null ? "" : carClubMetadata[0]["Passing"],

      "Car Club 2 ID": carClubID[1],
      "Car Club 2 Braking": carClubMetadata[1] === null ? "" : carClubMetadata[1]["Braking"],
      "Car Club 2 Turning": carClubMetadata[1] === null ? "" : carClubMetadata[1]["Turning"],
      "Car Club 2 Speed": carClubMetadata[1] === null ? "" : carClubMetadata[1]["Speed"],
      "Car Club 2 Passing": carClubMetadata[1] === null ? "" : carClubMetadata[1]["Passing"],

      "Car Club 3 ID": carClubID[2],
      "Car Club 3 Braking": carClubMetadata[2] === null ? "" : carClubMetadata[2]["Braking"],
      "Car Club 3 Turning": carClubMetadata[2] === null ? "" : carClubMetadata[2]["Turning"],
      "Car Club 3 Speed": carClubMetadata[2] === null ? "" : carClubMetadata[2]["Speed"],
      "Car Club 3 Passing": carClubMetadata[2] === null ? "" : carClubMetadata[2]["Passing"],

      "Car Club 4 ID": carClubID[3],
      "Car Club 4 Braking": carClubMetadata[3] === null ? "" : carClubMetadata[3]["Braking"],
      "Car Club 4 Turning": carClubMetadata[3] === null ? "" : carClubMetadata[3]["Turning"],
      "Car Club 4 Speed": carClubMetadata[3] === null ? "" : carClubMetadata[3]["Speed"],
      "Car Club 4 Passing": carClubMetadata[3] === null ? "" : carClubMetadata[3]["Passing"],

      "Turbo Driverz 1 ID": turboGenesisID[0], "Turbo Driverz 1 Name": turboGenesisName[0],
      "Turbo Driverz 1 Mental": turboGenesisMetadata[0] === null ? "" : turboGenesisMetadata[0]["Mental"],
      "Turbo Driverz 1 Physical Stamina": turboGenesisMetadata[0] === null ? "" : turboGenesisMetadata[0]["Physical Stamina"],
      "Turbo Driverz 1 Luck": turboGenesisMetadata[0] === null ? "" : turboGenesisMetadata[0]["Luck"],
      "Turbo Driverz 1 TeamWork": turboGenesisMetadata[0] === null ? "" : turboGenesisMetadata[0]["Teamwork"],
      "Turbo Driverz 1 Overall": turboGenesisMetadata[0] === null ? "" : turboGenesisOverall[0],
      "Turbo Driverz 1 Special Skill": turboGenesisMetadata[0] === null ? "" : turboGenesisMetadata[0]["Special Skill"],
      
      "Turbo Driverz 2 ID": turboGenesisID[1], "Turbo Driverz 2 Name": turboGenesisName[1],
      "Turbo Driverz 2 Mental": turboGenesisMetadata[1] === null ? "" : turboGenesisMetadata[1]["Mental"],
      "Turbo Driverz 2 Physical Stamina": turboGenesisMetadata[1] === null ? "" : turboGenesisMetadata[1]["Physical Stamina"],
      "Turbo Driverz 2 Luck": turboGenesisMetadata[1] === null ? "" : turboGenesisMetadata[1]["Luck"],
      "Turbo Driverz 2 TeamWork": turboGenesisMetadata[1] === null ? "" : turboGenesisMetadata[1]["Teamwork"],
      "Turbo Driverz 2 Overall": turboGenesisMetadata[1] === null ? "" : turboGenesisOverall[1],
      "Turbo Driverz 2 Special Skill": turboGenesisMetadata[1] === null ? "" : turboGenesisMetadata[1]["Special Skill"],

      "DRLLogo": selectedFile, "TurboLogo": turboSelectedFile,
    }

    localStorage.setItem("teamLogo", teamLogo);
    localStorage.setItem("turboTeamLogo", turboTeamLogo);

    

    if(formKey === null){
      FirebaseService.create(data)
      .then(() => {
        toast.success("Successfully submitted!");
      }).catch(e => {
        toast.error(e);
      });
    }else{
      FirebaseService.update(formKey, data)
      .then(() => {
        toast.success("Successfully updated!");
      }).catch(e => {
        toast.error(e);
      });
    }

  }

  const onDataChange = (items) => {
    const csvData = [
      [
        "Dapper Wallet", "Date Submitted", "Email", "Discord", "Twitter", "Instagram", "Email Opt Out",
        "Driverz 1 ID", "Driverz 1 Name", "Driverz 1 Mental", "Driverz 1 Physical Stamina", "Driverz 1 Luck", "Driverz 1 Teamwork", "Driverz 1 Overall", "Driverz 1 Special Skill",
        "Driverz 2 ID", "Driverz 2 Name", "Driverz 2 Mental", "Driverz 2 Physical Stamina", "Driverz 2 Luck", "Driverz 2 Teamwork", "Driverz 2 Overall", "Driverz 2 Special Skill",
        "Driverz 3 ID", "Driverz 3 Name", "Driverz 3 Mental", "Driverz 3 Physical Stamina", "Driverz 3 Luck", "Driverz 3 Teamwork", "Driverz 3 Overall", "Driverz 3 Special Skill",
        "Driverz 4 ID", "Driverz 4 Name", "Driverz 4 Mental", "Driverz 4 Physical Stamina", "Driverz 4 Luck", "Driverz 4 Teamwork", "Driverz 4 Overall", "Driverz 4 Special Skill",
        "Car Club 1 ID", "Car Club 1 Braking", "Car Club 1 Turning", "Car Club 1 Speed", "Car Club 1 Passing",
        "Car Club 2 ID", "Car Club 2 Braking", "Car Club 2 Turning", "Car Club 2 Speed", "Car Club 2 Passing",
        "Car Club 3 ID", "Car Club 3 Braking", "Car Club 3 Turning", "Car Club 3 Speed", "Car Club 3 Passing",
        "Car Club 4 ID", "Car Club 4 Braking", "Car Club 4 Turning", "Car Club 4 Speed", "Car Club 4 Passing",
        "Turbo Driverz 1 ID", "Turbo Driverz 1 Name", "Turbo Driverz 1 Mental", "Turbo Driverz 1 Physical Stamina", "Turbo Driverz 1 Luck", "Turbo Driverz 1 Teamwork", "Turbo Driverz 1 Overall", "Turbo Driverz 1 Special Skill",
        "Turbo Driverz 2 ID", "Turbo Driverz 2 Name", "Turbo Driverz 2 Mental", "Turbo Driverz 2 Physical Stamina", "Turbo Driverz 2 Luck", "Turbo Driverz 2 Teamwork", "Turbo Driverz 2 Overall", "Turbo Driverz 2 Special Skill",
      ],
    ];

    items.forEach((item) => {
      let key = item.key;
      let data = item.val();

      const arr = [
        data["Dapper Wallet"], data["Date Submitted"], data["Email"], data["Discord"], data["Twitter"], data["Instagram"], data["Email Opt Out"],
        data["Driverz 1 ID"], data["Driverz 1 Name"], data["Driverz 1 Mental"], data["Driverz 1 Physical Stamina"], data["Driverz 1 Luck"], data["Driverz 1 TeamWork"], data["Driverz 1 Overall"], data["Driverz 1 Special Skill"],
        data["Driverz 2 ID"], data["Driverz 2 Name"], data["Driverz 2 Mental"], data["Driverz 2 Physical Stamina"], data["Driverz 2 Luck"], data["Driverz 2 TeamWork"], data["Driverz 2 Overall"], data["Driverz 2 Special Skill"],
        data["Driverz 3 ID"], data["Driverz 3 Name"], data["Driverz 3 Mental"], data["Driverz 3 Physical Stamina"], data["Driverz 3 Luck"], data["Driverz 3 TeamWork"], data["Driverz 3 Overall"], data["Driverz 3 Special Skill"],
        data["Driverz 4 ID"], data["Driverz 4 Name"], data["Driverz 4 Mental"], data["Driverz 4 Physical Stamina"], data["Driverz 4 Luck"], data["Driverz 4 TeamWork"], data["Driverz 4 Overall"], data["Driverz 4 Special Skill"],
        data["Car Club 1 ID"], data["Car Club 1 Braking"], data["Car Club 1 Turning"], data["Car Club 1 Speed"], data["Car Club 1 Passing"],
        data["Car Club 2 ID"], data["Car Club 2 Braking"], data["Car Club 2 Turning"], data["Car Club 2 Speed"], data["Car Club 2 Passing"],
        data["Car Club 3 ID"], data["Car Club 3 Braking"], data["Car Club 3 Turning"], data["Car Club 3 Speed"], data["Car Club 3 Passing"],
        data["Car Club 4 ID"], data["Car Club 4 Braking"], data["Car Club 4 Turning"], data["Car Club 4 Speed"], data["Car Club 4 Passing"],
        data["Turbo Driverz 1 ID"], data["Turbo Driverz 1 Name"], data["Turbo Driverz 1 Mental"], data["Turbo Driverz 1 Physical Stamina"], data["Turbo Driverz 1 Luck"], data["Turbo Driverz 1 TeamWork"], data["Turbo Driverz 1 Overall"], data["Turbo Driverz 1 Special Skill"],
        data["Turbo Driverz 2 ID"], data["Turbo Driverz 2 Name"], data["Turbo Driverz 2 Mental"], data["Turbo Driverz 2 Physical Stamina"], data["Turbo Driverz 2 Luck"], data["Turbo Driverz 2 TeamWork"], data["Turbo Driverz 2 Overall"], data["Turbo Driverz 2 Special Skill"]
      ];

      csvData.push(arr);

      if(data["Dapper Wallet"] === user.addr){
        setFormKey(item.key);
        const tempForm = [];
        tempForm.push(
          data["Dapper Wallet"], data["Date Submitted"], data["Email"], data["Discord"], data["Twitter"], data["Instagram"], data["Email Opt Out"],
          data["Driverz 1 ID"], data["Driverz 1 Name"], data["Driverz 1 Mental"], data["Driverz 1 Physical Stamina"], data["Driverz 1 Luck"], data["Driverz 1 TeamWork"], data["Driverz 1 Overall"], data["Driverz 1 Special Skill"],
          data["Driverz 2 ID"], data["Driverz 2 Name"], data["Driverz 2 Mental"], data["Driverz 2 Physical Stamina"], data["Driverz 2 Luck"], data["Driverz 2 TeamWork"], data["Driverz 2 Overall"], data["Driverz 2 Special Skill"],
          data["Driverz 3 ID"], data["Driverz 3 Name"], data["Driverz 3 Mental"], data["Driverz 3 Physical Stamina"], data["Driverz 3 Luck"], data["Driverz 3 TeamWork"], data["Driverz 3 Overall"], data["Driverz 3 Special Skill"],
          data["Driverz 4 ID"], data["Driverz 4 Name"], data["Driverz 4 Mental"], data["Driverz 4 Physical Stamina"], data["Driverz 4 Luck"], data["Driverz 4 TeamWork"], data["Driverz 4 Overall"], data["Driverz 4 Special Skill"],
          data["Car Club 1 ID"], data["Car Club 1 Braking"], data["Car Club 1 Turning"], data["Car Club 1 Speed"], data["Car Club 1 Passing"],
          data["Car Club 2 ID"], data["Car Club 2 Braking"], data["Car Club 2 Turning"], data["Car Club 2 Speed"], data["Car Club 2 Passing"],
          data["Car Club 3 ID"], data["Car Club 3 Braking"], data["Car Club 3 Turning"], data["Car Club 3 Speed"], data["Car Club 3 Passing"],
          data["Car Club 4 ID"], data["Car Club 4 Braking"], data["Car Club 4 Turning"], data["Car Club 4 Speed"], data["Car Club 4 Passing"],
          data["Turbo Driverz 1 ID"], data["Turbo Driverz 1 Name"], data["Turbo Driverz 1 Mental"], data["Turbo Driverz 1 Physical Stamina"], data["Turbo Driverz 1 Luck"], data["Turbo Driverz 1 TeamWork"], data["Turbo Driverz 1 Overall"], data["Turbo Driverz 1 Special Skill"],
          data["Turbo Driverz 2 ID"], data["Turbo Driverz 2 Name"], data["Turbo Driverz 2 Mental"], data["Turbo Driverz 2 Physical Stamina"], data["Turbo Driverz 2 Luck"], data["Turbo Driverz 2 TeamWork"], data["Turbo Driverz 2 Overall"], data["Turbo Driverz 2 Special Skill"],
          data["DRLLogo"], data["TurboLogo"]
        );
        
        setFormData(tempForm);

        setFormKey(item.key);
      }
    });
    // console.log(csvData);
    setCSV(csvData);
  };

  useEffect(() => {
    fcl.currentUser.subscribe(setUser);

    var date = new Date(Date.now());
    var dateMDY = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    setCurrentDate(dateMDY);    
  }, []);

  useEffect(() => {   
    getNFTs();

    FirebaseService.getAll().on("value", onDataChange);
    return () => {
      FirebaseService.getAll().off("value", onDataChange);
    };
  }, [user]);

  useEffect(() => {
    getNFTs();
  }, [formData]);

  useEffect(() => {
    let logo = localStorage.getItem("teamLogo");

    if(logo !== null){
      setTeamLogo(logo);
    }else if (!selectedFile) {
      setTeamLogo(undefined);
      return;
    }else{
      const objectUrl = URL.createObjectURL(selectedFile);
      setTeamLogo(objectUrl);

      if(selectedFile.type.includes("image")){
        setIsLogoVideo(false);
      }else{
        setIsLogoVideo(true);
      }

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }

  }, [selectedFile]);

  useEffect(() => {
    let turboLogo = localStorage.getItem("turboTeamLogo");

    if(turboLogo !== null){
      setTurboTeamLogo(turboLogo);
    }else if (!turboSelectedFile) {
      setTurboTeamLogo(undefined);
      return;
    }else{
      const objectUrl = URL.createObjectURL(turboSelectedFile);
      setTurboTeamLogo(objectUrl);

      if(turboSelectedFile.type.includes("image")){
        setIsTurboLogoVideo(false);
      }else{
        setIsTurboLogoVideo(true);
      }

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }

  }, [turboSelectedFile]);

  useEffect(() => {
    if(carClubID[0] === ""){
      setCarClubImg(prev => {
        const temp = [...prev];
        temp[0] = "";

        return temp;
      });
    }else if(carClubID[1] === ""){
      setCarClubImg(prev => {
        const temp = [...prev];
        temp[1] = "";

        return temp;
      });
    }else if(carClubID[2] === ""){
      setCarClubImg(prev => {
        const temp = [...prev];
        temp[2] = "";

        return temp;
      });
    }else if(carClubID[3] === ""){
      setCarClubImg(prev => {
        const temp = [...prev];
        temp[3] = "";

        return temp;
      });
    }

    nft.map((data) => {
      if(data.nftId === carClubID[0]){
        setCarClubImg(prev => {
          const tempImg = [...prev];

          if(data.nftId >= 5001 && data.nftId <= 5525){
            tempImg[0] = "https://coral-capable-mackerel-46.mypinata.cloud/ipfs/Qmb2FFHiULyPYcX2DrvHxa3ME2dQiNbNHAwySqVom5kqt2/%28"+ data.nftId +"%29.mp4";
          }else{
            tempImg[0] = data.thumbnail;
          }

          return tempImg;
        });

        CarClub_Metadata.forEach((item) => {
          if( item.Name.includes(carClubID[0]) ){
            var tempMetadata = [...carClubMetadata];
            tempMetadata[0] = item;
            setCarClubMetadata(tempMetadata);
          }
        });

      } else if(data.nftId === carClubID[1]) {
        setCarClubImg(prev => {
          const tempImg = [...prev];

          if(data.nftId >= 5001 && data.nftId <= 5525){ 
            tempImg[1] = "https://coral-capable-mackerel-46.mypinata.cloud/ipfs/Qmb2FFHiULyPYcX2DrvHxa3ME2dQiNbNHAwySqVom5kqt2/%28"+ data.nftId +"%29.mp4";
          }else{
            tempImg[1] = data.thumbnail;
          }
          
          return tempImg;
        });

        CarClub_Metadata.forEach((item) => {
          if( item.Name.includes(carClubID[1]) ){
            var tempMetadata = [...carClubMetadata];
            tempMetadata[1] = item;
            setCarClubMetadata(tempMetadata);
          }
        });

      } else if(data.nftId === carClubID[2]) {
        setCarClubImg(prev => {
          const tempImg = [...prev];

          if(data.nftId >= 5001 && data.nftId <= 5525){
            tempImg[2] = "https://coral-capable-mackerel-46.mypinata.cloud/ipfs/Qmb2FFHiULyPYcX2DrvHxa3ME2dQiNbNHAwySqVom5kqt2/%28"+ data.nftId +"%29.mp4";
          }else{
            tempImg[2] = data.thumbnail;
          }

          return tempImg;
        });

        CarClub_Metadata.forEach((item) => {
          if( item.Name.includes(carClubID[2]) ){
            var tempMetadata = [...carClubMetadata];
            tempMetadata[2] = item;
            setCarClubMetadata(tempMetadata);
          }
        });

      } else if(data.nftId === carClubID[3]) {
        setCarClubImg(prev => {
          const tempImg = [...prev];

          if (data.nftId >= 5001 && data.nftId <= 5525) {
            tempImg[3] = "https://coral-capable-mackerel-46.mypinata.cloud/ipfs/Qmb2FFHiULyPYcX2DrvHxa3ME2dQiNbNHAwySqVom5kqt2/%28"+ data.nftId +"%29.mp4";
          } else {
            tempImg[3] = data.thumbnail;
          }

          return tempImg;
        });

        CarClub_Metadata.forEach((item) => {
          if( item.Name.includes(carClubID[3]) ){
            var tempMetadata = [...carClubMetadata];
            tempMetadata[3] = item;
            setCarClubMetadata(tempMetadata);
          }
        });
      }
    });
  }, [carClubID]);

  useEffect(() => {
    if(genesisID[0] === ""){
      setGenesis(prev => {
        const temp = [...prev];
        temp[0] = null;

        return temp;
      });
    }else if(genesisID[1] === ""){
      setGenesis(prev => {
        const temp = [...prev];
        temp[1] = null;

        return temp;
      });
    }else if(genesisID[2] === ""){
      setGenesis(prev => {
        const temp = [...prev];
        temp[2] = null;

        return temp;
      });
    }else if(genesisID[3] === ""){
      setGenesis(prev => {
        const temp = [...prev];
        temp[3] = null;

        return temp;
      });
    }

    nft.map((data) => {
      if(genesisID[0] === data.nftId){
        setGenesis(prev => {
          const temp = [...prev];
          temp[0] = data;

          return temp;
        });

        setGensisNum(prev => {
          const temp = [...prev];          
          const str = data.name.split('#');
          temp[0] = str[1];

          return temp;
        });

        Genesis_Metadata.forEach((item) => {
          if(item.revealDisplay.name === data.name){
            var tempMetadata = [...genesisMetadata];
            tempMetadata[0] = item.metadata;
            setGenesisMetadata(tempMetadata);

            var overall = overall = (parseInt(item.metadata.Mental) + parseInt(item.metadata.Luck) + 
            parseInt(item.metadata["Physical Stamina"]) + parseInt(item.metadata.Teamwork))/4;
            var tempOverall = [...genesisOverall];
            tempOverall[0] = overall;
            setGenesisOverall(tempOverall);
          }
        });

      }else if(genesisID[1] === data.nftId){
        setGenesis(prev => {
          const temp = [...prev];
          temp[1] = data;

          return temp;
        });

        setGensisNum(prev => {
          const temp = [...prev];          
          const str = data.name.split('#');
          temp[1] = str[1];

          return temp;
        });

        Genesis_Metadata.forEach((item) => {
          if(item.revealDisplay.name === data.name){
            var tempMetadata = [...genesisMetadata];
            tempMetadata[1] = item.metadata;
            setGenesisMetadata(tempMetadata);

            var overall = overall = (parseInt(item.metadata.Mental) + parseInt(item.metadata.Luck) + 
            parseInt(item.metadata["Physical Stamina"]) + parseInt(item.metadata.Teamwork))/4;
            var tempOverall = [...genesisOverall];
            tempOverall[1] = overall;
            setGenesisOverall(tempOverall);
          }
        });

      }else if(genesisID[2] === data.nftId){
        setGenesis(prev => {
          const temp = [...prev];
          temp[2] = data;

          return temp;
        });

        setGensisNum(prev => {
          const temp = [...prev];          
          const str = data.name.split('#');
          temp[2] = str[1];

          return temp;
        });

        Genesis_Metadata.forEach((item) => {
          if(item.revealDisplay.name === data.name){
            var tempMetadata = [...genesisMetadata];
            tempMetadata[2] = item.metadata;
            setGenesisMetadata(tempMetadata);

            var overall = overall = (parseInt(item.metadata.Mental) + parseInt(item.metadata.Luck) + 
            parseInt(item.metadata["Physical Stamina"]) + parseInt(item.metadata.Teamwork))/4;
            var tempOverall = [...genesisOverall];
            tempOverall[2] = overall;
            setGenesisOverall(tempOverall);
          }
        });

      }else if(genesisID[3] === data.nftId){
        setGenesis(prev => {
          const temp = [...prev];
          temp[3] = data;

          return temp;
        });

        setGensisNum(prev => {
          const temp = [...prev];          
          const str = data.name.split('#');
          temp[3] = str[1];

          return temp;
        });

        Genesis_Metadata.forEach((item) => {
          if(item.revealDisplay.name === data.name){
            var tempMetadata = [...genesisMetadata];
            tempMetadata[3] = item.metadata;
            setGenesisMetadata(tempMetadata);

            var overall = overall = (parseInt(item.metadata.Mental) + parseInt(item.metadata.Luck) + 
            parseInt(item.metadata["Physical Stamina"]) + parseInt(item.metadata.Teamwork))/4;
            var tempOverall = [...genesisOverall];
            tempOverall[3] = overall;
            setGenesisOverall(tempOverall);
          }
        });

      }
    });
  }, [genesisID]);

  useEffect(() => {
    if(rentalID === "") setRentalItem(null);

    rental.forEach((data) => {
      if(data.nftId === rentalID) setRentalItem(data)
    })
  }, [rentalID]);

  useEffect(() => {
    if(turboGenesisID[0] === ""){
      setTurboGenesis(prev => {
        const temp = [...prev];
        temp[0] = null;

        return temp;
      });
    }else if(turboGenesisID[1] === ""){
      setTurboGenesis(prev => {
        const temp = [...prev];
        temp[1] = null;

        return temp;
      });
    }

    nft.map((data) => {
      if(turboGenesisID[0] === data.nftId){
        setTurboGenesis(prev => {
          const temp = [...prev];
          temp[0] = data;

          return temp;
        });

        setTurboGensisNum(prev => {
          const temp = [...prev];          
          const str = data.name.split('#');
          temp[0] = str[1];

          return temp;
        });

        Genesis_Metadata.forEach((item) => {
          if(item.revealDisplay.name === data.name){
            var tempMetadata = [...turboGenesisMetadata];
            tempMetadata[0] = item.metadata;
            setTurboGenesisMetadata(tempMetadata);

            var overall = overall = (parseInt(item.metadata.Mental) + parseInt(item.metadata.Luck) + 
            parseInt(item.metadata["Physical Stamina"]) + parseInt(item.metadata.Teamwork))/4;
            var tempOverall = [...turboGenesisOverall];
            tempOverall[0] = overall;
            setTurboGenesisOverall(tempOverall);
          }
        });

      }else if(turboGenesisID[1] === data.nftId){
        setTurboGenesis(prev => {
          const temp = [...prev];
          temp[1] = data;

          return temp;
        });

        setTurboGensisNum(prev => {
          const temp = [...prev];          
          const str = data.name.split('#');
          temp[1] = str[1];

          return temp;
        });

        Genesis_Metadata.forEach((item) => {
          if(item.revealDisplay.name === data.name){
            var tempMetadata = [...turboGenesisMetadata];
            tempMetadata[1] = item.metadata;
            setTurboGenesisMetadata(tempMetadata);

            var overall = overall = (parseInt(item.metadata.Mental) + parseInt(item.metadata.Luck) + 
            parseInt(item.metadata["Physical Stamina"]) + parseInt(item.metadata.Teamwork))/4;
            var tempOverall = [...turboGenesisOverall];
            tempOverall[1] = overall;
            setTurboGenesisOverall(tempOverall);
          }
        });

      }
    });
  }, [turboGenesisID]);

  const getNFTs = async () => {
    if (user.loggedIn === null) return;

    const res = await fcl.query({
      cadence: getAllNFTs,
      args: (arg, t) => [arg(user?.addr, t.Address)],
      // args: (arg, t) => [arg("0x75e139bedd21d37f", t.Address)],
    }).then((res) => {
      // console.log('NFT - ', res);
      setNFT(res);

      if(formData.length > 0){
        setEmail(formData[2]);
        setDiscord(formData[3]);
        setTwitter(formData[4]);
        setInstagram(formData[5]);
        
        var driverzID = [formData[7], formData[15], formData[23], formData[31]];
        setGenesisID(driverzID);    
        var driverzName = [formData[8], formData[16], formData[24], formData[32]];
        setGenesisName(driverzName);

        var tempCarClubID = [formData[39], formData[44], formData[49], formData[54]];
        setCarClubID(tempCarClubID);

        var turboDriverzID = [formData[59], formData[67]];
        setTurboGenesisID(turboDriverzID);
        var turboDriverzName = [formData[60], formData[68]];
        setTurboGenesisName(turboDriverzName);

        // setSelectedFile(formData[75]);
        // setTurboSelectedFile(formData[76]);

        // console.log(formData);
      }

    });

    const rental = await fcl.query({
      cadence: getAllNFTs,
      args: (arg, t) => [arg("0x75e139bedd21d37f", t.Address)],
    });

    // console.log("rental - ", rental);
    setRental(rental);
  };

  // Set team logo
  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(selectedFile);
      return;
    }

    console.log(e.target.files);

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  }
  const openFile = () => {
    const uploadFile = document.querySelector("#uploadFile");
    uploadFile.click();
  }

  // Set Turbo team logo
  const onTurboSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setTurboSelectedFile(turboSelectedFile);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setTurboSelectedFile(e.target.files[0]);
  }
  const openTurboFile = () => {
    const uploadFile = document.querySelector("#turbo-uploadFile");
    uploadFile.click();
  }

  const genesisNameChange = (id, name) => {
    if(id === 1){
      var tempName = [...genesisName];
      tempName[0] = name;
      setGenesisName(tempName);
    }else if(id === 2){
      var tempName = [...genesisName];
      tempName[1] = name;
      setGenesisName(tempName);
    }else if(id === 3){
      var tempName = [...genesisName];
      tempName[2] = name;
      setGenesisName(tempName);
    }else if(id === 4){
      var tempName = [...genesisName];
      tempName[3] = name;
      setGenesisName(tempName);
    }
  }

  const turboGenesisNameChange = (id, name) => {
    if(id === 1){
      var tempName = [...turboGenesisName];
      tempName[0] = name;
      setTurboGenesisName(tempName);
    }else if(id === 2){
      var tempName = [...turboGenesisName];
      tempName[1] = name;
      setTurboGenesisName(tempName);
    }else if(id === 3){
      var tempName = [...turboGenesisName];
      tempName[2] = name;
      setTurboGenesisName(tempName);
    }else if(id === 4){
      var tempName = [...turboGenesisName];
      tempName[3] = name;
      setTurboGenesisName(tempName);
    }
  }

  // Driverz modal
  const handleClose = () => setShow(false);
  const handleShow = (sequence) => {
    setGenesisSequence(sequence);
    setShow(true);
  }
  // Car Club modal
  const handleCarClubClose = () => setCarClubShow(false);
  const handleCarClubShow = (sequence) =>{
    setCarClubSequence(sequence);
    setCarClubShow(true);
  }
  // Rental modal
  const handleRentalClose = () => setRentalShow(false);
  const handleRentalShow = () => setRentalShow(true);

  // Turbo Driverz modal
  const handleTurboClose = () => setTurboShow(false);
  const handleTurboShow = (sequence) => {
    setTurboGenesisSequence(sequence);
    setTurboShow(true);
  }

  carClubContent =
    <>
      {nft.map((data, index) => {
        if (data.nftType === "Car Club") {
          return (
            <RacingCarItem key={index} data={data} carClubID={carClubID} 
              setCarClubID={setCarClubID} carClubSequence={carClubSequence}>
            </RacingCarItem>
          )
        }
      })}
    </>

  genesisContent =
    <>
      {nft.map((data, index) => {
        if (data.nftType === "Genesis") {
          return (
            <RacingGenesisItem key={index} data={data} genesisID={genesisID}
            setGenesisID={setGenesisID} genesisSequence={genesisSequence}>
            </RacingGenesisItem>
          )
        }
      })}
    </>
  
  rentalContent =
    <>
      {rental.map((data, index) => {
        if (data.nftType === "Genesis") {
          return (
            <RacingRentalItem key={index} data={data} rentalID={rentalID} setRentalID={setRentalID} >
            </RacingRentalItem>
          )
        }
      })}
    </>

  turboGenesisContent =
  <>
    {nft.map((data, index) => {
      if (data.nftType === "Genesis") {
        return (
          <TurboGenesisItem key={index} data={data} turboGenesisID={turboGenesisID}
          setTurboGenesisID={setTurboGenesisID} turboGenesisSequence={turboGenesisSequence}>
          </TurboGenesisItem>
        )
      }
    })}
  </>

  return (
    <div className="driverz-page mt-5">
      <div className='heading-row'>
        <h1>Racing Simulation Entry Form</h1>
      </div>

      <div className='intro-text'>
        <p className="text-white">
        Complete the form below to participate in our Driverz Racing League and Driverz Turbo Simulations. 
        Please note that only 1 Genesis Driverz is required to participate in both formats. You can enter a maximum 
        of up to 6 Driverz (4 DRL/2 Turbo) and a maximum of up to 4 Car Club upgrades (optional add-on for DRL) into 
        the form today. Your entries will replace any information we have on file for you already once you click the 
        ‘Submit Form’ button at the bottom. We will lock in Driverz chosen, assuming they still meet the necessary 
        criteria needed for the new race, on the 3rd of the each month.
        <br/><br/>
        Please note that a bank rental can be used as an optional addition to your team, but will reduce any prizes 
        won during our sims based on your position finished. That said, only borrow from the bank if you need a 
        teammate to fill a hole in your squad! Specific details on monthly races will be found on our Upcoming Events 
        calendar, and in our Discord Simulation channel. Good luck in the races!
        </p>
      </div>

      <Form className=" form-settings mb-5">
        <Form.Group className="mb-3 form-group" controlId="formBasicEmail">
          <div className='form-row'>
            <div className='form-label'>
              <Form.Label className='text-white'>Email *</Form.Label>
            </div>
          </div>
          <div className='form-row'>
            <div className='form-control'>
              <Form.Control 
                type="email" 
                placeholder="Enter email" 
                required 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-text'>
              <Form.Text className="text-muted">
                Driverz will never share your personal information with anyone else.
              </Form.Text>
            </div>
          </div>
        </Form.Group>

        <Form.Group className="mb-3 form-group-2" controlId="formBasicEmail">
          <div className='form-row'>
            <div className='form-label'>
            <Form.Label className='text-white'>
              Discord (+ #ID)
            </Form.Label>
            </div>
          </div>
          <div className='form-row'>
            <div className='form-control'>
              <Form.Control 
                type="text" 
                placeholder="Enter Discord ID" 
                value={discord} 
                onChange={(e) => setDiscord(e.target.value)} 
              />
            </div>
          </div>
        </Form.Group>

        <Form.Group className="mb-3 form-group-2" controlId="formBasicEmail">
        <div className='form-row'>
            <div className='form-label'>
              <Form.Label className='text-white'>
                Twitter ID
              </Form.Label>
            </div>
          </div>
          <div className='form-row'>
            <div className='form-control'>
              <Form.Control 
                type="text" 
                placeholder="Enter Twitter" 
                value={twitter} 
                onChange={(e) => setTwitter(e.target.value)} 
              />
            </div>
          </div>
        </Form.Group>

        <Form.Group className="mb-3 form-group-2" controlId="formBasicEmail">
        <div className='form-row'>
          <div className='form-label'>
            <Form.Label className='text-white'>
              Instagram ID
            </Form.Label>
          </div>
        </div>
        <div className='form-row'>
          <div className='form-control'>
            <Form.Control 
              type="text" 
              placeholder="Enter Instagram" 
              value={instagram} 
              onChange={(e) => setInstagram(e.target.value)} 
            />
          </div>
        </div>              
        </Form.Group>

        <Form.Group className="mb-3 text-white form-group-1" controlId="formBasicCheckbox">
          <div className='form-row'>
            <div className='form-check'>
              <Form.Check 
                type="checkbox" 
                checked 
                readOnly
                label="We can contact you via email with project updates and current events for our holders." 
              />
            </div>
          </div>
        </Form.Group>
      </Form>

      {/* DRL Section  */}
      
      <h4 className='text-white-2 mb-0 mt-5'>DRL</h4>

      <div className='d-flex'>
        <h5 className='text-gray mt-4 me-3'>
          BUILD YOUR TEAM
        </h5>
      </div>

      <div className='d-flex'>
        <h5 className='text-gray-spec mt-4 me-3'>
          Genesis Driverz
        </h5>
      </div>

      <div className='row'>
        <div className='col-md-3 mt-5'>
          <h5 className='text-white'>TEAM LOGO</h5>

          <input id="uploadFile" className='d-none team-logo' type='file' onChange={(e) => onSelectFile(e)} />
          <div className='team-logo-pad' onClick={() => openFile()}>
            {isLogoVideo ?
            <video width="100%" height="auto" controls autoPlay loop>
              <source src={teamLogo} type="video/mp4" />
            </video>
            :
            <img src={teamLogo} width="100%" height="auto" />
            }            
          </div>
{/*
          <h5 className='text-white text-end mt-3'>DRIVERZ ID</h5>
          <h5 className='text-white text-end mt-3'>DRIVERZ NAME</h5> */}
        </div>

        <div className='col-md-2 mt-5'>
          <div className='row-1'>
            <h5 className='text-white row-1'>DRIVERZ 1</h5>
          </div>
          <div className='row-2'>
            <div className='driverz-pad mint-button' onClick={() => handleShow(1)}>
              {genesis[0] === null ?
                <div className='plus'>+</div>
              :
                <img className='carclub-img' src={genesis[0].thumbnail} width="100%" height="auto" />
              }
            </div>
          </div>
          <div className='row-3'>
            {genesisNum[0] === 0 ?
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" />
            :
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" 
              value={genesisNum[0]} readOnly/>
            }
          </div>
            <div className='row-4'>
            <Form.Control 
              className='mt-3' 
              type="text" 
              placeholder="Name" 
              value={genesisName[0]}
              onChange={(e) => genesisNameChange(1, e.target.value)} 
            /> 
          </div>
        </div>

        <div className='col-md-2 mt-5'>
          <div className='row-1'>
            <h5 className='text-white'>DRIVERZ 2</h5>
          </div>
          <div className='row-2'>
            <div className='driverz-pad mint-button' onClick={() => handleShow(2)}>
              {genesis[1] === null ?
                <div className='plus'>+</div>
              :
                <img className='carclub-img' src={genesis[1].thumbnail} width="100%" height="auto" />
              }
            </div>
          </div>
          <div className='row-3'>
            {genesisID[1] === 0 ?
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" />
            :
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" 
              value={genesisID[1]} onChange={(e) => e.preventDefault()} />
            }
          </div>
          <div className='row-4'>
            <Form.Control 
              className='mt-3' 
              type="text" 
              placeholder="Name" 
              value={genesisName[1]}
              onChange={(e) => genesisNameChange(2, e.target.value)}
            />
          </div>
        </div>

        <div className='col-md-2 mt-5'>
          <div className="row-1">
            <h5 className='text-white'>DRIVERZ 3</h5>
          </div>
          <div className='row-2'>
            <div className='driverz-pad mint-button' onClick={() => handleShow(3)}>
              {genesis[2] === null ?
                <div className='plus'>+</div>
              :
                <img className='carclub-img' src={genesis[2].thumbnail} width="100%" height="auto" />
              }
            </div>
          </div>

          <div className='row-3'>
            {genesisID[2] === 0 ?
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" />
            :
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" 
              value={genesisID[2]} onChange={(e) => e.preventDefault()} />
            }
          </div>
          <div className='row-4'>
            <Form.Control 
              className='mt-3' 
              type="text" 
              placeholder="Name" 
              value={genesisName[2]}
              onChange={(e) => genesisNameChange(3, e.target.value)}
            />
          </div>
        </div>

        <div className='col-md-2 mt-5'>
          <div className='row-1'>
            <h5 className='text-white'>DRIVERZ 4</h5>
          </div>
          <div className='row-2'>
            <div className='driverz-pad mint-button' onClick={() => handleShow(4)}>
              {genesis[3] === null ?
                <div className='plus'>+</div>
              :
                <img className='carclub-img' src={genesis[3].thumbnail} width="100%" height="auto" />
              }
            </div>
          </div>
          
          <div className='row-3'>
            {genesisID[3] === 0 ?
            <Form.Control className='mt-3 mint-button' type="text" placeholder="Driverz ID" />
            :
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" 
              value={genesisID[3]} onChange={(e) => e.preventDefault()} />
            }
          </div>
          <div className='row-4'>
            <Form.Control 
              className='mt-3' 
              type="text" 
              placeholder="Name" 
              value={genesisName[3]}
              onChange={(e) => genesisNameChange(4, e.target.value)}
            />
          </div>
        </div>

        <div className='col-md-2 mt-5'>
          <div className='row-1'>
            <h5 className='text-white'>RENTAL BANK</h5>
          </div>
          <div className='row-2'>
            <div className='driverz-pad mint-button' onClick={() => handleRentalShow()}>
              {rentalItem === null ?
                <div className='plus'>+</div>
              :
                <img className='carclub-img' src={rentalItem.thumbnail} width="100%" height="auto" />
              }
            </div>
          </div>

          <div className='row-3'>
            {rentalID === 0 ?
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" />
            :
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" 
              value={rentalID} onChange={(e) => e.preventDefault()}/>
            }
          </div>

          <div className='row-4'>
            <Form.Control 
              className='mt-3' 
              type="text" 
              placeholder="Name" 
            /> 
          </div>         
        </div>
      </div>

      <div className='d-flex'>
        <h5 className='text-gray-spec mt-4 me-3'>
          Car Club Collectibles
        </h5>
      </div>

      <div className='row mb-5'>
        {/*<div className='col-md-2 mt-5'>          
          <h5 className='text-white text-end'>CAR CLUB</h5>         
          </div>*/}

        <div className='col-md-4 pt-5'>
        <div className='row-1'>
            <h5 className='text-white'>Car Club 1</h5>
          </div>
          <div className='row-2'>
            <div className='carclub-pad mint-button' onClick={() => handleCarClubShow(1)}>
              {carClubImg[0] === '' ?
                <div className='plus-spec'>+</div>
              :
              <>
              {carClubImg[0].includes("mp4") ?
                <video width="100%" height="auto" controls autoPlay loop>
                  <source src={carClubImg[0]} type="video/mp4" />
                </video>
              :
                <img className='carclub-img' src={carClubImg[0]} width="100%" height="auto" />
              }
              </>              
              }         
            </div>
          </div>
          <div className='row-3'>
            {carClubID[0] === 0 ?
            <Form.Control className='mt-2' type="text" placeholder="Car Club ID" />
            :
            <Form.Control className='mt-2' type="text" placeholder="Car Club ID" 
              value={carClubID[0]} onChange={(e) => e.preventDefault()} />
            }
          </div>
        </div>

        <div className='col-md-4 mt-5'>
          <div className='row-1'>
            <h5 className='text-white'>Car Club 2</h5>
          </div>
          <div className='row-2'>
            <div className='carclub-pad mint-button' onClick={() => handleCarClubShow(2)}>
              {carClubImg[1] === '' ?
                <div className='plus-spec '>+</div>
              :
              <>
              {carClubImg[1].includes("mp4") ?
                <video width="100%" height="auto" controls autoPlay loop>
                  <source src={carClubImg[1]} type="video/mp4" />
                </video>
              :
                <img className='carclub-img' src={carClubImg[1]} width="100%" height="auto" />
              }
              </>
              }            
            </div>
          </div>
          <div className='row-3'>
            {carClubID[1] === 0 ?
            <Form.Control className='mt-2' type="text" placeholder="Car Club ID" />
            :
            <Form.Control className='mt-2' type="text" placeholder="Car Club ID" 
              value={carClubID[1]} onChange={(e) => e.preventDefault()} />
            }
          </div>
        </div>

        <div className='col-md-4 mt-5'>
          <div className='row-1'>
            <h5 className='text-white'>Car Club 3</h5>
          </div>
          <div className='row-2'>
            <div className='carclub-pad mint-button' onClick={() => handleCarClubShow(3)}>
              {carClubImg[2] === '' ?
                <div className='plus-spec'>+</div>
              :
              <>
              {carClubImg[2].includes("mp4") ?
                <video width="100%" height="auto" controls autoPlay loop>
                  <source src={carClubImg[2]} type="video/mp4" />
                </video>
              :
                <img className='carclub-img' src={carClubImg[2]} width="100%" height="auto" />
              }
              </>
              }            
            </div>
          </div>
          <div className='row-3'>
            {carClubID[2] === 0 ?
            <Form.Control className='mt-2' type="text" placeholder="Car Club ID" />
            :
            <Form.Control className='mt-2' type="text" placeholder="Car Club ID" 
              value={carClubID[2]} onChange={(e) => e.preventDefault()} />
            }
          </div>
        </div>

        <div className='col-md-4 mt-5'>
          <div className='row-1'>
            <h5 className='text-white'>Car Club 3</h5>
          </div>
          <div className='row-2'>
            <div className='carclub-pad mint-button' onClick={() => handleCarClubShow(4)}>
              {carClubImg[3] === '' ?
                <div className='plus-spec'>+</div>
              :
              <>
              {carClubImg[3].includes("mp4") ?
                <video width="100%" height="auto" controls autoPlay loop>
                  <source src={carClubImg[3]} type="video/mp4" />
                </video>
              :
                <img className='carclub-img' src={carClubImg[3]} width="100%" height="auto" />
              }
              </>
              }            
            </div>
          </div>
          <div className='row-3'>
            {carClubID[3] === 0 ?
            <Form.Control className='mt-2' type="text" placeholder="Car Club ID" />
            :
            <Form.Control className='mt-2' type="text" placeholder="Car Club ID" 
              value={carClubID[3]} onChange={(e) => e.preventDefault()} />
            }
          </div>
        </div>
      </div>
      {/* DRL Section  */}

      {/* Turbo Section  */}
 
      <h4 className='text-white-2 mb-0 mt-5'>Turbo</h4>

      <div className='d-flex'>
        <h5 className='text-gray mt-4 me-3'>
          BUILD YOUR TEAM
        </h5>
      </div>

      <div className='row mb-5'>
        <div className='col-md-3 mt-5'>
          <h5 className='text-white'>TEAM LOGO</h5>

          <input id="turbo-uploadFile" className='d-none team-logo' type='file' onChange={(e) => onTurboSelectFile(e)} />
          <div className='team-logo-pad' onClick={() => openTurboFile()}>
            {isTurboLogoVideo ?
            <video width="100%" height="auto" controls autoPlay loop>
              <source src={turboTeamLogo} type="video/mp4" />
            </video>
            :
            <img src={turboTeamLogo} width="100%" height="auto" />
            }  
          </div>
{/*
          <h5 className='text-white text-end mt-4'>DRIVERZ ID</h5>
          <h5 className='text-white text-end mt-3'>DRIVERZ NAME</h5>*/}
        </div>

        <div className='col-md-2 mt-5'>
          <div className='row-1'>
            <h5 className='text-white'>DRIVERZ 1</h5>
          </div>
          <div className='row-2'>
            <div className='driverz-pad mint-button' onClick={() => handleTurboShow(1)}>
              {turboGenesis[0] === null ?
                <div className='plus'>+</div>
              :
                <img className='carclub-img' src={turboGenesis[0].thumbnail} width="100%" height="auto" />
              }
            </div>
          </div>
          <div className='row-3'>
            {turboGenesisNum[0] === 0 ?
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" />
            :
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" 
              value={turboGenesisNum[0]} onChange={(e) => e.preventDefault()} />
            }
          </div>
          <div className='row-4'>
            <Form.Control 
              className='mt-3' 
              type="text" 
              placeholder="Name" 
              value={turboGenesisName[0]}
              onChange={(e) => turboGenesisNameChange(1, e.target.value)} 
            />    
          </div>      
        </div>

        <div className='col-md-2 mt-5'>
          <div className='row-1'>
            <h5 className='text-white'>DRIVERZ 2</h5>
          </div>
          <div className='row-2'>
            <div className='driverz-pad mint-button' onClick={() => handleTurboShow(2)}>
              {turboGenesis[1] === null ?
                <div className='plus'>+</div>
              :
                <img className='carclub-img' src={turboGenesis[1].thumbnail} width="100%" height="auto" />
              }
            </div>
          </div>
          <div className='row-3'>
            {turboGenesisNum[1] === 0 ?
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" />
            :
            <Form.Control className='mt-3' type="text" placeholder="Driverz ID" 
              value={turboGenesisNum[1]} onChange={(e) => e.preventDefault()} />
            }
          </div>
          <div className='row-4'>
          <Form.Control 
            className='mt-3' 
            type="text" 
            placeholder="Name" 
            value={turboGenesisName[1]}
            onChange={(e) => turboGenesisNameChange(2, e.target.value)} 
          />
          </div>
        </div>
      </div>
      {/* Turbo Section  */}

      {user.loggedIn === null || email === ""  || (genesis[0] === null && genesis[1] === null && genesis[2] === null && genesis[3] === null)
        || (turboGenesis[0] === null && turboGenesis[1] === null)  ?
      <Button className='mb-5' size="lg" variant="primary" type="submit" disabled>
        Submit Form
      </Button>      
      :
      <Button className='mb-5' size="lg" variant="primary" type="submit" onClick={submitForm}>
        Submit Form
      </Button>
      }
      <ToastContainer />

      {/* <CSVLink data={csv}>Download CSV</CSVLink> */}

      {/* DRL Modals  */}
      <Modal className="modal-container" size="xl" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-white'>Select Driverz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {user.loggedIn ?
          <div className="modal-row">
            {genesisContent}
          </div>
          :
          <p className='text-white text-center mt-5 mb-5'>
            Sign in to see your Driverz collectibles
          </p>
          }
        </Modal.Body>
      </Modal>
      <Modal className="modal-container" size="xl" show={carClubShow} onHide={handleCarClubClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-white'>Select Car Club</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {user.loggedIn ?
          <div className="modal-row">
            {carClubContent}
          </div>
          :
          <p className='text-white text-center mt-5 mb-5'>
            Sign in to see your Car Club collectibles
          </p>
          }
        </Modal.Body>
      </Modal>
      <Modal className="modal-container" size="xl" show={rentalShow} onHide={handleRentalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-white'>Select Driverz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row">
            {rentalContent}
          </div>
        </Modal.Body>
      </Modal>
      {/* DRL Modals  */}
      
      {/* Turbo Modals  */}
      <Modal className='modal-container' size="xl" show={turboShow} onHide={handleTurboClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-white'>Select Driverz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {user.loggedIn ?
          <div className="modal-row">
            {turboGenesisContent}
          </div>
          :
          <p className='text-white text-center mt-5 mb-5'>
            Sign in to see your Driverz collectibles
          </p>
          }
        </Modal.Body>
      </Modal>
      {/* Turbo Modals  */}

    </div>
  )
}