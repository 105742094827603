export const initHelmet = `
  import Helmet from 0xf887ece39166906e
  import NonFungibleToken from 0x1d7e57aa55817448 

  transaction(){
    prepare(signer: AuthAccount){
      let helmetCap = signer.getCapability<&{NonFungibleToken.CollectionPublic, Helmet.CollectionPublic}>(Helmet.CollectionPublicPath)

      if !helmetCap.check(){
        signer.save(<-Helmet.createEmptyCollection(), to: Helmet.CollectionStoragePath)
        signer.link<&Helmet.Collection{NonFungibleToken.CollectionPublic, Helmet.CollectionPublic}>(Helmet.CollectionPublicPath, target: Helmet.CollectionStoragePath)
      }
    }
  }
`