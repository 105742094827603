import React from 'react';
import './Terms.css';

const Terms = () => (
  <div className="terms-container" >
    <h1 className="terms-header" >
      Terms & Conditions/Privacy Policy
    </h1>
    <p className="terms-paragraph" >
      Driverz Inc. is a registered trademark over which holds exclusive exploitation rights.<br/><br/>

      Any reproduction, use or affixing of said brand, without the prior authorization of Driverz Inc. is
      prohibited, in accordance with the provisions of article L713-2 of the Intellectual Property
      Code.<br/><br/>

      It is recalled that the photographs present on the Site are successful as works of the mind
      benefiting from protection under copyright. Any unauthorized use of one or other of the
      photographs constitutes an act of counterfeiting as provided for in article L335-3 of the
      Intellectual Property Code.<br/><br/>

      In general, the data, programs, texts, information, logos, visual identities, animated or still
      images and their formatting appearing on the Site are the property of Driverz Inc. and are
      protected as such by the provisions of the Intellectual Property Code.<br/><br/>

      All Internet users undertake not to use them and not to allow anyone to use these contents for
      illegal purposes.<br/><br/>

      Any representation or reproduction, total or partial, permanent or temporary, on a computer
      and/or paper medium, and by any process whatsoever, of one or other of the elements of the
      site or the services offered, without prior and express agreement of Driverz Inc. is prohibited,
      and constitutes an act of counterfeiting, which may result in civil and/or criminal penalties.<br/><br/>
      
      Only paper printing is authorized for the purposes of private copying for the exclusive use of the
      copyist within the meaning of the article of the Intellectual Property Code. <br/><br/>
    </p>
  </div>
)

export default Terms;
