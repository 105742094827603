import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet'
import * as fcl from "@onflow/fcl";
import { Card, Button, Alert, InputGroup, Form } from "react-bootstrap"
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import './CarClubMintPage.css';
import bckgd from '../../assets/Images/Car_Club.jpg';
import carClub1 from '../../assets/Images/CarClub1.png';
import carClub2 from '../../assets/Images/CarClub2.png';
import cClub_rare_1 from '../../assets/Videos/5181.mp4';
import cClub_3 from '../../assets/Images/2151.png';
import cClub4 from '../../assets/Images/carClub4888.png';
import starterPack from '../../assets/PackVideos/Starter_Pack.mp4'
import pitcrewPack from '../../assets/PackVideos/Pit_Crew_Pack.mp4'
import toolboxBundle from '../../assets/PackVideos/Toolbox_Bundle_Pack.mp4'
import singleCC from '../../assets/Images/singleCCSale.png';
import packCC from '../../assets/Images/packCCSale.png';

import { checkFlowBalance} from '../../Cadence/Scripts/checkFlowBalance';
import { getCCListingDetails } from "../../Cadence/Scripts/getCCListingDetails";
import { checkCarClub } from "../../Cadence/carclub/checkCarClub"; //fixed imports
import { initCarClub } from "../../Cadence/carclub/initCarClub"; //done
import { multiPurchaseCarClub } from "../../Cadence/carclub/multiPurchaseCarClub"; //done
import { getListingId } from "../../Cadence/carclub/getListingId"; //done
import { unlinkAccount } from "../../Cadence/carclub/unlinkAccount"; //done
import { purchaseCC } from "../../Cadence/Transactions/purchaseCC";

import { getListingInsurance } from "../../Cadence/insurance/getListingInsurance"; //done
import { purchaseInsurance } from "../../Cadence/insurance/purchaseInsurance"; // done
import { checkOwnInsurance } from "../../Cadence/insurance/checkOwnInsurance"; //done

import { initAll } from '../../Cadence/Transactions/initAll';
import { purchaseCarClubListing } from "../../Cadence/Vroom/transactions/purchaseCarClubListing";
import { getCCPurchaseData } from '../../Cadence/Vroom/scripts/getCCPurchaseData';


export default function Mint() {
  const [user, setUser] = useState({ loggedIn: null });
  const [userAddress, setUserAddress] = useState(null);
  const [isCarClubInit, setIsCarClubInit] = useState(false);
  const [listedNFT, setListedNFT] = useState([]);
  const [mintCount, setMintCount] = useState(1);
  const [listedInsurance, setListedInsurance] = useState([]);
  const [ownInsurance, setOwnInsurance] = useState(false);
  const [ownGenesis, setOwnGenesis] = useState(false);
  const [soldOut, setSoldOut] = useState(false);
  const [SPListings, setSPListings] = useState([]);
  const [PCListings, setPCListings] = useState([]);
  const [TBListings, setTBListings] = useState([]);
  const [listings4070, setListings4070] = useState([]);
  const [listings12100, setListings12100] = useState([]);
  const [isSoldOut4070, setIsSoldOut4070] = useState(false);
  const [isSoldOut12100, setIsSoldOut12100] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [bal, setBal] = useState(null);
  const [singlePurchases, setSinglePurchases] = useState(0);
  const [packPurchases, setPackPurchases] = useState(0);


  const [isProcessing10, setIsProcessing10] = useState(false);
  const [isProcessing11, setIsProcessing11] = useState(false);

  const [dots, setDots] =useState("")

  useEffect(() => {
    fcl.currentUser.subscribe(setUser);
  }, []);

  useEffect(() => {
    // checkCarClubInitialized();
    getListingIdFunc();
    getListingInsuranceFunc();
    checkOwnInsuranceFunc();
    checkOwnGenesisFunc();
    getFlowBalance();
    checkListingsStatus();
  }, [user]);


  const checkListingsStatus = () => {
    // Check if either of the listings arrays are empty and set sold out flags accordingly
    setIsSoldOut4070(listings4070.length === 0);
    setIsSoldOut12100(listings12100.length === 0);
  };

  const getFlowBalance = async () => {
    if (user.loggedIn === null) return;
    console.log("Fetching VROOM balance for:", user?.addr);
    const bal = await fcl.query({
      cadence: checkFlowBalance,
      args: (arg, t) => [arg(user?.addr, t.Address)]
    });
    setBal(Number(bal).toFixed(2));
    console.log("New VROOM balance:", bal);
  };

  const getListingIdFunc = async () => {
    const res = await fcl.query({
      cadence: getListingId,
      args: (arg, t) => [arg("0xf887ece39166906e", t.Address)],
      limit: 9999
    });
    setListedNFT(res);
    // console.log(res.length);
  }
  const getListingInsuranceFunc = async () => {
    const res = await fcl.query({
      cadence: getListingInsurance,
      args: (arg, t) => [arg("0xf887ece39166906e", t.Address)],
    });

    const listings = res ?? [];

    const remainingListings = listings.length - 299;
    const limitedListings = remainingListings > 0 ? listings.slice(0, remainingListings) : [];

    setListedInsurance(limitedListings);
    console.log(limitedListings.length, limitedListings);
    setSoldOut(remainingListings <= 0);
  }

  const checkCarClubInitialized = async () => {
    const currentUser = await fcl.currentUser().snapshot();
    if (currentUser.addr != null) {
      const res = await fcl.query({
        cadence: checkCarClub,
        args: (arg, t) => [arg(currentUser?.addr, t.Address)],
      });

      // console.log('init car club - ', res);
      setIsCarClubInit(res);
    }
  };
  const checkOwnInsuranceFunc = async () => {
    const currentUser = await fcl.currentUser().snapshot();
    
    if (currentUser.addr != null) {
      const res = await fcl.query({
        cadence: checkOwnInsurance,
        args: (arg, t) => [arg(currentUser?.addr, t.Address)],
      });

      setOwnInsurance(res);
      console.log('own insurance - ', res);
    }
  };
  const checkOwnGenesisFunc = async () => {
    {/*const currentUser = await fcl.currentUser().snapshot();
    
    if (currentUser.addr != null) {
      const isGenesis = await fcl.query({
        cadence: checkOwnGenesis,
        args: (arg, t) => [
          arg(currentUser?.addr, t.Address)
        ],
      });*/}
      
      // console.log("own genesis - ", isGenesis);
      setOwnGenesis(true);
      console.log("Is Genesis:", ownGenesis);
    }
    
  

  const initCarClubClick = async () => {
    try {
      const txid = await fcl.mutate({
        cadence: initCarClub,
        args: (arg, t) => [],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      console.log(txid);
      toast.success("Successfully initialized for Car Club!");
      setIsCarClubInit(true);
    } catch (error) {
      // console.log("err", error);
      toast.error(error);
    }
  };

  const initAllClick = async () => {
    try {
      const init = await fcl.mutate ({
        cadence: initAll,
        args: (arg, t) => [],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      toast.success("Successfully initialized for Car Club!");
      setIsCarClubInit(true);

    } catch (error) {

    };
  };

  // console.log("listed NFT - ", listedNFT);

  const mintCarClubClick = async () => {

    var listedID = [];
    for (let i = 0; i < mintCount; i++) {
      listedID.push(listedNFT[i]);
    }

    console.log("Car Club Mint Listing: ", listedID);

    if (ownGenesis) {
      var price = parseFloat(mintCount * 37);

      try {
        const txid = await fcl.mutate({
          cadence: multiPurchaseCarClub,
          args: (arg, t) => [
            arg('0x6817fd8ae667556e', t.Address),
            arg('0xf887ece39166906e', t.Address),
            arg(listedID, t.Array(t.UInt64)),
            arg(price + ".0", t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64)//
          ],
          proposer: fcl.currentUser,
          payer: fcl.currentUser,
          authorizations: [fcl.currentUser],
          limit: 999,
        });

        console.log(txid);
        toast.success("Successfully minted Car Club!");
      } catch (error) {
        console.log("err", error);
        console.log("error.message", error.message);

        /*fetch("/log", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ error: error.message }),
          })
            .then((res) => {
              if (res.ok) {
                console.log("Error logged successfully");
              } else {
                console.log("Error logging failed:", res.status);
              }
            })
            .catch((fetchError) => {
              console.log("Error logging fetch error:", fetchError);
            });*/
        
          toast.error(error);
        }
        
    }
    
    else {
      var price = parseFloat(mintCount * 57);

      try {
        const txid = await fcl.mutate({
          cadence: multiPurchaseCarClub,
          args: (arg, t) => [
            arg('0x6817fd8ae667556e', t.Address),
            arg('0xf887ece39166906e', t.Address),
            arg(listedID, t.Array(t.UInt64)),
            arg(price + ".0", t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64) //arg(price + ".0", t.UFix64)
          ],
          proposer: fcl.currentUser,
          payer: fcl.currentUser,
          authorizations: [fcl.currentUser],
          limit: 999,
        });

        console.log(txid);
        toast.success("Successfully minted Car Club!");
      } catch (error) {
        console.log("err", error);
        console.log("error.message", error.message);

        fetch("/log", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ error: error.message }),
          })
            .then((res) => {
              if (res.ok) {
                console.log("Error logged successfully");
              } else {
                console.log("Error logging failed:", res.status);
              }
            })
            .catch((fetchError) => {
              console.log("Error logging fetch error:", fetchError);
            });
        
          toast.error(error);
        }
      
    }

  }


















  const getTBListings = async () => {
    try {
      const listings = await fcl.query({
        cadence: getCCListingDetails,
        args: (arg, t) => [
          arg("0xf887ece39166906e", t.Address)
        ],
      });

      const filtered4070 = [];
      const filtered12100 = [];

      // Iterate through each listing and filter based on conditions
      listings.forEach(listing => {
        const { purchased, nftIDs } = listing.details;

        if (!purchased) {
          if (nftIDs.length === 1) {
            filtered4070.push(listing.listingID);
          } else if (nftIDs.length === 4) {
            filtered12100.push(listing.listingID);
          }
        }
      });

      // Set state with the filtered listings
      setListings4070(filtered4070);
      setListings12100(filtered12100);

      // Check if sold out
      setIsSoldOut4070(filtered4070.length === 0);
      setIsSoldOut12100(filtered12100.length === 0);

    } catch (error) {
      console.error("Error fetching listings:", error);
    }
  };

  // Fetch the listings when the component mounts
  useEffect(() => {
    getTBListings();
  }, []);

  // Log the listings when either state changes
  useEffect(() => {
    if (listings4070.length > 0) {
      console.log("Listings with 1 NFT (4070):", listings4070);
    }

    if (listings12100.length > 0) {
      console.log("Listings with 4 NFTs (12100):", listings12100);
    }
  }, [listings4070, listings12100]);

  const purchaseToolbox = async () => {
    try {
      setIsProcessing(true);
      await getTBListings();
      const listingID = listings12100[0];
      console.log("TX ID Input: ", typeof listingID, listingID)

      const txid = await fcl.mutate({
        cadence: purchaseCC,
        args: (arg, t) => [
          arg('0x6817fd8ae667556e', t.Address),
          arg(listingID, t.UInt64),
          arg("197.0", t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64) //arg(price + ".0", t.UFix64)
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });
    } catch(error) {}
  }

  const unLinkClick = async () => {
    try {
      const txid = await fcl.mutate({
        cadence: unlinkAccount,
        args: (arg, t) => [],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      console.log(txid);
      toast.success("Successfully unlinked!");
    } catch (error) {
      console.log("err", error);
      toast.error(error);
    }
  }

  function plusClick() {
    if (mintCount < 15) {
      let count = mintCount;
      count++;
      setMintCount(count);
    }
  }
  function minusClick() {
    if (mintCount > 1) {
      let count = mintCount;
      count--;
      setMintCount(count);
    }
  }  

  const mintInsuranceClick = async () => {

    try {
      const txid = await fcl.mutate({
        cadence: purchaseInsurance,
        args: (arg, t) => [
          arg('0x6817fd8ae667556e', t.Address),
          arg('0xf887ece39166906e', t.Address),
          arg(listedInsurance[0], t.UInt64),
          arg('67.0', t.UFix64)
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      console.log(txid);
      checkOwnInsuranceFunc();
      toast.success("Successfully minted Driverz Insurance!");
    } catch (error) {
      console.log("err", error);
      toast.error(error);
    }
 
  }

  const purchase4070 = async () => {
    try {
      setIsProcessing(true);
        console.log("Attempting to purchase at 3050.0 vroom...");
        const listingID = listings4070[0];

  
        
        const txid = await fcl.mutate({
            cadence: purchaseCC,
            args: (arg, t) => [
              arg('0xf887ece39166906e', t.Address),
              arg(listingID, t.UInt64),
              arg("67.0", t.UFix64)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
  
        console.log(`Transaction ID: ${txid}`);
        await fcl.tx(txid).onceSealed();


      // Refresh listings after purchase
        await getTBListings();
        setIsProcessing(false);
        console.log("Transaction sealed.");
        
    } catch (error) {
        console.error("Error in purchase3050:", error);
        setIsProcessing(false);

        // Additional error handling can be done here
    }
  };
  
  const purchase12100 = async () => {
    try {
        setIsProcessing(true);
        await getTBListings();
        const listingID = listings12100[0];
        console.log("TX ID Input: ", typeof listingID, listingID)

        const txid = await fcl.mutate({
          cadence: purchaseCC,
          args: (arg, t) => [
            arg('0xf887ece39166906e', t.Address),
            arg(listingID, t.UInt64),
            arg("197.0", t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64) //arg(price + ".0", t.UFix64)
          ],
          proposer: fcl.currentUser,
          payer: fcl.currentUser,
          authorizations: [fcl.currentUser],
          limit: 999,
        });
    
        console.log(`Transaction ID: ${txid}`);
        await fcl.tx(txid).onceSealed();

        // Refresh listings after purchase
        await getTBListings();
        console.log("Transaction sealed.");
        setIsProcessing(false);
    } catch (error) {
        console.error("Error in purchase9000:", error);
        setIsProcessing(false);
        // Additional error handling can be done here
    }
  };
  

  return (
    <div className="container mt-5 car-club-page">

      <Helmet>
        <meta property="og:title" content="Driverz Car Club - Build Your Car Club Collection" />
        <meta property="og:description" content="Mint your exclusive Driverz Car Club collection and unlock exciting racing perks and VROOM token rewards. Join the Driverz community and start your racing journey today!" />
        <meta property="og:image" content={bckgd} />
      </Helmet>

      <div className="first-row">
        <h2>Buy Driverz Collectibles</h2>
        <h4>
          Unlock exclusive access to club perks
          <span className="mobile-hide-br"><br /></span>
          and start generating $VROOM!
        </h4>               
      </div>

      <div className="pack-row">
        <div className="pack-column-1">
          <div className="price-tag">67 $FLOW</div>
            <img src={singleCC} alt="Car Club Collectible" />
            <h3>Car Club</h3>
            <h4>Car Club Collectible</h4>
            {isProcessing ? (
                  <div className='loading-spinner'>
                    <ThreeDots color="#f0f0f0" height={80} width={80} />
                  </div>
                ) : (
              <button
                className='mint-button'
                onClick={() => {
                  if (parseFloat(bal) >= 67.0) {
                    purchase4070();
                    }
                  
                }}
                disabled={isSoldOut4070 }
              >
                {isSoldOut4070 ? 'SOLD OUT' : parseFloat(bal) >= 67.0 ? 'BUY' : 'ADD FUNDS'}
              </button>
                )}

        </div>

        <div className="pack-column-1">
          <div className="price-tag">197 $FLOW</div>
            <img src={packCC} alt="Toolbox Bundle" />
            <h3>Toolbox Bundle</h3>
            <h4>4 Car Club Collectibles</h4>
            {isProcessing ? (
                  <div className='loading-spinner'>
                    <ThreeDots color="#f0f0f0" height={80} width={80} />
                  </div>
                ) : (
                <button
                  className='mint-button'
                  onClick={() => {
                    if (parseFloat(bal) >= 197.0) {
                      purchase12100();
                    }
                  }}
                  disabled={isSoldOut12100 || packPurchases >= 1}
                >
                  {isSoldOut12100 ? 'SOLD OUT' : packPurchases >= 1 ? 'Max Purchased' : parseFloat(bal) >= 197.0 ? 'BUY' : 'ADD FUNDS'}
                </button>
            )}
        </div>
      </div> 
{/*
      <div className="pack-row">

        <div className="pack-column">
        <video autoPlay loop muted playsInline>
            <source src={cClub_rare_1}  type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <h3>Car Club <br /> $37 </h3>
          <h4>Car Club Collectible</h4>
          <button onClick={handleNullPurchase} className='mint-button'>BUY</button>
        </div>

        <div className="pack-column">
          <video autoPlay loop muted playsInline>
            <source src={starterPack}  type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <h3>Starter Pack <br />SOLD OUT</h3>
          <h4>2 Genesis <br /> 1 Car Club</h4>
          
          <button COMMENTonClick={handlePurchaseClickSP}COMMENT className="mint-button">BUY</button>
        </div>

        <div className="pack-column">
          <video autoPlay loop muted playsInline>
            <source src={pitcrewPack} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <h3>Pit Crew Pack <br />SOLD OUT</h3>
          <h4>4 Genesis</h4>
          
          <button COMMENTonClick={purchasePitCrewPack}COMMENT className="mint-button">BUY</button>
        </div>

        <div className="pack-column">
          <video autoPlay loop muted playsInline>
            <source src={toolboxBundle} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <h3>Toolbox Bundle <br /> $110</h3>
          <h4>4 Car Club</h4>
          
          <button onClick={purchaseToolbox} className="mint-button">BUY</button>
        </div>

      </div> */}

      <div className="car-club-second-row">
        <div className="left-column">
          <h3>Experience Exclusive Benefits with Car Club Ownership!</h3>
          <p>By becoming a proud owner of Car Club collectibles at Driverz Inc., you unlock incredible advantages!</p>
          <p><strong>VROOM Token:</strong> Enjoy staking rewards coming soon! Once staking is available on our platform, you can earn tokens based on how many Car Club Collectibles are in your wallet: </p>
          <ul>
            <li>Own 1+ Car Club: Unlock staking for all collectibles.</li>
            <li>Own 7+ Car Club: Earn 1.5x the token earn rate for ALL collectibles.</li>
            <li>Own 15+ Car Club: Earn 2x the token earn rate for ALL collectibles.</li>
          </ul>
          <p><strong>Thrilling Raffles and Prizes:</strong> As a valued member of our community, you'll have the chance to participate in exciting raffles and win amazing prizes. </p>

        </div>
        <div className="right-column">
          <img src={cClub4} alt='' />
        </div>
      </div>

      <div className="car-club-third-row">
        <div className="left-column">
          <img src={cClub_3} alt="" />
        </div>
        <div className="right-column">
          <h3>Driverz Car Club</h3>
          <p>Owning Driverz Car Club collectibles not only grants instant upgrades in the Driverz Racing League and access to a thrilling fantasy Formula 1 league but also unlocks the VROOM token. Exchange VROOM tokens on our website for local automotive, racing, and karting experiences! Additionally, redeem tokens for merchandise, digital collectibles, and cash rewards!<br /> <br />To start buying car club collectibles and enjoying the amazing benefits of all our Driverz collections, setup your account here.</p>
          {isCarClubInit ?
            <Alert key='success' variant='success' className="mb-0 custom-alert-text">
              Your account is already initialized.
            </Alert>
            :
            <Button variant="primary" onClick={initAllClick} className="mint-button">
              INITIALIZE
            </Button>
          }
          <ToastContainer />
        </div>
      </div>
{/*}
      <div className="fourth-row">
        <div className="left-column">
          <h3>Mint Car Club</h3>
          <p>
            Mint a Car Club for just $37! <br/>            
          </p>
          <ul>
            <li>Total Collectibles: 5555</li>
            <li>Common tires: 4700</li>
            <li>Rare special tires: 285</li>
            <li>Rare rotating AVI tires: 525</li>
            <li>Sponsor tires: 15</li>
            <li>Legendary auto components: 30</li>
          </ul>
          <p>Select the quantity of Car Clubs you'd like to mint:</p>
          <InputGroup className="mb-3 padding-up center-item">
            <Button variant="primary" id="button-addon1" onClick={minusClick} className="minus-btn big-minus-button">
              -
            </Button>
            <Form.Control
              className="mint-count big-form-control"
              aria-label="Example text with button addon"
              aria-describedby="basic-addon1"
              value={mintCount}
              readOnly
            />
            <Button variant="primary" id="button-addon1" onClick={plusClick} className="custom-sign-in-btn big-plus-button">
              +
            </Button>
          </InputGroup>
          <div className="padding-up center-item">
            {user.addr !== null ?
              <Button variant="primary" onClick={mintCarClubClick} className="mint-button">
                {ownGenesis ? 
                <>
                  MINT
                </>                      
                :
                <>
                  MINT
                </>
                }                    
              </Button>
            :
              <Button variant="primary" disabled className="mint-button">
                MINT 
              </Button>
            }
          </div>
        </div>
        <div className="right-column">
          <img src={carClub2} alt="" />
        </div>
      </div> */}
{/*}
      <div className="car-club-fifth-row">
        <div className="left-column">
          <video autoPlay loop muted playsInline>
            <source src={cClub_rare_1} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="right-column">
          <h3>Driverz Insurance:<br></br> We've Got Your Back!</h3>
          <p>As passionate automobile racing fans, we understand the unpredictable nature of the digital collectible world. That's why Driverz Insurance is here to provide you, fellow racing enthusiasts, with an extra level of confidence when minting our new set.</p>
          <ul>
            <li>Mint: Acquire your Driverz Car Club.</li>
            <li>Insurance: Add a $10 insurance card to your collection.</li>
            <li>Limited Supply: Only 99 cards available, 1 per wallet.</li>
            <li>Validity: Card remains active for 30 days.</li>
            <li>Sell Back: Return your Driverz Car Club mints to us within the coverage period, no questions asked.</li>
            <li>Refund: Receive a refund of all but $7 per mint if unsatisfied with the project.</li>
            <li>No Claim Bonus: If no claim is processed within 30 days, enjoy an airdrop of one Genesis Driverz.</li>
          </ul>
          <p>As an additional bonus, 100% of the proceeds from Driverz Insurance will send 2 owners to an IRL racing, karting, or auto experience near them! Take the next step and secure your collection with Driverz Insurance today. Click the button below to purchase your insurance and join our community of racing enthusiasts.</p>
          {soldOut ? (
            <p>Insurance Cards are sold out.</p>
          ) : (
            <>
              {user.addr !== null && !ownInsurance ? (              
                <Button variant="primary" onClick={mintInsuranceClick} className="mint-button">
                  Get Insurance
                </Button>
              ) : user.addr === null ? (
                <p>Sign in to get insured!</p>
              ) : (
                <p>Thank you for your trust in Driverz Insurance!</p>
              )}
            </>
          )}
        </div>
      </div>
              */}
      <div className="car-club-sixth-row">
        <div className="left-column">
          <h3>Starter Pack [2OG/1CC]</h3>
          <h4><em>Perfect for beginning collectors!</em></h4>
          <p>Unwrap the thrill of DRIVERZ with TWO Genesis and ONE Car Club collectible. This pack provides everything you need to get started in our simulation leagues. Build your collection and showcase your passion for cars and racing. These items can be yours in our Starter Pack for just $49!</p>
          <button variant="primary" /* onClick={handlePurchaseClickSP} */  className="mint-button">SOLD OUT</button>
        </div>
        <div className="right-column">
          <video autoPlay loop muted playsInline>
            <source src={starterPack}  type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

        <div className="car-club-seventh-row">
          <div className="left-column">
            <video autoPlay loop muted playsInline>
              <source src={pitcrewPack} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="right-column">
            <h3>Pit Crew Pack [4OG]</h3>
            <h4><em>Build your crew!</em></h4>
            <p>Rev up your collection with FOUR Genesis collectibles. This pack is designed for avid collectors who can't get enough of our exclusive Genesis collectibles with the chance to pull Captain and Team (Matching) Driverz.  Grow your squad by 4 today for only $77! </p>
            <button variant="primary"  /*onClick={purchasePitCrewPack}*/  className="mint-button">SOLD OUT</button>
          </div>
        </div>

        <div className="car-club-eigth-row">
          <div className="left-column">
            <h3>Toolbox Bundle [4CC]</h3>
            <h4><em>Upgrade your tires!</em></h4>
            <p>Hit the grid with FOUR Car Club tires! This bundle is a must-have for holders that want the most from their driverz on race day. Upgrade your Genesis Driverz and generate more $VROOM with CC Tires! Click to secure the Toolbox Bundle for your collection today for $110!</p>
            <button variant="primary"  onClick={purchaseToolbox}  className="mint-button">Get the Toolbox Bundle Now!</button>
          </div>
          <div className="right-column">
            <video autoPlay loop muted playsInline>
              <source src={toolboxBundle} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
          

      
{/*
      <div className="row mt-5 mb-5">
        

        

        <div className="col-md-4 p-2">
          <Card bg="light" key="light" text="dark" className="mb-2">/*}
            {/*<Card.Img variant="top" src="./CarClub_Collection_2.png" />*/}
{/*            <Card.Body>
              <Card.Title>
                Driverz Car Club Collection Sale
              </Card.Title>
              <Card.Text>
                Click here to buy Driverz Car Club
              </Card.Text>

              <div className="row">
                <div className="col-6">
                  <InputGroup className="mb-3">
                    <Button variant="primary" id="button-addon1" onClick={minusClick}>
                      -
                    </Button>
                    <Form.Control
                      className="mint-count"
                      aria-label="Example text with button addon"
                      aria-describedby="basic-addon1"
                      value={mintCount}
                      readOnly
                    />
                    <Button variant="primary" id="button-addon1" onClick={plusClick}>
                      +
                    </Button>
                  </InputGroup>
                </div>

                <div className="col-6">
                  {user.addr !== null ?
                    <Button variant="primary" onClick={mintCarClubClick}>
                      {ownGenesis ? 
                      <>
                        Mint {mintCount} for ${mintCount * 37}
                      </>                      
                      :
                      <>
                        Mint {mintCount} for ${mintCount * 57}
                      </>
                      }                    
                    </Button>
                  :
                    <Button variant="primary" disabled>
                      Mint {mintCount} for ${mintCount * 57}
                    </Button>
                  }
                </div>
              </div>

              {/* <Button className="mx-3" variant="primary" onClick={unLinkClick}>
                Unlink
              </Button> 
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="row mt-5 mb-5">
        <div className="col-md-6 p-2">
          {/*<img src="./Mint1.png" alt="" width="100%" height="auto" />*
        </div>
        <div className="col-md-6 p-2">
          {/*<img src="./Mint.png" alt="" width="100%" height="auto" />*
        </div>
      </div>

      {/*<h1 className="text-white text-center">
        Driverz Insurance
      </h1>
      <h3 className="text-white text-center">
        150 Policies Available
      </h3>
      <p className="text-white text-center mb-5">
        $10/Card, Max 1 Card per Holder
      </p>

      <p className="text-white">
        <b>Driverz Insurance</b>, We Got Your Back.<br/>
        Driverz Insurance was created with you, the collector in mind.  The NFT world can be unpredictable and we at Driverz want to give you an extra level of confidence when minting.
        <br/><br/>
        <b>How it Works</b>
        <br/>
        In short, after you mint your Driverz Car Club, add one insurance card to your collection for $10. Limited supply of 150 and only one per wallet is allowed.  
        The insurance card is valid for 30 days, and will give you the ability to sell your Driverz Car Club NFT back to us, no questions asked. Driverz will buy back your $77 mint 
        for $47 or your $97 mint for $67. You can trade in any amount of Driverz Car Club NFTs that you paid to mint. If you decide not to use your insurance and 
        hold your Driverz Car Club NFTs for more than the 30 day policy, you will be rewarded with your choice of a raffle ticket for an IRL 
        automotive experience near you, or a Driverz Genesis NFT. We will use 100% of the proceeds made from our Insurance policies to send 
        a few of our holders to their local race of choice. Simple, right?
        <br/><br/>
        <b>Why?</b><br/>
        Hey, life happens and we understand that here at Driverz.  Thirty days can seem like an eternity in the NFT world and some things may come up in real life.  
        We want to give our collectors a guaranteed cash offer to help out.  If you decide this project isn’t the right fit, that’s okay.  
        We want your NFTs and we want to get them into the hands of people who love the project. Driverz values you and the trust you showed us by minting, 
        and this insurance option is just one way we can say thank you.
            </p>*/}
{/*}
    <div className="row mt-5 mb-5">
        <div className="col-md-6">
        { /*<video width="100%" height="auto" controls>
            <source src="Driverz_Insurance.mp4"
              type="video/mp4" />
          </video>* 
        </div>      

        <div className="col-md-6">
          <Card bg="light" key="light" text="dark" className="mb-2">
            {/*<Card.Img variant="top" src="./DriverzInsuranceGold.png" />
            <Card.Body>
              {/*<Card.Title>
                Driverz Insurance Collection
              </Card.Title>
              <Card.Text>
                Click here to buy Driverz Insurance
              </Card.Text>*/}

              {/*(user.addr !== null && !ownInsurance) ?              
              <Button variant="primary" onClick={mintInsuranceClick}>
                Mint for $10
              </Button>
              :
              <Button variant="primary" disabled>
                Mint for $10
              </Button>
            
              
            </Card.Body>
          </Card>
        </div>
      </div> */}
    </div>
  )
}