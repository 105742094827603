
import firebase from 'firebase/compat/app';
import "firebase/compat/database";
/*
import { initializeApp } from "firebase/app";
import { getDatabase, ref } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
*/
/* OLD CONFIG */ /*
let config = {
  apiKey: "AIzaSyC0nhqkQd9T8RKGwQBm108nYeknrAUuBpY",
  authDomain: "driverz-5e83d.firebaseapp.com",
  databaseURL: "https://driverz-5e83d-default-rtdb.firebaseio.com",
  projectId: "driverz-5e83d",
  storageBucket: "driverz-5e83d.appspot.com",
  messagingSenderId: "589082640594",
  appId: "1:589082640594:web:9b84b73ef95fb12b8dad0b"
};*/

let config = {
  apiKey: "AIzaSyAiUri2dDb-UgBuiZ4Q_hKdWsupA4Vexxk",
  authDomain: "driverzracingform.firebaseapp.com",
  databaseURL: "https://driverzracingform-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "driverzracingform",
  storageBucket: "driverzracingform.appspot.com",
  messagingSenderId: "48669581788",
  appId: "1:48669581788:web:649ab0579fa960cabbaf19",
  measurementId: "G-NESF4EV1D8"
};
/*
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const analytics = getAnalytics(app);

var contactFormDB = firebase.database().ref('conteactForm');

document.getElementById('formBasicEmail').addEventListener('submit', submitForm);


function submitForm(e) {
  e.preventDefault();
}

const getElementVal


*/

firebase.initializeApp(config);

export default firebase.database();